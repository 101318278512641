import React from "react";
import ERROR_MESSAGES from "utils/errorMessages";

import { Header, Container } from "semantic-ui-react";
import styled from "styled-components";

type ErrorType = {
  errorCode?: number;
  errorMessage?: string;
};

const WrapperDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorPage: React.FC<ErrorType> = ({
  errorCode = 404,
  errorMessage = ERROR_MESSAGES.NOT_FOUND_ERROR,
}) => {
  return (
    <WrapperDiv>
      <Header as="h1">{errorCode}</Header>

      <Container textAlign="center">
        <p>{errorMessage}</p>
      </Container>
    </WrapperDiv>
  );
};

export default ErrorPage;
