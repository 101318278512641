import { isNumber } from "util";

export const px = (value?: number | string, important?: boolean) => {
  return (
    value !== undefined &&
    (important
      ? (isNumber(value) ? value + "px" : value) + " !important"
      : isNumber(value)
      ? value + "px"
      : value)
  );
};
