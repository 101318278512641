import React, { useState, useMemo } from "react";

import { Header, Form } from "semantic-ui-react";

import ZipcodeActionsCreators from "modules/Address/addressActions";
import { Registration, AdditionalRegistrationValues } from "./registerTypes";
import RegisterCreators from "./registerActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";

import TextBox from "components/atoms/TextBox";
import { withHeaderAndFooter } from "components/organisms/Header";
import DesignedButton from "components/atoms/DesignedButton";
import SelectBox from "components/atoms/SelectBox";
import InfoMessage from "components/atoms/InfoMessage";
import Loader from "components/atoms/Loader";

import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";

import prefecturesData from "utils/prefectures";
import Validate from "utils/Validate";

const PaddingGrid = padding(DefaultGrid);

const RegisterScheme = Yup.object().shape({
  name: Validate.user_name,
  kana_name: Validate.user_kana_name,
  postal_code: Validate.postal_code,
  city: Validate.city,
  address1: Validate.address1,
  person_in_charge: Validate.user_name,
  kana_person_in_charge: Validate.user_kana_name,
  tel_number: Validate.tel_number,
  email: Validate.email,
  password: Validate.password,
  password_confirmation: Validate.password_confirmation,
  privacy_policy: Validate.privacy_policy,
});

const Register: React.FC = () => {
  const { prefecture, city, area, loading, error } = useSelector(
    (state: State) => state.address
  );
  const [capturedValues, setCapturedValues] = useState<
    (Registration & AdditionalRegistrationValues) | undefined
  >();
  const [confirming, setConfirming] = useState<boolean>(false);
  const [valuesToSend, setValuesToSend] = useState<Registration | undefined>();

  const initialFormValues: Registration & AdditionalRegistrationValues = {
    name: capturedValues?.name || "",
    kana_name: capturedValues?.kana_name || "",
    postal_code: capturedValues?.postal_code || "",
    prefecture:
      prefecture !== "" ? prefecture : capturedValues?.prefecture || "選択する",
    city: city !== "" ? city : capturedValues?.city || "",
    address1: area !== "" ? area : capturedValues?.address1 || "",
    address2: capturedValues?.address2 || "",
    person_in_charge: capturedValues?.person_in_charge || "",
    kana_person_in_charge: capturedValues?.kana_person_in_charge || "",
    gender: capturedValues?.gender || "女性",
    tel_number: capturedValues?.tel_number || "",
    email: capturedValues?.email || "",
    password: capturedValues?.password || "",
    password_confirmation: capturedValues?.password_confirmation || "",
    privacy_policy: capturedValues?.privacy_policy || "",
  };

  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      registerActions: bindActionCreators(RegisterCreators, dispatch),
    };
  }, [dispatch]);

  const handleSubmit = async (values: Registration) => {
    setConfirming(true);
    setValuesToSend(values);
  };

  const changeFormValues = () => {
    setConfirming(false);
  };

  const handleRegister = () => {
    actions.registerActions.register(valuesToSend as Registration);
  };

  return (
    <>
      <PaddingGrid paddingbottom={20}>
        <DefaultGrid.Row centered>
          <DefaultGrid.Column textAlign={"center"}>
            <Header as="h1">登録</Header>
          </DefaultGrid.Column>
        </DefaultGrid.Row>
      </PaddingGrid>

      {error && (
        <PaddingGrid paddingbottom={20}>
          <DefaultGrid.Row centered>
            <DefaultGrid.Column textAlign={"center"}>
              <InfoMessage severity="error" message={error.message} />
            </DefaultGrid.Column>
          </DefaultGrid.Row>
        </PaddingGrid>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={RegisterScheme}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            {loading && <Loader />}

            <PaddingGrid paddingbottom={20}>
              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.name && errors.name && { content: errors.name }
                      }
                      id="name"
                      name="name"
                      label="施設名"
                      placeholder="施設名"
                      value={values.name}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>
              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.kana_name &&
                        errors.kana_name && { content: errors.kana_name }
                      }
                      id="kana_name"
                      name="kana_name"
                      label="施設名（ふりがな）："
                      placeholder="施設名（ふりがな）"
                      value={values.kana_name}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.postal_code &&
                        errors.postal_code && { content: errors.postal_code }
                      }
                      id="postal_code"
                      name="postal_code"
                      label="郵便番号："
                      placeholder="郵便番号"
                      value={values.postal_code}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                        handleBlur(e);
                        if (!errors.postal_code) {
                          dispatch(
                            ZipcodeActionsCreators.getAddress(
                              values.postal_code
                            )
                          );
                          setCapturedValues(values);
                        }
                      }}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <SelectBox
                      label="都道府県："
                      options={prefecturesData}
                      selection
                      id="prefecture"
                      name="prefecture"
                      placeholder="都道府県"
                      value={values.prefecture}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.city && errors.city && { content: errors.city }
                      }
                      id="city"
                      name="city"
                      label="市区町："
                      placeholder="市区町"
                      value={values.city}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.address1 &&
                        errors.address1 && { content: errors.address1 }
                      }
                      id="address1"
                      name="address1"
                      label="番地："
                      placeholder="番地"
                      value={values.address1}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.address2 &&
                        errors.address2 && { content: errors.address2 }
                      }
                      id="address2"
                      name="address2"
                      label="建物名/部屋番号名："
                      placeholder="建物名/部屋番号名"
                      value={values.address2}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.person_in_charge &&
                        errors.person_in_charge && {
                          content: errors.person_in_charge,
                        }
                      }
                      id="person_in_charge"
                      name="person_in_charge"
                      label="担当者氏名："
                      placeholder="担当者氏名"
                      value={values.person_in_charge}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.kana_person_in_charge &&
                        errors.kana_person_in_charge && {
                          content: errors.kana_person_in_charge,
                        }
                      }
                      id="kana_person_in_charge"
                      name="kana_person_in_charge"
                      label="担当者氏名（かな）："
                      placeholder="担当者氏名（かな）"
                      value={values.kana_person_in_charge}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group grouped>
                    <label>性別：</label>
                    <Form.Field
                      control="input"
                      type="radio"
                      label="女性"
                      name="gender"
                      value="女性"
                      checked={values.gender === "女性"}
                      onChange={handleChange}
                      disabled={confirming}
                    />

                    <Form.Field
                      control="input"
                      type="radio"
                      label="男性"
                      name="gender"
                      value="男性"
                      checked={values.gender === "男性"}
                      onChange={handleChange}
                      disabled={confirming}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.tel_number &&
                        errors.tel_number && { content: errors.tel_number }
                      }
                      id="tel_number"
                      name="tel_number"
                      label="電話番号："
                      placeholder="電話番号"
                      value={values.tel_number}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.email &&
                        errors.email && { content: errors.email }
                      }
                      id="email"
                      name="email"
                      label="メールアドレス："
                      placeholder="メールアドレス"
                      value={values.email}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                      autoComplete="username"
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      type="password"
                      error={
                        touched.password &&
                        errors.password && { content: errors.password }
                      }
                      id="password"
                      name="password"
                      label="パスワード："
                      placeholder="パスワード"
                      value={values.password}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column width={6}>
                  <Form.Group widths="equal">
                    <TextBox
                      type="password"
                      error={
                        touched.password_confirmation &&
                        errors.password_confirmation && {
                          content: errors.password_confirmation,
                        }
                      }
                      id="password_confirmation"
                      name="password_confirmation"
                      label="確認用パスワード："
                      placeholder="確認用パスワード"
                      value={values.password_confirmation}
                      disabled={confirming}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                      autoComplete="new-password"
                    />
                  </Form.Group>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column textAlign="center" width={6}>
                  <a
                    href="https://www.b4s.jp/faq/privacy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    利用規約を読む
                  </a>
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <DefaultGrid.Column textAlign="center" width={6}>
                  <Form.Field
                    label="利用規約に同意"
                    error={
                      touched.privacy_policy &&
                      errors.privacy_policy && {
                        content: errors.privacy_policy,
                      }
                    }
                    id="privacy_policy"
                    name="privacy_policy"
                    control="input"
                    type="checkbox"
                    value={values.privacy_policy}
                    disabled={confirming}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </DefaultGrid.Column>
              </DefaultGrid.Row>

              {!confirming && (
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column width={6}>
                    <DesignedButton type="submit" fluid={true}>
                      登録
                    </DesignedButton>
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
              )}
            </PaddingGrid>
          </Form>
        )}
      </Formik>

      {confirming && (
        <PaddingGrid paddingbottom={20}>
          <DefaultGrid.Row centered>
            <DefaultGrid.Column width={6}>
              <DesignedButton
                type="button"
                onClick={changeFormValues}
                fluid={true}
              >
                修正
              </DesignedButton>
            </DefaultGrid.Column>
            <DefaultGrid.Column width={6}>
              <DesignedButton onClick={handleRegister} fluid={true}>
                完了
              </DesignedButton>
            </DefaultGrid.Column>
          </DefaultGrid.Row>
        </PaddingGrid>
      )}
    </>
  );
};

export default withHeaderAndFooter(Register);
