import React from "react";
import { generatePath, NavLink, RouteComponentProps } from "react-router-dom";
import { Breadcrumb, BreadcrumbSectionProps } from "semantic-ui-react";

interface SectionsItem extends BreadcrumbSectionProps {
  key: React.Key;
}

export const Breadcrumbs = <P extends RouteComponentProps>(props: P) => {
  const path = generatePath("/donations/:id", props.match.params);

  const sections: SectionsItem[] = [
    { key: "", content: "", as: NavLink, to: "" },
    { key: path, content: "寄付品へ戻る", as: NavLink, to: path, active: true },
  ];
  return <Breadcrumb icon="left angle" sections={sections} />;
};

export const CompleteBreadcrumbs = <P extends RouteComponentProps>() => {
  const path = "/donations";

  const sections: SectionsItem[] = [
    { key: "", content: "", as: NavLink, to: "" },
    {
      key: path,
      content: "寄付一覧",
      as: NavLink,
      to: "/donations",
      active: true,
    },
  ];
  return <Breadcrumb icon="left angle" sections={sections} />;
};
