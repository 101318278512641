import actions from "./RequestChangePasswordConstants";

import {
  RequestChangePasswordAction,
  RequestChangePasswordSuccessAction,
  RequestChangePasswordErrorAction,
} from "./RequestChangePasswordTypes";

const requestChangePassword = (
  scope: string,
  email: string
): RequestChangePasswordAction => {
  return {
    type: actions.REQUEST_CHANGE_PASSWORD,
    payload: {
      scope,
      email,
    },
  };
};

const requestChangePasswordSuccess = (): RequestChangePasswordSuccessAction => {
  return {
    type: actions.REQUEST_CHANGE_PASSWORD_SUCCESS,
  };
};

const requestChangePasswordError = (
  error: Error
): RequestChangePasswordErrorAction => {
  return {
    type: actions.REQUEST_CHANGE_PASSWORD_ERROR,
    payload: {
      error,
    },
  };
};

const requestChangePasswordCreators = {
  requestChangePassword,
  requestChangePasswordSuccess,
  requestChangePasswordError,
};

export default requestChangePasswordCreators;
