import request, { requestHeadersAuthorized } from "utils/request";

export default {
  verify: async (resetPasswordToken: string, tel_number: string) =>
    request(
      `${process.env.REACT_APP_API_URL}/orphanage_user/password/verify?reset_password_token=${resetPasswordToken}&tel_number=${tel_number}`,
      {
        method: "GET",
        ...requestHeadersAuthorized(resetPasswordToken),
      }
    ) as unknown as Promise<void>,
};
