import { Payload } from "interfaces/Action";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import { Action } from "redux";
import { PaginationProps } from "semantic-ui-react";
import Actions from "./ChildrenListConstants";

export type ActionTypes =
  | ChangeValuesAction
  | GetChildrenListAction
  | GetChildrenListSuccessAction
  | GetChildrenListErrorAction
  | SendResetPasswordEmail;

export enum AccountNoticed {
  ALL = "ALL",
  NOTICED = "NOTICED",
  NOT_NOTICED = "NOT_NOTICED",
}

export interface ChildrenListState extends Omit<PaginationProps, "activePage"> {
  children?: ChildUser[];
  isLoading: boolean;
  error: boolean | Error;
  accountNoticed: AccountNoticed;
  activePage: number;
  total_pages: number;
  searchWord: string;
}

export type ChildrenListPagination = {
  limit?: number;
  page?: number;
};

export interface ChangeValuesAction
  extends Action<Actions.CHANGE_VALUES>,
    Payload<Partial<ChildrenListState>> {}

export interface GetChildrenListAction extends Action {
  type: typeof Actions.GET_CHILDREN_LIST;
  payload: {
    pagination: ChildrenListPagination;
  };
}

export interface GetChildrenListSuccessAction extends Action {
  type: typeof Actions.GET_CHILDREN_LIST_SUCCESS;
  payload: {
    children: ChildrenListState["children"];
    total_pages: number;
  };
}

export interface GetChildrenListErrorAction extends Action {
  type: typeof Actions.GET_CHILDREN_LIST_ERROR;
  payload: {
    error: Error;
  };
}
export interface SendResetPasswordEmail
  extends Action<Actions.SEND_RESET_PASSWORD_EMAIL> {
  payload: {
    email: string;
  };
}

export type GetLikesResponse = {
  data: ChildrenListState["children"];
  total_pages: number;
};
