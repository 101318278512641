import { Reducer } from "redux";
import { ProfileState, ActionTypes } from "./profileTypes";

import actions from "./profileConstants";

const profileInitialState: ProfileState = {
  message: false,
  loading: false,
  profile: {
    id: "",
    userName: "",
    first_name: "",
    last_name: "",
    first_kana_name: "",
    last_kana_name: "",
    tel_number: "",
    email: "",
  },
  error: undefined,
};

const profileReducer: Reducer<ProfileState, ActionTypes> = (
  state = profileInitialState,
  action
) => {
  switch (action.type) {
    case actions.UPDATE_PROFILE:
    case actions.GET_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROFILE_SUCCESS:
      return {
        loading: false,
        error: undefined,
        message: false,
        profile: action.payload.profile,
      };
    case actions.UPDATE_PROFILE_SUCCESS:
      return {
        error: undefined,
        loading: false,
        message: action.payload.message as string,
        profile: action.payload.profile,
      };
    case actions.PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        message: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default profileReducer;
