import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import { withHeaderAndFooter } from "components/organisms/Header";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import ChildEditor from "./parts/ChildEditor";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 375px !important;
`;

interface RouterProps {
  id: string;
}

const ChildPage: React.FC<RouteComponentProps<RouterProps>> = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <PaddingGrid id="Child" paddingleft={16} paddingright={32} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>
      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <ChildEditor />
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withRouter(withHeaderAndFooter(ChildPage));
