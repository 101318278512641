import { call, put, takeLatest } from "redux-saga/effects";

import { RequestChangePasswordAction } from "./RequestChangePasswordTypes";
import actions from "./RequestChangePasswordConstants";
import requestChangePasswordActionsCreators from "./RequestChangePasswordActions";

import requestErrorCheck from "utils/requestErrorCheck";
import request from "utils/request";
import { requestHeaders } from "utils/request";

import serialize from "serialize-javascript";

import { SCOPE } from "containers/App/GlobalTypes";

function* requestChangePasswordSaga(params: RequestChangePasswordAction) {
  try {
    let requestURL = "";

    if (params.payload.scope === SCOPE.ORPHANAGE) {
      requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/password/reset_request`;
    } else {
      requestURL = `${process.env.REACT_APP_API_URL}/child_user/password/reset_request`;
    }

    yield call(request, requestURL, {
      method: "POST",
      ...requestHeaders,
      body: serialize({ email: params.payload.email }),
    });

    yield put(
      requestChangePasswordActionsCreators.requestChangePasswordSuccess()
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(
      requestChangePasswordActionsCreators.requestChangePasswordError(error)
    );
  }
}

export default function* requestChangePasswordRootSaga() {
  yield takeLatest(actions.REQUEST_CHANGE_PASSWORD, requestChangePasswordSaga);
}
