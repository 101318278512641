export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json().catch(() => null);
}

async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return parseJSON(response);
  }

  let parsedResponse;
  const error = new ResponseError(response);

  if ([400, 401, 403, 422].includes(response.status)) {
    parsedResponse = await parseJSON(response);

    if (parsedResponse[0]) {
      error.message = parsedResponse[0];
    } else if (parsedResponse.message) {
      error.message = parsedResponse.message[0];
    } else if (parsedResponse.messages) {
      error.message = parsedResponse.messages[0];
    }
  }

  error.response = response;
  throw error;
}

export const requestHeaders = {
  headers: {
    "Content-Type": "application/json",
    charset: "UTF-8",
  },
};

export const requestHeadersAuthorized = (token: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      charset: "UTF-8",
      Authorization: `Bearer ${token}`,
    },
  };
};

export default async function request(
  url: string,
  options?: RequestInit
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  return await checkStatus(fetchResponse);
}

export type AsyncReturnType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends (...args: any[]) => any
> = ReturnType<P> extends Promise<infer T> ? T : never;
export interface B4SResponse<D> {
  data: D;
}
