import { Reducer } from "redux";

import Actions from "./ChildrenListConstants";
import {
  ChildrenListState,
  ActionTypes,
  AccountNoticed,
} from "./ChildrenListTypes";

const initialState = (): ChildrenListState => ({
  children: undefined,
  isLoading: false,
  error: false,
  accountNoticed: AccountNoticed.ALL,
  activePage: 1,
  total_pages: 0,
  searchWord: "",
});

const childrenListReducer: Reducer<ChildrenListState, ActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case Actions.CHANGE_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.GET_CHILDREN_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.GET_CHILDREN_LIST_SUCCESS:
      return {
        ...state,
        children: action.payload.children,
        total_pages: action.payload.total_pages,
        error: false,
        isLoading: false,
      };
    case Actions.GET_CHILDREN_LIST_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default childrenListReducer;
