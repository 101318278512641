import { Reducer } from "redux";

import Actions from "./GlobalConstants";
import {
  LogInState,
  ActionTypes,
  LogInSuccessAction,
  LogInErrorAction,
} from "./GlobalTypes";

const initialState = (): LogInState => ({
  accessToken: "",
  userName: "",
  scope: undefined,
  id: 0,
  isLoading: true,
  loggedIn: false,
  error: false,
});

export const logIn: Reducer<LogInState, ActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case Actions.LOGOUT:
    case Actions.LOGIN:
      return {
        ...state,
        isLoading: true,
        loggedIn: false,
      };
    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        loggedIn: true,
        accessToken: (action as LogInSuccessAction).payload.accessToken,
        userName: (action as LogInSuccessAction).payload.userName,
        id: (action as LogInSuccessAction).payload.id,
        scope: (action as LogInSuccessAction).payload.scope,
      };
    case Actions.LOGOUT_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        loggedIn: false,
        role: undefined,
      };
    case Actions.LOGOUT_ERROR:
    case Actions.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        error: (action as LogInErrorAction).payload.error,
      };
    default:
      return state;
  }
};
