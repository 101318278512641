import request from "utils/request";

import { call, put, takeLatest, select } from "redux-saga/effects";

import { deleteAccountActionsCreators } from "./DeleteAccountActions";

import { DeleteAccountAction } from "./DeleteAccountTypes";

import Actions from "./DeleteAccountConstants";

import { globalActionsCreators } from "containers/App/GlobalActions";
import { accessToken } from "containers/App/GlobalSelectors";

import requestErrorCheck from "utils/requestErrorCheck";
import { requestHeadersAuthorized } from "utils/request";

import serialize from "serialize-javascript";

function* deleteAccountSaga(action: DeleteAccountAction) {
  let requestURL = "";

  if (sessionStorage.scope === "orphanage_user") {
    requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/delete_request`;
  } else {
    requestURL = `${process.env.REACT_APP_API_URL}/child_user/delete_request`;
  }

  try {
    const responseAccessToken = yield select(accessToken);

    yield call(request, requestURL, {
      method: "POST",
      body: serialize(action.payload.accountParams),
      ...requestHeadersAuthorized(responseAccessToken),
    });

    yield put(deleteAccountActionsCreators.deleteAccountSuccess());
    yield put(globalActionsCreators.logOut());
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(deleteAccountActionsCreators.deleteAccountError(error));
  }
}

export default function* deleteAccountRootSaga() {
  yield takeLatest(Actions.DELETE_ACCOUNT, deleteAccountSaga);
}
