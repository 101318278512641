import actions from "./profileChildConstants";

import {
  ProfileChild,
  GetProfileChildAction,
  UpdateProfileChildAction,
  GetProfileChildSuccessAction,
  UpdateProfileChildSuccessAction,
  ProfileChildErrorAction,
} from "./profileChildTypes";

const getProfileChild = (): GetProfileChildAction => {
  return {
    type: actions.GET_PROFILE_CHILD,
  };
};

const updateProfileChild = (
  profileChild: ProfileChild
): UpdateProfileChildAction => {
  return {
    type: actions.UPDATE_PROFILE_CHILD,
    payload: {
      profileChild,
    },
  };
};

const getProfileChildSuccess = (
  profileChild: ProfileChild
): GetProfileChildSuccessAction => {
  return {
    type: actions.GET_PROFILE_CHILD_SUCCESS,
    payload: {
      profileChild,
    },
  };
};

const updateProfileChildSuccess = (
  profileChild: ProfileChild,
  message: string
): UpdateProfileChildSuccessAction => {
  return {
    type: actions.UPDATE_PROFILE_CHILD_SUCCESS,
    payload: {
      profileChild,
      message,
    },
  };
};

const profileChildError = (error: Error): ProfileChildErrorAction => {
  return {
    type: actions.PROFILE_CHILD_ERROR,
    payload: {
      error,
    },
  };
};

const ProfileChildCreators = {
  getProfileChild,
  updateProfileChild,
  getProfileChildSuccess,
  updateProfileChildSuccess,
  profileChildError,
};

export default ProfileChildCreators;
