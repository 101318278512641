import { ThumbList, ThumbListItem } from "components/organisms/ThumbList";
import { State } from "interfaces/State";
import { categoriesActionsCreators } from "modules/db/Categories/CategoriesActions";
import { donationsActionsCreators } from "modules/db/Donations/DonationsActions";
import React, { useEffect, useMemo, Props } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import {
  Dropdown,
  Form,
  Grid,
  Header,
  Image,
  Input,
  Loader,
  Radio,
  GridColumn,
  DropdownItemProps,
  StrictDropdownItemProps,
  Button,
} from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { ObjectEntries, COLORS, BREAKPOINTS } from "utils/ComponentUtils";
import { EXIST, SORT } from "utils/filterTypes";
import ROUTES from "utils/routes";
import { donationsActionsCreators as actionCreators } from "pages/Donations/List/DonationsActions";
import todokun_animation from "images/todokun_gif.gif";
import { DonationsState } from "pages/Donations/List/DonationsReducer";
import CustomBreadcrumbs from "./CustomBreadcrumbs";
import { Dispatch } from "redux";
import { GetDonationsOptions } from "modules/db/Donations/DonationsTypes";
import { POINT_LIMITS } from "pages/Donations/List/DonationsConstants";
import { Category } from "interfaces/db/Category";
import { EXTRANAL_ROUTES } from "utils/routes";
import DesignedButtonPager from "./DesignedButtonPager";
import { Donation } from "interfaces/db/Donation";

export type DonationsProps = DonationsState & RouteComponentProps;

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const LogoStyled = styled(Image)`
  width: 100%;
  height: auto;
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    width: 770px;
    height: 470px;
  }
`;

const Title = styled(Header)`
  &&& {
    font-weight: bold;
  }
`;

const SearchTitle = styled(Header)`
  &&& {
    font-weight: bold;
    color: #00b5ad;
    font-size: 1.4rem;
  }
`;

const StyledDropdown = styled(Dropdown)`
  && > .default.text {
    color: black !important;
  }
`;

const StyledInput = styled(Input)`
  && > input::placeholder {
    color: black !important;
  }
  && > input {
    border: 1px solid ${COLORS.BORDER} !important;
  }
`;

const MainPage = (props: React.PropsWithChildren<DonationsProps>) => {
  const loggedIn = useSelector((state: State) => state.logIn.loggedIn);
  const { data: donationsData } = useSelector(
    (state: State) => state.donations
  );
  const donations = donationsData?.donations;
  const donations_count = donationsData?.total_num;
  const totalPages = donationsData.total_pages;
  const { data: categories } = useSelector((state: State) => state.categories);
  const { isLoading } = useSelector((state: State) => state.globals);
  const { searchCondition, pagination, sort } = useSelector(
    (state: State) => state.donationsPage
  );
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      donations: bindActionCreators(donationsActionsCreators, dispatch),
      categories: bindActionCreators(categoriesActionsCreators, dispatch),
      ...bindActionCreators(actionCreators, dispatch),
    };
  }, [dispatch]);
  const donationThumbs = donations
    ?.sort((a, b) => {
      if (searchCondition?.sort) {
        switch (searchCondition.sort) {
          case "NEWEST":
            return b.id - a.id;
          case "OLDEST":
            return a.id - b.id;
          case "POINT_HIGHEST":
            return b.point - a.point;
          case "POINT_LOWEST":
            return a.point - b.point;
          default:
            break;
        }
      }
      return 0;
    })
    .map(({ images, name, id, point, liked, like_num }): ThumbListItem => {
      return {
        key: id.toString(),
        image: images,
        alt: name,
        imageLink: generatePath(ROUTES.DONATION_DETAIL, { id }),
        thumbsTitle: name,
        thmubsPoint: point + "pt",
        titleOption: {
          textAlign: "left",
        },
        liked,
        like_num,
      };
    });
  const newDonationThumbs = donations
    ?.filter((donation: Donation) => {
      if (!donation.stock_num) {
        return false;
      }
      if (donation.images) {
        return true;
      }
    })
    .sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      }
      if (a.id < b.id) {
        return 1;
      }
      return 0;
    })
    .map(({ images, name, id, point, liked, like_num }): ThumbListItem => {
      return {
        key: id.toString(),
        image: images,
        alt: name,
        imageLink: generatePath(ROUTES.DONATION_DETAIL, { id }),
        thumbsTitle: name,
        thmubsPoint: point + "pt",
        titleOption: {
          textAlign: "left",
        },
        liked,
        like_num,
      };
    });

  useEffect(() => {
    actions.donations.getDonations(searchCondition, pagination, sort);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!categories) {
      actions.categories.getCategories();
    }
  }, [categories, actions]);

  useEffect(() => {
    sessionStorage.removeItem("form_values");
  }, []);

  return (
    <PaddingGrid
      id="MainPage"
      paddingtop={0}
      paddingleft={16}
      paddingright={16}
    >
      {props.location.pathname === "/donations" && (
        <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
          <PaddingColumn width={16} paddingleft={0}>
            <CustomBreadcrumbs {...props} />
          </PaddingColumn>
        </PaddingRow>
      )}
      <Grid.Row centered>
        <Grid.Column
          mobile={16}
          tablet={14}
          computer={12}
          largeScreen={10}
          style={{ paddingbottom: "40px" }}
        >
          <Grid>
            <DefaultGrid.PaddingRow
              id="Hero"
              centered
              paddingbottom={30}
              style={{ display: "block" }}
            >
              <LogoStyled src={todokun_animation} alt="トップ画像" centered />
            </DefaultGrid.PaddingRow>
            <DefaultGrid.PaddingRow
              id="SearchTitleContainer"
              centered
              paddingbottom={35}
            >
              <SearchTitle id="SearchTitle">
                {searchCondition.categoryName
                  ? searchCondition.categoryName
                  : "カテゴリーを選択"}
              </SearchTitle>
            </DefaultGrid.PaddingRow>
            <DefaultGrid.PaddingRow padding={0}>
              <DefaultGrid.PaddingColumn
                padding={0}
                mobile={8}
                tablet={8}
                computer={5}
              >
                <StyledDropdown
                  selection
                  fluid
                  options={categoryDropDownItems(
                    dispatch,
                    categories,
                    searchCondition
                  )}
                  placeholder={"カテゴリ"}
                  value={searchCondition.category_id_eq}
                  style={{
                    fontSize: "12px",
                    border: `1px solid ${COLORS.BORDER}`,
                  }}
                />
              </DefaultGrid.PaddingColumn>
              <DefaultGrid.PaddingColumn
                padding={0}
                mobile={8}
                tablet={8}
                computer={11}
              >
                <StyledInput
                  className="input-search"
                  fluid
                  icon="search"
                  type="text"
                  placeholder="検索"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    actions.setSearchCondition({
                      ...searchCondition,
                      name_or_description_cont: e.target.value,
                    });
                  }}
                  style={{ fontSize: "12px", borderRadius: 10, border: "none" }}
                />
              </DefaultGrid.PaddingColumn>
            </DefaultGrid.PaddingRow>
            <Grid.Row>
              <DefaultGrid.PaddingColumn padding={0} mobile={7} computer={6}>
                <StyledDropdown
                  selection
                  fluid
                  options={pointDropDownItems(dispatch, searchCondition)}
                  placeholder={"ポイント数で検索"}
                  value={`${searchCondition.point_gteq}-${searchCondition.point_lteq}`}
                  style={{
                    fontSize: "12px",
                    border: `0.1px solid ${COLORS.BORDER}`,
                  }}
                />
              </DefaultGrid.PaddingColumn>
              <DefaultGrid.PaddingColumn
                padding={0}
                floated="right"
                mobile={7}
                tablet={6}
                computer={4}
              >
                <Dropdown
                  text={"フィルター"}
                  labeled
                  className="icon"
                  button
                  fluid
                  multiple
                  closeOnChange={false}
                  icon="sliders horizontal"
                  style={{
                    fontSize: "12px",
                    backgroundColor: "white",
                    border: `1px solid ${COLORS.BORDER}`,
                  }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Header>並び替え</Header>
                      <Form>
                        {ObjectEntries(SORT).map(([key, item]) => {
                          return (
                            <Form.Field key={key}>
                              <Radio
                                label={item.label}
                                name="order"
                                checked={
                                  sort.type === item.value.type &&
                                  sort.order === item.value.order
                                }
                                disabled={isLoading}
                                onClick={() => {
                                  actions.setSort({
                                    type: item.value.type,
                                    order: item.value.order,
                                  });
                                }}
                              />
                            </Form.Field>
                          );
                        })}
                      </Form>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Header>在庫状況</Header>
                      <Form>
                        {ObjectEntries(EXIST).map(([key, item]) => {
                          return (
                            <Form.Field key={key}>
                              <Radio
                                label={item.label}
                                name="exist"
                                checked={
                                  searchCondition?.stock_num_gt === item.value
                                }
                                disabled={isLoading}
                                onClick={() => {
                                  actions.setSearchCondition({
                                    ...searchCondition,
                                    stock_num_gt: item.value,
                                  });
                                }}
                              />
                            </Form.Field>
                          );
                        })}
                      </Form>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </DefaultGrid.PaddingColumn>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={14} computer={12} largeScreen={10}>
          <Grid id="donationsContents" style={{ paddingTop: "4rem" }}>
            {props.location.pathname === "/top" &&
              !isLoading &&
              newDonationThumbs &&
              !searchCondition && (
                <DefaultGrid.PaddingRow centered paddingbottom={80}>
                  <DefaultGrid.PaddingColumn
                    id="newDonationContents"
                    padding={0}
                  >
                    <Title
                      id="NewArrivalsTitle"
                      style={{ marginBottom: "2rem" }}
                    >
                      おすすめの商品
                    </Title>
                    <ThumbList
                      items={newDonationThumbs}
                      mobileMaxItems={4}
                      computerMaxItems={8}
                    />
                  </DefaultGrid.PaddingColumn>
                </DefaultGrid.PaddingRow>
              )}
            <Grid.Row centered>
              <GridColumn>
                {!isLoading && donationThumbs ? (
                  donationThumbs.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                      該当する寄付品がありません
                    </div>
                  ) : (
                    <DefaultGrid.PaddingRow>
                      <Title
                        id="NewArrivalsTitle"
                        style={{ marginBottom: "2rem" }}
                      >
                        商品を見る
                      </Title>
                      {donations_count && <div>{donations_count}件</div>}
                      <ThumbList
                        items={donationThumbs}
                        mobileMaxItems={24}
                        computerMaxItems={24}
                      />
                    </DefaultGrid.PaddingRow>
                  )
                ) : (
                  <Loader active />
                )}
              </GridColumn>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      {!isLoading && donationThumbs && (
        <>
          {donationThumbs.length !== 0 && (
            <DefaultGrid.PaddingRow paddingtop={20} centered>
              <DesignedButtonPager
                activePage={pagination.page}
                totalPages={totalPages}
                onPageChange={(e, { activePage }) => {
                  if (activePage) {
                    actions.setPagination({ ...pagination, page: +activePage });
                  }
                }}
              />
            </DefaultGrid.PaddingRow>
          )}
          {!loggedIn && (
            <DefaultGrid.PaddingRow paddingtop={30} centered>
              <Button
                basic
                size="large"
                color="orange"
                href={EXTRANAL_ROUTES.DONATION}
                target="_blank"
              >
                <strong>寄付で応援する</strong>
              </Button>
            </DefaultGrid.PaddingRow>
          )}
        </>
      )}
    </PaddingGrid>
  );
};

export default MainPage;

const categoryDropDownItems = (
  dispatch: Dispatch,
  categories: Category[] = [],
  searchCondition: GetDonationsOptions
): Props<StrictDropdownItemProps>[] => {
  return [{ id: 0, name: "すべてのカテゴリー" }]
    .concat(categories)
    .map((category) => {
      return {
        key: category.id,
        active: category.id === searchCondition.category_id_eq,
        text: category.name,
        value: category.id,
        onClick: () => {
          dispatch(
            actionCreators.setSearchCondition({
              ...searchCondition,
              category_id_eq: category.id,
              categoryName: category.name,
            })
          );
        },
      };
    });
};

const pointDropDownItems = (
  dispatch: Dispatch,
  searchCondition: GetDonationsOptions
): DropdownItemProps[] => {
  return [
    {
      key: 0,
      active:
        searchCondition.point_gteq === undefined &&
        searchCondition.point_lteq === undefined,
      text: "pt指定なし",
      value: "undefined-undefined",
      onClick: () => {
        dispatch(
          actionCreators.setSearchCondition({
            ...searchCondition,
            point_gteq: undefined,
            point_lteq: undefined,
          })
        );
      },
    },
  ].concat(
    POINT_LIMITS.map((point_lteq, idx, _this) => {
      const point_gt = idx === 0 ? 0 : _this[idx - 1];
      const point_gteq = point_gt ? point_gt + 1 : 0;
      const pointText = `${point_gteq ? `${point_gteq}pt` : ""}${
        point_gteq !== 0 && point_lteq !== undefined ? "から" : ""
      }${point_lteq === undefined ? "以上" : point_lteq + "pt"}${
        point_gteq === 0 ? "以下" : ""
      }`;
      return {
        key: idx + 1,
        active:
          point_lteq !== 0 &&
          point_gteq === searchCondition.point_gteq &&
          point_lteq === searchCondition.point_lteq,
        text: pointText,
        value: `${point_gteq}-${point_lteq}`,
        onClick: () => {
          dispatch(
            actionCreators.setSearchCondition({
              ...searchCondition,
              point_gteq,
              point_lteq,
            })
          );
        },
      };
    })
  );
};
