import { withHeaderAndFooter } from "components/organisms/Header";
import React from "react";
import MainPage from "components/organisms/MainPage";
import { DonationsState } from "../Donations/List/DonationsReducer";
import { RouteComponentProps } from "react-router";

export type DonationsProps = DonationsState & RouteComponentProps;

const Top: React.FC<DonationsProps> = (props) => {
  return <MainPage {...props} />;
};

export default withHeaderAndFooter(Top);
