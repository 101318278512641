import PrivateRoute from "containers/PrivateRoute";
import ChangePassword from "pages/ChangePassword";
import DonationApplication from "pages/Donations/Application/DonationApplication";
import DonationComplete from "pages/Donations/complete/DonationComplete";
import DonationConfirmation from "pages/Donations/Confirmation/DonationConfirmation";
import DonationDetail from "pages/Donations/Detail/DonationDetail";
import Donations from "pages/Donations/List/Donations";
import Error from "pages/Error";
import LogIn from "pages/LogIn";
import ChangeEmail from "pages/MyPage/ChangeEmail";
import ChangeEmailComplete from "pages/MyPage/ChangeEmail/ChangeEmailComplete";
import Child from "pages/MyPage/Child";
import ChildrenList from "pages/MyPage/ChildrenList";
import AccountNotificationCompleted from "pages/MyPage/ChildrenList/AccountNotificationCompleted";
import DeleteAccount from "pages/MyPage/DeleteAccount";
import ProfilePage from "pages/MyPage/Profile/index";
import Settings from "pages/MyPage/Settings";
import Transactions from "pages/MyPage/Transactions";
import TransactionDetail from "pages/MyPage/Transactions/TransactionDetail";
import TransactionEdit from "pages/MyPage/Transactions/TransactionEdit";
import TransactionEditComplete from "pages/MyPage/Transactions/TransactionEditComplete";
import Message from "pages/MyPage/Transactions/Message";
import RegisterOrphanage from "pages/RegisterOrphanage";
import RequestChangePassword from "pages/RequestChangePassword";
import ChangePasswordPage from "pages/ChangePassword";
import PersonalIdentification from "pages/PersonalIdentification";
import Likes from "pages/MyPage/Likes";
import Top from "pages/Top/Top";
import React from "react";
import { Redirect, Route as PublicRoute, Switch } from "react-router-dom";
import ROUTES from "utils/routes";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={ROUTES.TOP} />
      <PublicRoute exact path={ROUTES.REGISTER} component={RegisterOrphanage} />
      <PublicRoute exact path={ROUTES.TOP} component={Top} />
      <PublicRoute exact path={ROUTES.LOGIN} component={LogIn} />
      <PublicRoute
        exact
        path={ROUTES.CHANGE_PASSWORD}
        component={ChangePasswordPage}
      />
      <PublicRoute exact path={ROUTES.DONATIONS} component={Donations} />
      <PublicRoute
        exact
        path={ROUTES.DONATION_DETAIL}
        component={DonationDetail}
      />
      <PrivateRoute
        exact
        path={ROUTES.DONATION_APPLICATION}
        component={DonationApplication}
      />
      <PrivateRoute
        exact
        path={ROUTES.DONATION_APPLICATION_CONFIRMATION}
        component={DonationConfirmation}
      />
      <PrivateRoute
        exact
        path={ROUTES.DONATION_APPLICATION_COMPLETE}
        component={DonationComplete}
      />
      <PrivateRoute exact path={ROUTES.TRANSACTIONS} component={Transactions} />
      <PrivateRoute
        exact
        path={ROUTES.TRANSACTIONS_DETAIL}
        component={TransactionDetail}
      />
      <PrivateRoute
        exact
        path={ROUTES.TRANSACTIONS_EDIT}
        component={TransactionEdit}
      />
      <PrivateRoute
        exact
        path={ROUTES.TRANSACTIONS_EDIT_COMPLETE}
        component={TransactionEditComplete}
      />
      <PrivateRoute exact path={ROUTES.LIKES} component={Likes} />
      <PrivateRoute exact path={ROUTES.MESSAGE} component={Message} />
      <PrivateRoute exact path={ROUTES.SETTINGS} component={Settings} />
      <PrivateRoute
        exact
        path={ROUTES.CHILDREN_LIST}
        component={ChildrenList}
      />
      <PrivateRoute exact path={ROUTES.EDIT_CHILD} component={Child} />
      <PrivateRoute
        exact
        path={ROUTES.ACCOUNT_NOTIFICATION_COMPLETED}
        component={AccountNotificationCompleted}
      />
      <PrivateRoute exact path={ROUTES.PROFILE} component={ProfilePage} />
      <PrivateRoute
        exact
        path={ROUTES.DELETE_ACCOUNT}
        component={DeleteAccount}
      />
      <PublicRoute path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
      <PublicRoute
        exact
        path={ROUTES.RESET_PASSWORD}
        component={RequestChangePassword}
      />
      <PrivateRoute exact path={ROUTES.CHANGE_EMAIL} component={ChangeEmail} />
      <PrivateRoute
        exact
        path={ROUTES.CHANGE_EMAIL_COMPLETE}
        component={ChangeEmailComplete}
      />
      <PublicRoute
        exact
        path={ROUTES.PERSONAL_IDENTIFICATION}
        component={PersonalIdentification}
      />
      <PublicRoute exact path={ROUTES.LIKES} component={Likes} />
      <PublicRoute component={Error} />
    </Switch>
  );
};

export default Routes;
