import { Category } from "interfaces/db/Category";
import { call, put, takeLatest } from "redux-saga/effects";
import request, { B4SResponse } from "utils/request";
import { categoriesActionsCreators } from "./CategoriesActions";
import actions from "./CategoriesConstants";

export const categoriesSaga = function* () {
  yield takeLatest(actions.GET_CATEGORIES, getCategoriesSaga);
};

export const getCategoriesSaga = function* () {
  const requestURL = `${process.env.REACT_APP_API_URL}/categories`;

  try {
    const categories: B4SResponse<Category[]> = yield call(
      request,
      requestURL,
      {
        method: "GET",
      }
    );

    yield put(categoriesActionsCreators.setCategories(categories.data));
  } catch (e) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};
