import React from "react";

interface Props {
  point: string;
  color: string;
}

const PointLabel: React.FC<Props> = ({ point, color }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        color: color,
        fontFamily: "Helvetica",
        fontStyle: "normal",
        fontWeight: 700,
      }}
    >
      <div>{point + " pt"}</div>
    </div>
  );
};

export default PointLabel;
