import ChildUserApi from "api/v1/ChildUser";
import { push } from "connected-react-router";
import { accessToken } from "containers/App/GlobalSelectors";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import request, {
  AsyncReturnType,
  requestHeadersAuthorized,
} from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import ROUTES from "utils/routes";
import { childrenListActionsCreators } from "./ChildrenListActions";
import Actions from "./ChildrenListConstants";
import {
  SendResetPasswordEmail,
  GetChildrenListAction,
  GetLikesResponse,
} from "./ChildrenListTypes";

function* getChildrenListSaga(params: GetChildrenListAction) {
  try {
    const responseAccessToken = yield select(accessToken);

    const childrenData = (yield call(
      getChildrenList,
      responseAccessToken,
      params.payload.pagination
    )) as AsyncReturnType<typeof getChildrenList>;
    yield put(
      childrenListActionsCreators.getChildrenListSuccess(
        childrenData.data,
        childrenData.total_pages
      )
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(childrenListActionsCreators.getChildrenListError(error));
  }
}

export default function* getChildrenListRootSaga() {
  yield takeLatest(Actions.GET_CHILDREN_LIST, getChildrenListSaga);
  yield takeEvery(
    Actions.SEND_RESET_PASSWORD_EMAIL,
    sendResetPasswordEmailSaga
  );
}

const sendResetPasswordEmailSaga = function* (Action: SendResetPasswordEmail) {
  try {
    yield call(
      ChildUserApi.accountNotify,
      (yield select(accessToken)) as string,
      Action.payload.email
    );
    yield put(push(ROUTES.ACCOUNT_NOTIFICATION_COMPLETED));
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};

const getChildrenList = async (
  accessToken: string,
  pagination: GetChildrenListAction["payload"]["pagination"]
): Promise<GetLikesResponse> => {
  const page = Object.entries(pagination || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      return `pagination[${key}]=` + (value || "");
    })
    .join("&");

  return (
    request(
      `${process.env.REACT_APP_API_URL}/child_users` +
        (page.length !== 0 ? "?" + page : ""),
      {
        method: "GET",
        ...requestHeadersAuthorized(accessToken),
      }
    ) as Promise<GetLikesResponse>
  ).then((response) => {
    return {
      ...response,
      data: response.data?.map((chidlUser) => {
        return {
          ...chidlUser,
          birthdate:
            chidlUser.birthdate === null ? null : new Date(chidlUser.birthdate),
          account_notified_at:
            chidlUser.account_notified_at === null
              ? null
              : new Date(chidlUser.account_notified_at),
          created_at: new Date(chidlUser.created_at),
          updated_at: new Date(chidlUser.updated_at),
        };
      }),
      total_pages: response.total_pages,
    };
  });
};
