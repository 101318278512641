import {
  SetPaginationAction,
  LikesPagination,
  GetLikesChildAction,
  GetLikesOrphanageAction,
} from "./LikesTypes";
import Actions from "./LikesConstants";
import { GetLikesSuccessAction, GetLikesErrorAction } from "./LikesTypes";
import { Donation } from "interfaces/db/Donation";
import { Scopes } from "containers/App/GlobalTypes";

export const setPagination = (
  pagination: LikesPagination
): SetPaginationAction => {
  return {
    type: Actions.SET_PAGINATION,
    payload: {
      pagination,
    },
  };
};

export const getLikesChild = (
  scope: Scopes,
  pagination: LikesPagination,
  childUserId: number
): GetLikesChildAction => {
  return {
    type: Actions.GET_LIKES_CHILD,
    payload: {
      scope,
      pagination,
      childUserId,
    },
  };
};

export const getLikesOrphanage = (
  scope: Scopes,
  pagination: LikesPagination
): GetLikesOrphanageAction => {
  return {
    type: Actions.GET_LIKES_ORPHANAGE,
    payload: {
      scope,
      pagination,
    },
  };
};

export const getLikesSuccess = (
  data: Donation[],
  total_pages: number
): GetLikesSuccessAction => {
  return {
    type: Actions.GET_LIKES_SUCCESS,
    payload: {
      data,
      total_pages,
    },
  };
};

export const getLikesError = (error: Error): GetLikesErrorAction => {
  return {
    type: Actions.GET_LIKES_ERROR,
    payload: {
      error,
    },
  };
};

export const likesActionsCreators = {
  setPagination,
  getLikesChild,
  getLikesOrphanage,
  getLikesSuccess,
  getLikesError,
};
