import { Donation } from "interfaces/db/Donation";
import {
  GetDonationsOptions,
  Pagination,
  Sort,
} from "../../../modules/db/Donations/DonationsTypes";
import actions from "./DonationsConstants";
import { GetDonationsAction, SetDonationsAction } from "./DonationsTypes";

export const getDonations = (
  searchCondition?: GetDonationsOptions,
  pagination?: Pagination,
  sort?: Sort
): GetDonationsAction => {
  return {
    type: actions.GET_DONATIONS,
    payload: {
      searchCondition,
      pagination,
      sort,
    },
  };
};

export const setDonations = (data: {
  donations: Donation[];
  total_num: number;
  total_pages: number;
}): SetDonationsAction => {
  return {
    type: actions.SET_DONATIONS,
    payload: {
      data,
    },
  };
};

export const donationsActionsCreators = {
  getDonations,
  setDonations,
};
