import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button } from "semantic-ui-react";
import styled from "styled-components";
import LikeCreators from "../../modules/db/Like/LikeActions";

interface Props {
  like_num: number;
  liked: boolean;
  donation_id: number;
  child_user_id: string;
}

const StyledButton = styled(Button)`
  padding: 0.6rem 1rem !important;
  border: 1px #00b5ad solid !important;
`;

const LikeButton: React.FC<Props> = ({
  like_num,
  liked,
  donation_id,
  child_user_id,
}) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState<boolean>(false || liked);
  const [likedNum, setLikedNum] = useState<number>(like_num);

  const actions = useMemo(() => {
    return {
      like: bindActionCreators(LikeCreators, dispatch),
    };
  }, [dispatch]);

  const makeLike = () => {
    actions.like.Like(child_user_id, donation_id.toString(), "POST");
    setLikedNum(likedNum + 1);
    setClicked(true);
  };

  const makeUnLike = () => {
    actions.like.Like(child_user_id, donation_id.toString(), "DELETE");
    setLikedNum(likedNum - 1);
    setClicked(false);
  };

  return (
    <div>
      {clicked ? (
        <StyledButton
          style={{
            color: "#00B5AD",
            backgroundColor: " #e0f6f5",
          }}
          onClick={makeUnLike}
        >
          <Icon name="heart" />
          {likedNum + "件"}
        </StyledButton>
      ) : (
        <StyledButton style={{ color: "#FFFFFF" }} primary onClick={makeLike}>
          <StyledButton.Content>
            <Icon name="heart" />
            <span style={{ padding: "0 8px 0 4px" }}>お気に入り登録</span>
            {likedNum + "件"}
          </StyledButton.Content>
        </StyledButton>
      )}
    </div>
  );
};

export default LikeButton;
