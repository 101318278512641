import { Reducer } from "redux";
import {
  DonationConfirmationActionTypes,
  DonationConfirmationState,
} from "./DonationConfirmationTypes";

const initialState = (): DonationConfirmationState => ({});

const donationConfirmation: Reducer<
  DonationConfirmationState,
  DonationConfirmationActionTypes
> = (state = initialState(), action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default donationConfirmation;
