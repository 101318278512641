import { push } from "connected-react-router";
import { generatePath } from "react-router";
import { put, takeLatest } from "redux-saga/effects";
import ROUTES from "utils/routes";
import ActionTypes from "./DonationApplicationConstants";
import { ConfirmationAction } from "./DonationApplicationTypes";

const donationApplicationSaga = function* () {
  yield takeLatest(ActionTypes.CONFIRMATION, confrimationSaga);
};

export const confrimationSaga = function* (action: ConfirmationAction) {
  yield put(
    push(
      generatePath(
        ROUTES.DONATION_APPLICATION_CONFIRMATION,
        action.payload.params
      )
    )
  );
};

export default donationApplicationSaga;
