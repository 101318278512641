import Transactions from "api/v1/Transactions";
import { push } from "connected-react-router";
import { accessToken as accessTokenSelector } from "containers/App/GlobalSelectors";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { AsyncReturnType } from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import transactionEditActionCreator, {
  GetTransactionAction,
  SubmitAction,
} from "./TransactionEditAction";
import ActionTypes from "./TransactionEditConstants";
import { generatePath } from "react-router";
import ROUTES from "utils/routes";
import OrphanageUser from "api/v1/OrphanageUser";

const transactionEditSaga = function* () {
  yield takeEvery(ActionTypes.GET_TRANSACTION, getTransactionSaga);
  yield takeEvery(ActionTypes.GET_ORPHANAGE, getOrphanageSaga);
  yield takeEvery(ActionTypes.SUBMIT, submitSaga);
};

export default transactionEditSaga;

const getTransactionSaga = function* ({
  payload: { id },
}: GetTransactionAction) {
  try {
    const accessToken = (yield select(accessTokenSelector)) as string;
    const response = (yield call(
      Transactions.get,
      accessToken,
      id
    )) as AsyncReturnType<typeof Transactions.get>;
    yield put(transactionEditActionCreator.setTransaction(response.data));
  } catch (error) {
    yield requestErrorCheck(error);
    // dummy
    yield put(push("/error"));
  }
};
const getOrphanageSaga = function* () {
  try {
    const accessToken = (yield select(accessTokenSelector)) as string;
    const response = (yield call(
      OrphanageUser.orphanage,
      accessToken
    )) as AsyncReturnType<typeof OrphanageUser.orphanage>;
    yield put(transactionEditActionCreator.setOrphanage(response.data));
  } catch (error) {
    yield requestErrorCheck(error);
    // dummy
    yield put(push("/error"));
  }
};
const submitSaga = function* ({ payload: { id, values } }: SubmitAction) {
  try {
    const accessToken = (yield select(accessTokenSelector)) as string;
    yield call(Transactions.patch, accessToken, id, values);
    yield alert("住所登録に成功しました");
    yield put(push(generatePath(ROUTES.TRANSACTIONS_EDIT_COMPLETE, { id })));
    yield put(transactionEditActionCreator.setLoading(false));
  } catch (e) {
    yield requestErrorCheck(e);
    yield put(push("/error"));
  }
};
