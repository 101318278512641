import React, { CSSProperties } from "react";
import styled from "styled-components";

type AlignProps = Pick<
  CSSProperties,
  | "alignContent"
  | "alignItems"
  | "alignSelf"
  | "alignmentBaseline"
  | "scrollSnapAlign"
  | "textAlign"
  | "textAlignLast"
  | "verticalAlign"
>;

export const align = function <T>(Component: React.ComponentType<T>) {
  return styled(
    ({
      alignContent,
      alignItems,
      alignSelf,
      alignmentBaseline,
      scrollSnapAlign,
      textAlign,
      textAlignLast,
      verticalAlign,
      ...props
    }: T & Partial<AlignProps>) => (
      <Component
        data-align-content={alignContent}
        data-align-items={alignItems}
        data-align-self={alignSelf}
        data-alignment-baseline={alignmentBaseline}
        data-scroll-snap-align={scrollSnapAlign}
        data-text-align={textAlign}
        data-text-align-last={textAlignLast}
        data-vertical-align={verticalAlign}
        {...(props as T)}
      />
    )
  )`
    &&& {
      align-content: ${(props) =>
        props.alignContent && props.alignContent + " !important"};
      align-items: ${(props) =>
        props.alignItems && props.alignItems + " !important"};
      align-self: ${(props) =>
        props.alignSelf && props.alignSelf + " !important"};
      alignment-baseline: ${(props) =>
        props.alignmentBaseline && props.alignmentBaseline + " !important"};
      scroll-snap-align: ${(props) =>
        props.scrollSnapAlign && props.scrollSnapAlign + " !important"};
      text-align: ${(props) =>
        props.textAlign && props.textAlign + " !important"};
      text-align-last: ${(props) =>
        props.textAlignLast && props.textAlignLast + " !important"};
      vertical-align: ${(props) =>
        props.verticalAlign && props.verticalAlign + " !important"};
    }
  `;
};
