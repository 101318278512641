import MainHeader from "components/atoms/MainHeader";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import { withHeaderAndFooter } from "components/organisms/Header";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import ChildrenList from "./parts/ChildrenList";
import Controlls from "./parts/Controlls";
import { State } from "interfaces/State";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const ChildrenListPage: React.FC<RouteComponentProps> = (props) => {
  const { pagination } = useSelector((state: State) => state.childrenListPage);
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return bindActionCreators(childrenListActionsCreators, dispatch);
  }, [dispatch]);

  useEffect(() => {
    actions.getChildrenList(pagination);
    return () => {
      actions.changeValues({ children: undefined });
    };
  }, [dispatch, actions, pagination]);

  return (
    <PaddingGrid id="ChildrenList" paddingleft={16} paddingright={16}>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>
      <DefaultGrid.Row id="MainContents" centered>
        <DefaultGrid.PaddingColumn
          mobile={16}
          tablet={12}
          computer={8}
          padding={0}
        >
          <DefaultGrid>
            <DefaultGrid.Row id="TitleContainer" columns="equal">
              <DefaultGrid.Column id="TitleColumn" computer={4} mobile={7}>
                <MainHeader id="Title">子ども一覧</MainHeader>
              </DefaultGrid.Column>
              <DefaultGrid.Column />
            </DefaultGrid.Row>
            <DefaultGrid.PaddingRow id="ControllsContainer" padding={0}>
              <DefaultGrid.PaddingColumn
                id="ControllsColumn"
                width={16}
                padding={0}
              >
                <Controlls />
              </DefaultGrid.PaddingColumn>
            </DefaultGrid.PaddingRow>
            <DefaultGrid.PaddingRow id="ListContainer" paddingtop={2}>
              <DefaultGrid.PaddingColumn id="ListColumn" width={16} padding={0}>
                <ChildrenList />
              </DefaultGrid.PaddingColumn>
            </DefaultGrid.PaddingRow>
          </DefaultGrid>
        </DefaultGrid.PaddingColumn>
      </DefaultGrid.Row>
    </PaddingGrid>
  );
};
export default withRouter(withHeaderAndFooter(ChildrenListPage));
