import MainHeader from "components/atoms/MainHeader";
import { withHeaderAndFooter } from "components/organisms/Header";
import { State } from "interfaces/State";
import React, { ComponentProps, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, NavLink, Redirect, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  Image,
  Loader,
  Grid,
} from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { size } from "styles/Size";
import ROUTES from "utils/routes";
import DestinationEditor from "./parts/DestinationEditor";
import transactionEditActionCreator from "./TransactionEditAction";
import { TransactionEditState } from "./TransactionEditReducer";
import { COLORS } from "utils/ComponentUtils";

const SubHeader = styled.h2`
  font-size: 1.2rem;
`;

const TransactionsEdit: React.FC = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      ...bindActionCreators(transactionEditActionCreator, dispatch),
    }),
    [dispatch]
  );
  const { transaction, orphanage, loading } = useSelector<
    State,
    TransactionEditState
  >((s) => s.transactionEdit);

  const { id } = useParams<TransactionEditParams>();

  useEffect(() => {
    actions.getTransaction(id);
    return () => {
      actions.setTransaction(undefined);
    };
  }, [id, actions]);

  const RowStyled = styled(Grid.Row)`
    &&& {
      font-weight: 300;
      font-size: 0.6rem;
      position: relative;
      max-height: 11em;
      overflow: hidden;
      padding-right: 0.5rem;
      ::before {
        position: absolute;
        content: "...";
        bottom: 0;
        right: 0;
      }
      ::after {
        content: "";
        position: absolute;
        right: 0;
        width: 1rem;
        height: 1rem;
        background: white;
      }
    }
  `;
  const ColumnStyled = styled(Row)`
    border: 1px solid ${COLORS.BORDER};
    border-radius: 5px;
  `;
  const ImageStyled = styled(Image)`
    &&& {
      height: 115px;
      object-fit: contain;
    }
  `;

  return transaction?.status !== undefined &&
    transaction?.status !== "address_unset" ? (
    <Redirect to={generatePath(ROUTES.TRANSACTIONS_DETAIL, { id })} />
  ) : (
    <DefaultGrid id="TransactionsEdit">
      <Row id="BreadcrumbsRow">
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumb icon="left angle" sections={BreadcrumbsSections(id)} />
        </PaddingColumn>
      </Row>
      <Row id="MainContentRow" centered>
        <ResizeableColumn size={{ maxWidth: 375 }}>
          <FluidGrid id="MainContentGrid">
            <Row id="TitleRow" centered>
              <MainHeader>申し込み履歴詳細</MainHeader>
            </Row>
            <Row id="BodyRow">
              {transaction === undefined ? (
                <FluidGrid id="LoaderGrid">
                  <Loader active />
                </FluidGrid>
              ) : (
                <FluidGrid id="BodyGrid">
                  <ColumnStyled id="HeadlineRow" columns="equal">
                    {transaction.donation?.images[0] && (
                      <Column>
                        <ImageStyled
                          src={transaction.donation.images[0].thumbnail_url}
                          alt={transaction.donation.name}
                          size="medium"
                        />
                      </Column>
                    )}
                    <Column>
                      <Grid.Row>商品詳細</Grid.Row>
                      <RowStyled style={{ whiteSpace: "pre-line" }}>
                        {transaction.donation.description}
                      </RowStyled>
                    </Column>
                  </ColumnStyled>
                  <PaddingRow id="NameRow" paddingbottom={0}>
                    <SubHeader>{transaction?.donation.name}</SubHeader>
                  </PaddingRow>
                  <PaddingRow id="DestinationInfoRow" paddingtop={0}>
                    <DestinationEditor
                      transaction={transaction}
                      orphanage={orphanage}
                      loading={loading}
                    />
                  </PaddingRow>
                </FluidGrid>
              )}
            </Row>
          </FluidGrid>
        </ResizeableColumn>
      </Row>
    </DefaultGrid>
  );
};

export default withHeaderAndFooter(TransactionsEdit);

export interface TransactionEditParams {
  id: string;
}

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const BreadcrumbsSections = (id: string): BreadcrumbSectionProps[] => [
  {
    key: "dummy",
  },
  {
    key: "BackToTransactions",
    content: "申し込み詳細履歴へ戻る",
    as: NavLink,
    active: true,
    to: generatePath(ROUTES.TRANSACTIONS_DETAIL, { id }),
  },
];

const ResizeableGrid = size(padding(DefaultGrid));
const ResizeableColumn = size(Column);
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
