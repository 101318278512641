import React, { useState } from "react";

import { Form } from "semantic-ui-react";

import ChangePasswordCreators from "./ChangePasswordActions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";
import { SCOPE } from "containers/App/GlobalTypes";

import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";

import TextBox from "components/atoms/TextBox";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";

import { withHeaderAndFooter } from "components/organisms/Header";
import { Breadcrumb } from "semantic-ui-react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import MainHeader from "components/atoms/MainHeader";
import { BREAKPOINTS } from "utils/ComponentUtils";
import { useQuery } from "utils/ComponentUtils";
import Validate from "utils/Validate";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 600px !important;
`;

const ResetHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 1.1rem;
    }
  }
`;

const ChangePasswordPage: React.FC = () => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: State) => state.changePassword);
  const [clickedScope, setClickedScope] = useState<string>();

  const initialFormValues = {
    password: "",
    password_confirmation: "",
  };
  const query = useQuery();
  const reset_password_token = query.get("reset_password_token");
  const userType = query.get("user_type");

  const changePasswordScheme = Yup.object().shape({
    password: Validate.password,
    password_confirmation: Validate.password_confirmation,
  });

  const clickAndSetRole = (scope: string) => {
    setClickedScope(scope);
  };

  const handleSubmit = async (values: Record<string, string>) => {
    if (clickedScope) {
      dispatch(
        ChangePasswordCreators.changePassword(
          reset_password_token,
          clickedScope,
          values.password,
          values.password_confirmation
        )
      );
    }
  };
  const sections = [
    { key: "", content: "", link: false },
    {
      key: "トップに戻る",
      content: "トップに戻る",
      to: "/",
      active: true,
      as: NavLink,
    },
  ];

  return (
    <PaddingGrid
      id="ChangePassword"
      paddingleft={16}
      paddingright={16}
      centered
    >
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumb icon="left angle" sections={sections} />
        </PaddingColumn>
      </PaddingRow>

      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <DefaultGrid>
                <PaddingRow centered>
                  <DefaultGrid.Column textAlign={"center"}>
                    <ResetHeader>
                      新しいパスワードを設定してください
                    </ResetHeader>
                  </DefaultGrid.Column>
                </PaddingRow>
              </DefaultGrid>
              {error && (
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column textAlign={"center"}>
                    <InfoMessage
                      severity="error"
                      message={
                        error.toString() ===
                        "Error: Reset password token不正な値です"
                          ? "パスワード変更期限が過ぎました。"
                          : error.toString().slice(7)
                      }
                    />
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
              )}
              <PaddingRow paddingtop={50} centered>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialFormValues}
                  validationSchema={changePasswordScheme}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    handleSubmit,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <DefaultGrid.Row centered>
                        <DefaultGrid.Column width={6}>
                          <Form.Group widths="equal">
                            <TextBox
                              type="password"
                              error={
                                touched.password &&
                                errors.password && { content: errors.password }
                              }
                              id="password"
                              name="password"
                              label="新しいパスワード"
                              placeholder="新しいパスワード"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fluid={true}
                              autoComplete="new-password"
                            />
                          </Form.Group>
                        </DefaultGrid.Column>
                      </DefaultGrid.Row>

                      <DefaultGrid.Row centered>
                        <DefaultGrid.Column width={6}>
                          <Form.Group widths="equal">
                            <TextBox
                              type="password"
                              error={
                                touched.password_confirmation &&
                                errors.password_confirmation && {
                                  content: errors.password_confirmation,
                                }
                              }
                              id="password_confirmation"
                              name="password_confirmation"
                              label="パスワード確認"
                              placeholder="パスワード確認"
                              value={values.password_confirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fluid={true}
                              autoComplete="new-password"
                            />
                          </Form.Group>
                        </DefaultGrid.Column>
                      </DefaultGrid.Row>
                      {userType === "orphanage_user" && (
                        <DefaultGrid>
                          <DefaultGrid.Row centered>
                            <DefaultGrid.Column style={{ width: "150px" }}>
                              <DesignedButton
                                type="button"
                                onClick={() => {
                                  clickAndSetRole(SCOPE.ORPHANAGE);
                                  handleSubmit();
                                }}
                                fluid={true}
                              >
                                変更する
                              </DesignedButton>
                            </DefaultGrid.Column>
                          </DefaultGrid.Row>
                        </DefaultGrid>
                      )}
                      {userType === "child_user" && (
                        <DefaultGrid>
                          <DefaultGrid.Row centered>
                            <DefaultGrid.Column style={{ width: "150px" }}>
                              <DesignedButton
                                type="button"
                                onClick={() => {
                                  clickAndSetRole(SCOPE.CHILD);
                                  handleSubmit();
                                }}
                                fluid={true}
                              >
                                変更する
                              </DesignedButton>
                            </DefaultGrid.Column>
                          </DefaultGrid.Row>
                        </DefaultGrid>
                      )}
                    </Form>
                  )}
                </Formik>
              </PaddingRow>
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withHeaderAndFooter(ChangePasswordPage);
