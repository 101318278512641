import React from "react";
import styled from "styled-components";
import { px } from "./styleUtil";

interface PaddingOptions {
  padding?: number | string | undefined;
  paddingtop?: number | string | undefined;
  paddingright?: number | string | undefined;
  paddingleft?: number | string | undefined;
  paddingbottom?: number | string | undefined;
}

const padding = function <P>(Wrapped: React.ComponentType<P>) {
  return styled(Wrapped)`
    &&& {
      padding: ${(props: P & PaddingOptions) => px(props.padding, true)};
      padding-top: ${(props: P & PaddingOptions) => px(props.paddingtop, true)};
      padding-right: ${(props: P & PaddingOptions) =>
        px(props.paddingright, true)};
      padding-left: ${(props: P & PaddingOptions) =>
        px(props.paddingleft, true)};
      padding-bottom: ${(props: P & PaddingOptions) =>
        px(props.paddingbottom, true)};
    }
  ` as unknown as React.FC<P & PaddingOptions>;
};

export default padding;
