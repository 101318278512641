import { go, goBack, goForward, push, replace } from "connected-react-router";
import React from "react";
import { useLocation } from "react-router";

export const getDisplayName = <P>(
  WrappedComponent: React.ComponentType<P>
): string => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export interface withNavigate {
  navigate: typeof navigateActionsCreators;
}

export const navigateActionsCreators = {
  push,
  replace,
  go,
  goBack,
  goForward,
};

export const ObjectEntries = <T extends {}>(
  obj: T
): Array<[keyof T, T[keyof T]]> => {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
};

export const BREAKPOINTS = {
  MOBILE: "768px",
  TABLET: "990px",
  DESKTOP: "1200px",
  LARGEMONITOR: "1440px",
  WIDESCREEN: "1920px",
};

export const COLORS = {
  PRIMARY: "#00B5AD",
  SECONDARY: "#FF7F50",
  DISABLED: "#E0E1E2",
  DISABLED_TEXT: "#B4AEAE",
  BORDER: "#a9a9a9",
  SWIPER: "#f1f1f1",
};

export const DateFormatter = (
  date?: Date | null,
  format = "YYYY/MM/DD"
): string => {
  if (date) {
    return format
      .replace(/YYYY/, date.getFullYear().toString())
      .replace(/MM/g, (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace(/M/g, (date.getMonth() + 1).toString())
      .replace(/DD/g, date.getDate().toString().padStart(2, "0"))
      .replace(/D/g, date.getDate().toString())
      .replace(/hh/g, date.getHours().toString().padStart(2, "0"))
      .replace(/h/g, date.getHours().toString())
      .replace(/mm/g, date.getMinutes().toString().padStart(2, "0"))
      .replace(/m/g, date.getMinutes().toString())
      .replace(/ss/g, date.getSeconds().toString().padStart(2, "0"))
      .replace(/s/g, date.getSeconds().toString())
      .replace(/SSS/g, date.getMilliseconds().toString().padStart(3, "0"))
      .replace(/S/g, date.getMilliseconds().toString());
  } else {
    return "";
  }
};

export enum STATUS {
  before_delivery = "配送前",
  delivering = "配送中",
  address_unset = "配送先未確定",
  delivered = "受取済み",
  cancelled = "キャンセル",
}
export const statusMapper = (value: StatusKey | null | undefined) => {
  if (value) {
    return STATUS[value];
  } else {
    return "";
  }
};

export type StatusKey = keyof typeof STATUS;

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
