import React, { useEffect } from "react";

import {
  Form,
  Segment,
  BreadcrumbSectionProps,
  Breadcrumb,
} from "semantic-ui-react";

import { Formik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import { State } from "interfaces/State";

import DefaultGrid from "styles/DefaultGrid";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";
import TextArea from "components/atoms/TextArea";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";

import { withHeaderAndFooter } from "components/organisms/Header";
import padding from "styles/Padding";
import MainHeader from "components/atoms/MainHeader";
import LinkButton from "components/atoms/LinkButton";
import ROUTES from "utils/routes";
import { transactionsActionsCreators } from "modules/db/Transactions/TransactionsActions";
import { NavLink } from "react-router-dom";

const PaddingGrid = padding(DefaultGrid);

const ContentsRow = styled(DefaultGrid.Row)`
  &&& {
    max-width: 600px !important;
    padding-top: 20px;
  }
`;

const MessageHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
  }
`;

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const SegmentContent = styled.div`
  font-size: 0.8rem;
`;

interface RouterProps {
  id: string;
}

interface Message {
  receiver_message: string;
}

const Message: React.FC<RouteComponentProps<RouterProps>> = (props) => {
  const { error } = useSelector((state: State) => state.deleteAccount);
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const initialFormValues: Message = {
    receiver_message: "",
  };
  const handleSubmit = async (values: Message) => {
    dispatch(
      transactionsActionsCreators.completeTransaction(
        id,
        values.receiver_message
      )
    );
  };
  const BreadcrumbsSections: BreadcrumbSectionProps[] = [
    {
      key: "dummy",
    },
    {
      key: "BackToTransactions",
      content: "申し込み履歴へ戻る",
      as: NavLink,
      to: ROUTES.TRANSACTIONS,
    },
  ];
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <PaddingGrid id="Message" paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumb icon="left angle" sections={BreadcrumbsSections} />
        </PaddingColumn>
      </PaddingRow>
      <ContentsRow id="Contents">
        <DefaultGrid.Column id="InputColumn" width={16}>
          <DefaultGrid width={16}>
            <Row centered>
              <DefaultGrid.Column textAlign={"center"}>
                <MessageHeader>受取完了</MessageHeader>
              </DefaultGrid.Column>
            </Row>
            <PaddingRow centered>
              <Segment secondary textAlign={"left"}>
                <h4>メッセージを贈ることができます</h4>
                <SegmentContent>
                  メッセージは寄付者にお伝えしますので、たくさん書いていただけるとうれしいです。
                  <br />
                  また、いただいたメッセージの一部が一般に公開される場合があります。
                  <br />
                  いずれの場合も、個人が特定されない状態で使用しますので、ご安心ください。
                </SegmentContent>
              </Segment>
            </PaddingRow>
          </DefaultGrid>
          {error && (
            <DefaultGrid.Row centered>
              <DefaultGrid.Column textAlign={"center"}>
                <InfoMessage severity="error" message={error.toString()} />
              </DefaultGrid.Column>
            </DefaultGrid.Row>
          )}
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column width={6}>
                    <Form.Group widths="equal">
                      <TextArea
                        id="receiver_message"
                        name="receiver_message"
                        label="メッセージ入力欄"
                        value={values.receiver_message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ height: 130 }}
                      />
                    </Form.Group>
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
                <Row
                  id="ControlsRow"
                  style={{ paddingTop: 32, paddingBottom: 32 }}
                >
                  <Column width={16}>
                    <DefaultGrid>
                      <PaddingRow padding={0} centered>
                        <LinkButton
                          to={ROUTES.TRANSACTIONS}
                          design="primary"
                          inverted
                          style={{ width: "120px" }}
                        >
                          キャンセル
                        </LinkButton>
                        <DesignedButton style={{ width: "120px" }}>
                          受取確認
                        </DesignedButton>
                      </PaddingRow>
                    </DefaultGrid>
                  </Column>
                </Row>
              </Form>
            )}
          </Formik>
        </DefaultGrid.Column>
      </ContentsRow>
    </PaddingGrid>
  );
};
export default withHeaderAndFooter(Message);
