const ONLY_JAPANESE_ALLOWED = /^[一-龯ぁ-ゔゞァ-・ヽヾ゛゜ー]+$/;
const NO_SPECIAL_CHARACTERS_ALLOWED =
  /^[０-９0-9A-Za-zＡ-ｚ一-龯ぁ-ゔゞァ-・ヽヾ゛゜ー\s]+$/;
const HIRAGANA_SPECIAL_CHARS_NOT_ALLOWED = /^[ぁ-ゔゞ゛゜ー]+$/;
const MUST_BE_DATE = /^[０-９0-9_/.-]+$/;
const ONLY_NUMBER = /^[0-9]+$/;

const REGEX_STRINGS = {
  ONLY_JAPANESE_ALLOWED,
  NO_SPECIAL_CHARACTERS_ALLOWED,
  HIRAGANA_SPECIAL_CHARS_NOT_ALLOWED,
  MUST_BE_DATE,
  ONLY_NUMBER,
};

export default REGEX_STRINGS;
