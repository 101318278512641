import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  GetLikesChildAction,
  GetLikesOrphanageAction,
  GetLikesResponse,
} from "./LikesTypes";
import actions from "./LikesConstants";
import { likesActionsCreators } from "./LikesAction";
import requestErrorCheck from "utils/requestErrorCheck";
import request, {
  AsyncReturnType,
  requestHeadersAuthorized,
} from "utils/request";
import { accessToken } from "containers/App/GlobalSelectors";

function* LikesChildSaga(params: GetLikesChildAction) {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/likes`;
    const responseAccessToken = yield select(accessToken);

    const likes: AsyncReturnType<typeof getLikesChild> = yield call(
      getLikesChild,
      requestURL,
      responseAccessToken,
      params.payload.pagination,
      params.payload.childUserId
    );

    yield put(
      likesActionsCreators.getLikesSuccess(likes.data, likes.total_pages)
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(likesActionsCreators.getLikesError(error));
  }
}

function* LikesOrphanageSaga(params: GetLikesOrphanageAction) {
  try {
    // swagger hubで/orphanage_user/chiled_likesとなっているが、実際は/orphanage_user/child_likesで実装されていた。
    //const requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/chiled_likes`;
    const requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/child_likes`;
    const responseAccessToken = yield select(accessToken);

    const likes: AsyncReturnType<typeof getLikesOrphanage> = yield call(
      getLikesOrphanage,
      requestURL,
      responseAccessToken,
      params.payload.pagination
    );

    yield put(
      likesActionsCreators.getLikesSuccess(likes.data, likes.total_pages)
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(likesActionsCreators.getLikesError(error));
  }
}

const getLikesChild = async (
  requestURL: string,
  responseAccessToken: string,
  pagination: GetLikesChildAction["payload"]["pagination"],
  childUserId: number
) => {
  const page = Object.entries(pagination || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      return `pagination[${key}]=` + (value || "");
    })
    .join("&");

  return request(
    requestURL +
      ("?child_user_id=" + childUserId + "&") +
      (page.length !== 0 ? page : ""),
    { method: "GET", ...requestHeadersAuthorized(responseAccessToken) }
  ) as Promise<GetLikesResponse>;
};

const getLikesOrphanage = async (
  requestURL: string,
  responseAccessToken: string,
  pagination: GetLikesOrphanageAction["payload"]["pagination"]
) => {
  const page = Object.entries(pagination || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      return `pagination[${key}]=` + (value || "");
    })
    .join("&");

  return request(requestURL + (page.length !== 0 ? "?" + page : ""), {
    method: "GET",
    ...requestHeadersAuthorized(responseAccessToken),
  }) as Promise<GetLikesResponse>;
};

export default function* LikeRootSaga() {
  yield takeLatest(actions.GET_LIKES_CHILD, LikesChildSaga);
  yield takeLatest(actions.GET_LIKES_ORPHANAGE, LikesOrphanageSaga);
}
