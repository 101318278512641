import actions from "./ChangeEmailConstants";

import {
  ChangeEmail,
  ChangeEmailAction,
  ChangeEmailSuccessAction,
  ChangeEmailErrorAction,
} from "./ChangeEmailTypes";

const changeEmail = (emailParams: ChangeEmail): ChangeEmailAction => {
  return {
    type: actions.CHANGE_EMAIL,
    payload: {
      emailParams,
    },
  };
};

const changeEmailSuccess = (): ChangeEmailSuccessAction => {
  return {
    type: actions.CHANGE_EMAIL_SUCCESS,
  };
};

const changeEmailError = (error: Error): ChangeEmailErrorAction => {
  return {
    type: actions.CHANGE_EMAIL_ERROR,
    payload: {
      error,
    },
  };
};

const ChangeEmailCreators = {
  changeEmail,
  changeEmailSuccess,
  changeEmailError,
};

export default ChangeEmailCreators;
