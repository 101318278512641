import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";
import MainHeader from "components/atoms/MainHeader";
import TextBox from "components/atoms/TextBox";
import { withHeaderAndFooter } from "components/organisms/Header";
import { globalActionsCreators } from "containers/App/GlobalActions";
import { Login, SCOPE } from "containers/App/GlobalTypes";
import Error_logo from "Error_logo.png";
import { Formik } from "formik";
import { State } from "interfaces/State";
import logo from "logo.png";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Form, Grid } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { navigateActionsCreators } from "utils/ComponentUtils";
import ROUTES, { EXTRANAL_ROUTES } from "utils/routes";
import * as Yup from "yup";
import Validate from "utils/Validate";
import ERROR_MESSAGES from "utils/errorMessages";

const GridRow = styled(Grid.Row)`
  &&& {
    font-size: 12px;
    margin-bottom: 5px;
    padding: 0;
    text-decoration: underline;
  }
`;

const LoginHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
    text-align: center;
  }
`;
const LoginForm = styled(DefaultGrid.Row)`
  &&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const MaxGridColumn = styled(DefaultGrid.Column)`
  &&& {
    max-width: 311px;
  }
`;
const MaxButtonColumn = styled(DefaultGrid.Column)`
  &&& {
    max-width: 280px;
  }
`;

const H1 = styled.h1`
  color: #02b5ad;
`;

const LoginScheme = Yup.object().shape({
  email: Validate.email,
  password: Validate.password,
});

const initialFormValues: Login = {
  email: "",
  password: "",
};

const LogIn: React.FC = () => {
  const dispatch = useDispatch();
  const { loggedIn, isLoading, error } = useSelector(
    (state: State) => state.logIn
  );

  const actions = useMemo(() => {
    return {
      navigate: bindActionCreators(navigateActionsCreators, dispatch),
      global: bindActionCreators(globalActionsCreators, dispatch),
    };
  }, [dispatch]);

  const handleSubmit = (values: Login) => {
    if (values.scope) {
      actions.global.logIn(
        "password",
        values.email,
        values.password,
        values.scope
      );
    }
  };

  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      // Todo: 寄付品詳細画面からログインした場合に申請条件を満たしたユーザの場合は申込みページに遷移させる
      // if (history.location.search) {
      //   actions.navigate.push(
      //     decodeURIComponent(history.location.search.split("?redirectURL=")[1])
      //   );
      // } else {
      //   actions.navigate.push(ROUTES.TOP);
      // }
      actions.navigate.push(ROUTES.TOP);
    }
  }, [loggedIn, actions.navigate, history.location.search]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <DefaultGrid centered style={{ paddingTop: 30 }}>
        <DefaultGrid.Row>
          <DefaultGrid.Column>
            <LoginHeader as={H1}>ログイン</LoginHeader>
          </DefaultGrid.Column>
        </DefaultGrid.Row>
        {!error && (
          <DefaultGrid.Row>
            <img src={logo} alt="ロゴ画像" title="ロゴ画像" />
          </DefaultGrid.Row>
        )}
        {error && (
          <DefaultGrid.Row>
            <img src={Error_logo} alt="エラー画像" title="エラー画像" />
          </DefaultGrid.Row>
        )}

        {error && (
          <DefaultGrid.Row>
            <DefaultGrid.Column>
              <InfoMessage
                severity={"error"}
                message={ERROR_MESSAGES.LOGIN_ERROR}
              />
            </DefaultGrid.Column>
          </DefaultGrid.Row>
        )}
      </DefaultGrid>

      <Formik
        validationSchema={LoginScheme}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DefaultGrid centered>
              <LoginForm centered>
                <MaxGridColumn mobile={14} tablet={8}>
                  <Form.Group widths="equal">
                    <TextBox
                      error={
                        touched.email &&
                        errors.email && { content: errors.email }
                      }
                      id="email"
                      name="email"
                      label="メールアドレス"
                      placeholder="メールアドレス"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                      disabled={isLoading}
                    />
                  </Form.Group>
                </MaxGridColumn>
              </LoginForm>

              <LoginForm centered>
                <MaxGridColumn mobile={14} tablet={8}>
                  <Form.Group widths="equal">
                    <TextBox
                      type="password"
                      error={
                        touched.password &&
                        errors.password && { content: errors.password }
                      }
                      id="password"
                      name="password"
                      label="パスワード"
                      placeholder="パスワード"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                      disabled={isLoading}
                    />
                  </Form.Group>
                </MaxGridColumn>
              </LoginForm>

              <DefaultGrid centered>
                <GridRow>
                  <MaxGridColumn mobile={15} tablet={12}>
                    <Link to={ROUTES.RESET_PASSWORD}>
                      パスワードを忘れた方はこちら
                    </Link>
                  </MaxGridColumn>
                </GridRow>
                <GridRow>
                  <MaxGridColumn mobile={15} tablet={12}>
                    <a
                      href={EXTRANAL_ROUTES.CONTACT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      メールアドレスを忘れた方はお問い合わせください
                    </a>
                  </MaxGridColumn>
                </GridRow>
              </DefaultGrid>
              <DefaultGrid.Row centered style={{ paddingBottom: 0 }}>
                <MaxButtonColumn mobile={11} tablet={5} computer={4}>
                  <DesignedButton
                    type="submit"
                    id="ScopeOrphanage"
                    name="scope"
                    value={SCOPE.ORPHANAGE}
                    onClick={handleChange}
                    disabled={isLoading}
                    fluid
                  >
                    施設・里親としてログイン
                  </DesignedButton>
                </MaxButtonColumn>
              </DefaultGrid.Row>

              <DefaultGrid.Row centered>
                <MaxButtonColumn mobile={11} tablet={5} computer={4}>
                  <DesignedButton
                    type="submit"
                    id="ScopeChild"
                    name="scope"
                    value={SCOPE.CHILD}
                    onClick={handleChange}
                    disabled={isLoading}
                    fluid
                  >
                    個人としてログイン
                  </DesignedButton>
                </MaxButtonColumn>
              </DefaultGrid.Row>
            </DefaultGrid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default withHeaderAndFooter(LogIn);
