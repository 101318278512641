import ROUTES from "utils/routes";

type BreadcrumbMap = {
  [K: string]: string;
};

const BREADCRUMB_MAP: BreadcrumbMap = {
  [ROUTES.TOP]: "TOP",
  [ROUTES.DONATIONS]: "寄付一覧",
  [ROUTES.LIKES]: "お気に入りリスト",
  [ROUTES.DONATION_DETAIL]: "寄付品詳細",
  [ROUTES.SETTINGS]: "設定",
  [ROUTES.CHILDREN_LIST]: "子ども一覧",
  [ROUTES.TRANSACTIONS]: "申し込み履歴",
  [ROUTES.ADD_CHILD]: "子ども登録",
  [ROUTES.EDIT_CHILD]: "子ども編集",
  [ROUTES.PROFILE]: "プロフィール",
  [ROUTES.CHANGE_EMAIL]: "メールアドレスの変更",
  [ROUTES.DELETE_ACCOUNT]: "退会",
};

export default BREADCRUMB_MAP;
