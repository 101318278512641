import { OrphanageResponse } from "api/v1/OrphanageUser/OrphanageUserTypes";
import { GetTransactionsResponse } from "interfaces/db/Transaction";
import { Reducer } from "redux";
import { Actions } from "./TransactionEditAction";
import ActionTypes from "./TransactionEditConstants";

export interface TransactionEditState {
  transaction?: GetTransactionsResponse;
  orphanage?: OrphanageResponse;
  loading: boolean;
}

const initialState = () => ({
  transaction: undefined,
  orphanage: undefined,
  loading: false,
});

const transactionEditReducer: Reducer<TransactionEditState, Actions> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case ActionTypes.GET_TRANSACTION:
    case ActionTypes.GET_ORPHANAGE:
    case ActionTypes.SUBMIT:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload.transaction,
        loading: false,
      };
    case ActionTypes.SET_ORPHANAGE:
      return {
        ...state,
        orphanage: action.payload.orphanage,
        loading: false,
      };
    default:
      return state;
  }
};

export default transactionEditReducer;
