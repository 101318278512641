import { GetTransactionsResponse } from "interfaces/db/Transaction";
import { Reducer } from "redux";
import { Actions } from "./TransactionDetailAction";
import ActionTypes from "./TransactionDetailConstants";

export interface TransactionDetailState {
  transaction?: GetTransactionsResponse;
  loading: boolean;
}

const initialState = () => ({
  transaction: undefined,
  loading: false,
});

const transactionDetailReducer: Reducer<TransactionDetailState, Actions> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case ActionTypes.GET_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload.transaction,
      };
    default:
      return state;
  }
};

export default transactionDetailReducer;
