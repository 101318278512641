import DesignedButton from "components/atoms/DesignedButton";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { Card, Grid, Image } from "semantic-ui-react";
import styled from "styled-components";
import { navigateActionsCreators } from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import { BREAKPOINTS } from "utils/ComponentUtils";
import { bindActionCreators } from "redux";
import React, { useMemo } from "react";
import LikeLabel from "components/atoms/LikeLabel";
import PointLabel from "components/atoms/PointLabel";
import { State } from "interfaces/State";
import LikeCreators from "../../../modules/db/Like/LikeActions";
import { likesActionsCreators as actionCreators } from "modules/db/Likes/LikesAction";
import { Scopes } from "containers/App/GlobalTypes";
import { Donation } from "interfaces/db/Donation";

const LabelStyled = styled(Grid.Column)`
  &&& {
    font-family: "Helvetica";
    white-space: nowrap;
  }
`;
const TitleStyled = styled(Grid.Row)`
  &&& {
    font-style: normal;
    font-size: 1.3rem;
    font-weight: 700;
    flex-grow: 0 !important;
    padding-bottom: 1rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 0.9rem;
      padding-bottom: 0.5rem;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const RowStyled = styled(Grid.Row)`
  &&& {
    flex-grow: 0 !important;
    padding-bottom: 0.5rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 0.8rem;
      padding-bottom: 0.5rem;
    }
    white-space: normal;
  }
`;
const ButtonStyled = styled(Grid.Row)`
  align-items: flex-end;
  margin-top: auto;
`;
const ImageStyled = styled(Image)`
  &&& {
    flex-direction: center;
    height: 200px;
    width: 100%;
    object-fit: contain;
    @media (max-width: ${BREAKPOINTS.DESKTOP}) {
      // height: 110px;
      object-fit: contain;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      // height: 50px;
      object-fit: contain;
    }
  }
`;
const CardButton = styled(DesignedButton)`
  &&& {
    font-size: 1rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
  }
`;

interface LikesProps {
  user_id: number;
  scope: Scopes;
  donation: Donation;
  image: string;
}

const LikeCards: React.FC<LikesProps> = (props) => {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      like: bindActionCreators(LikeCreators, dispatch),
      likes: bindActionCreators(actionCreators, dispatch),
      navigate: bindActionCreators(navigateActionsCreators, dispatch),
    };
  }, [dispatch]);
  const pagination = useSelector((state: State) => state.likes.pagination);

  const deleteLike = () => {
    // ユーザーのidはstoreから取得する
    // donation_idはpropsで渡す
    actions.like.Like(
      props.user_id.toString(),
      props.donation.id.toString(),
      "DELETE"
    );
    dispatch(
      actions.likes.getLikesChild(props.scope, pagination, props.user_id)
    );
  };

  return (
    <Card fluid centered>
      <Card.Content>
        <Grid>
          <Grid.Row stretched>
            <Grid.Column mobile={5}>
              <Grid.Row>
                <ImageStyled
                  id="DonationImage"
                  src={props.image}
                  size="medium"
                  centered
                />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              mobile={11}
              style={{
                paddingLeft: 0,
              }}
            >
              <Grid.Row style={{ display: "flex", flexDirection: "column" }}>
                <LabelStyled textAlign={"left"}>
                  {/* 寄付品のタイトル */}
                  {props.donation.public === false ||
                  props.donation.stock_num === 0 ? (
                    <TitleStyled style={{ color: "rgba(0, 0, 0, 0.38)" }}>
                      <strong>{props.donation.name}</strong>
                    </TitleStyled>
                  ) : (
                    <TitleStyled>
                      <strong>{props.donation.name}</strong>
                    </TitleStyled>
                  )}

                  {/* 寄付品のポイント数 */}
                  {props.donation.public === false ||
                  props.donation.stock_num === 0 ? (
                    <RowStyled>
                      <PointLabel
                        point={props.donation.point.toString()}
                        color="rgba(0, 0, 0, 0.38)"
                      />
                    </RowStyled>
                  ) : (
                    <RowStyled>
                      <PointLabel
                        point={props.donation.point.toString()}
                        color="#00B5AD"
                      />
                    </RowStyled>
                  )}

                  {props.donation.public === false ||
                  props.donation.stock_num === 0 ? (
                    /* 申し込み終了のお知らせ */
                    <RowStyled style={{ color: "red" }}>
                      申し込みの受付を終了しました。
                    </RowStyled>
                  ) : (
                    /* 寄付品の残数 */
                    <RowStyled>
                      {"残り" + props.donation.stock_num + "点"}
                    </RowStyled>
                  )}

                  {/* お気に入りされている数(ハート) */}
                  <RowStyled>
                    <LikeLabel
                      flex_placement="flex-start"
                      color="#00B5AD"
                      like_num={props.donation.like_num}
                    />
                  </RowStyled>

                  {/* お気に入り登録している子供ユーザー */}
                  {props.scope === "orphanage_user" && (
                    <>
                      <Grid.Column xs={1}>
                        <RowStyled>
                          {<b>お気に入り登録：</b>}
                          {props.donation.liked_children.map((value, i) => (
                            <>
                              {value.full_name +
                                (props.donation.liked_children.length - 1 === i
                                  ? ""
                                  : ", ")}
                            </>
                          ))}
                        </RowStyled>
                      </Grid.Column>
                    </>
                  )}
                </LabelStyled>

                <ButtonStyled textAlign={"right"} style={{ paddingTop: 0 }}>
                  {props.scope === "child_user" && (
                    <CardButton
                      inverted
                      onClick={deleteLike}
                      style={{ marginRight: "0.5rem" }}
                    >
                      {window.innerWidth < 768 ? (
                        <>削除</>
                      ) : (
                        <>リストから削除</>
                      )}
                    </CardButton>
                  )}

                  {props.donation.public === false ||
                  props.donation.stock_num === 0 ? (
                    <CardButton disabled>寄付品詳細</CardButton>
                  ) : (
                    <CardButton
                      onClick={(e: { preventDefault: () => void }) =>
                        actions.navigate.push(
                          generatePath(ROUTES.DONATION_DETAIL, {
                            id: props.donation.id,
                          })
                        )
                      }
                    >
                      寄付品詳細
                    </CardButton>
                  )}
                </ButtonStyled>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default LikeCards;
