import { Donation } from "interfaces/db/Donation";
import {
  GetDonationAction,
  SetDonationAction,
  ResetDonationAction,
} from "./DonationType";
import actions from "./DonationConstants";

export const getDonation = (id: number): GetDonationAction => {
  return {
    type: actions.GET_DONATION,
    payload: {
      id,
    },
  };
};

export const setDonation = (data: Donation): SetDonationAction => {
  return {
    type: actions.SET_DONATION,
    payload: {
      data,
    },
  };
};

export const resetDonation = (): ResetDonationAction => {
  return {
    type: actions.RESET_DONATION,
  };
};

export const donationActionsCreators = {
  getDonation,
  setDonation,
  resetDonation,
};
