import { Sort } from "modules/db/Donations/DonationsTypes";

type sortType = {
  NEWEST: {
    label: string;
    value: {
      type: Sort["type"];
      order: Sort["order"];
    };
  };
  OLDEST: {
    label: string;
    value: {
      type: Sort["type"];
      order: Sort["order"];
    };
  };
  POINT_HIGHEST: {
    label: string;
    value: {
      type: Sort["type"];
      order: Sort["order"];
    };
  };
  POINT_LOWEST: {
    label: string;
    value: {
      type: Sort["type"];
      order: Sort["order"];
    };
  };
};

export const SORT: sortType = {
  NEWEST: {
    label: "新しい順",
    value: {
      type: "created_at",
      order: "desc",
    },
  },
  OLDEST: {
    label: "古い順",
    value: {
      type: "created_at",
      order: "asc",
    },
  },
  POINT_HIGHEST: {
    label: "ポイントが高い順",
    value: {
      type: "point",
      order: "desc",
    },
  },
  POINT_LOWEST: {
    label: "ポイントが低い順",
    value: {
      type: "point",
      order: "asc",
    },
  },
};

type existType = {
  ONLY_EXIST: {
    label: string;
    value: string;
  };
  ALL: {
    label: string;
    value: undefined;
  };
};

export const EXIST: existType = {
  ONLY_EXIST: {
    label: "在庫あり",
    value: "0",
  },
  ALL: {
    label: "すべてを表示",
    value: undefined,
  },
};
