import { Reducer } from "redux";
import actions from "./DonationsConstants";
import {
  GetDonationsOptions,
  Pagination,
  Sort,
} from "../../../modules/db/Donations/DonationsTypes";
import {
  DonationsActionTypes,
  SetSearchConditionAction,
  SetPaginationAction,
  SetSortAction,
} from "./DonationsActions";

export interface DonationsState {
  searchCondition: GetDonationsOptions;
  pagination: Pagination;
  sort: Sort;
}

const initialState: DonationsState = {
  searchCondition: {},
  pagination: {
    limit: 24,
    page: 1,
  },
  sort: {
    type: "updated_at",
    order: "desc",
  },
};

export const donations: Reducer<DonationsState, DonationsActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actions.SET_SEARCH_CONDITION: {
      const searchCondition = (action as SetSearchConditionAction).payload
        .searchCondition;
      return {
        ...state,
        searchCondition: {
          ...state.searchCondition,
          ...searchCondition,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      };
    }
    case actions.SET_PAGINATION: {
      const pagination = (action as SetPaginationAction).payload.pagination;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    }
    case actions.SET_SORT: {
      const sort = (action as SetSortAction).payload.sort;
      return {
        ...state,
        sort: {
          ...state.sort,
          ...sort,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      };
    }
    default:
      return state;
  }
};
