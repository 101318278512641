import { Payload } from "interfaces/Action";
import { Action } from "redux";
import ActionTypes from "./PersonalIdentificationConstants";
import { PersonalIdentificationState } from "./PersonalIdentificationReducer";

interface ChildSubmitPayload {
  resetPasswordToken: string;
  birthDate: string;
  userType: string | null;
}
export interface ChildSubmitAction
  extends Action<ActionTypes.CHILD_SUBMIT>,
    Payload<ChildSubmitPayload> {}
interface OrphanageSubmitPayload {
  resetPasswordToken: string;
  phoneNumber: string;
  userType: string | null;
}
export interface OrphanageSubmitAction
  extends Action<ActionTypes.ORPHANAGE_SUBMIT>,
    Payload<OrphanageSubmitPayload> {}
export interface SetMessageAction
  extends Action<ActionTypes.SET_MESSAGE>,
    Payload<Pick<PersonalIdentificationState, "message">> {}

export type Actions =
  | SetMessageAction
  | ChildSubmitAction
  | OrphanageSubmitAction;

export default {
  setMessage: (payload: SetMessageAction["payload"]): SetMessageAction => ({
    type: ActionTypes.SET_MESSAGE,
    payload,
  }),
  childSubmit: (payload: ChildSubmitAction["payload"]): ChildSubmitAction => ({
    type: ActionTypes.CHILD_SUBMIT,
    payload,
  }),
  orphanageSubmit: (
    payload: OrphanageSubmitAction["payload"]
  ): OrphanageSubmitAction => ({ type: ActionTypes.ORPHANAGE_SUBMIT, payload }),
};
