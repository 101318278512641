import { ChildUser } from "modules/db/ChildUser/ChildUserType";

export type ChildEditorState = Omit<
  ChildUser,
  | "id"
  | "orphanage_id"
  | "password"
  | "account_notified_at"
  | "created_at"
  | "updated_at"
  | "orphanage"
>;

export const initialChildEditorState = (): ChildEditorState => ({
  first_name: "",
  last_name: "",
  first_kana_name: "",
  last_kana_name: "",
  birthdate: null,
  postal_code: "",
  prefecture: "東京都",
  city: "",
  address1: "",
  address2: "",
  receiver_place_name: "",
  available_point: 0,
  email: "",
  tel_number: "",
  fledged_year: "",
  tags: [""],
});
