import React from "react";

import { Message } from "semantic-ui-react";

import styled from "styled-components";

const MessageBox = styled(Message)`
  &&& {
    width: max-content;
    max-width: 100%;
    margin: auto;
  }
`;

type ErrorProps = {
  message: string;
  severity?: "success" | "info" | "warning" | "error" | undefined;
};

const InfoMessage: React.FC<ErrorProps> = ({ message, severity }) => {
  return (
    <MessageBox
      success={severity === "success"}
      info={severity === "info"}
      warning={severity === "warning"}
      error={severity === "error"}
    >
      {" "}
      {message}
    </MessageBox>
  );
};

export default InfoMessage;
