import { State } from "../../../interfaces/State";
import {
  GetDonationsOptions,
  Pagination,
  Sort,
} from "../../../modules/db/Donations/DonationsTypes";
import { donationsActionsCreators } from "../../../modules/db/Donations/DonationsActions";
import { put, select, takeLatest } from "redux-saga/effects";
import actions from "./DonationsConstants";

const donationsSaga = function* () {
  yield takeLatest(actions.SET_SEARCH_CONDITION, searchDonationsSaga);
  yield takeLatest(actions.SET_PAGINATION, searchDonationsSaga);
  yield takeLatest(actions.SET_SORT, searchDonationsSaga);
};

export const searchDonationsSaga = function* () {
  const searchConditions: GetDonationsOptions = yield select(
    (state: State) => state.donationsPage.searchCondition
  );
  const pagination: Pagination = yield select(
    (state: State) => state.donationsPage.pagination
  );
  const sort: Sort = yield select((state: State) => state.donationsPage.sort);
  yield put(
    donationsActionsCreators.getDonations(searchConditions, pagination, sort)
  );
};

export default donationsSaga;
