import { TransactionPatchState } from "api/v1/Transactions";
import Annotation from "components/atoms/Annotation";
import DesignedButton from "components/atoms/DesignedButton";
import SelectBox from "components/atoms/SelectBox";
import TextBox from "components/atoms/TextBox";
import { Formik } from "formik";
import React, { ComponentProps, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Form, Label } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { size } from "styles/Size";
import prefectures from "utils/prefectures";
import Validate from "utils/Validate";
import * as Yup from "yup";
import { TransactionEditParams } from "..";
import transactionEditActionCreator from "../TransactionEditAction";
import { TransactionEditState } from "../TransactionEditReducer";
import { ItemText } from "components/atoms/ItemText";
import { ItemLabel } from "components/atoms/ItemLabel";

type DestinationInfoProps = TransactionEditState;

type FormValues = TransactionPatchState;

const destinationEditorScheme = Yup.object().shape<Partial<FormValues>>({
  postal_code: Validate.postal_code,
  city: Validate.city,
  address1: Validate.address1,
  receiver_tel_number: Validate.tel_number,
});

const SubHeader = styled.h2`
  font-size: 1.2rem;
`;

export const DestinationInfo: React.FC<DestinationInfoProps> = ({
  transaction,
  loading,
}) => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators(transactionEditActionCreator, dispatch),
    [dispatch]
  );
  const { id } = useParams<TransactionEditParams>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const initialFormValues: FormValues = {
    postal_code: transaction?.postal_code || "",
    prefecture: transaction?.prefecture || "東京都",
    city: transaction?.city || "",
    address1: transaction?.address1 || "",
    address2: transaction?.address2 || "",
    receiver_place_name: transaction?.receiver_place_name || "",
    receiver_tel_number: transaction?.receiver_tel_number || "",
    delivery_date: transaction?.delivery_date || "",
  };

  const onSubmit = (values: FormValues) => {
    if (showConfirm) {
      actions.submit({ id, values });
    } else {
      setShowConfirm(true);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [showConfirm]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFormValues}
      validationSchema={destinationEditorScheme}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        handleSubmit,
        touched,
      }) => {
        return (
          <Form>
            <FluidGrid id="DestinationInfoGrid">
              <Row>
                <DefaultColumn>
                  <SubHeader id="DestinationInfoSubHeader">
                    送付先情報
                  </SubHeader>
                </DefaultColumn>
              </Row>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <ItemLabel id="ReceiverNameLabel">受取人指名</ItemLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow>
                <DefaultColumn>
                  <ItemText id="ReceiverName">
                    {transaction?.receiver_last_name +
                      "  " +
                      transaction?.receiver_first_name}
                  </ItemText>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <ItemLabel id="ReceiverNameKanaLabel">
                    受取人指名（かな）
                  </ItemLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow>
                <DefaultColumn>
                  <ItemText id="ReceiverNameKana">
                    {transaction?.receiver_last_kana_name +
                      "  " +
                      transaction?.receiver_first_kana_name}
                  </ItemText>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel id="PostalCodeLabel" htmlFor="postal_code">
                    郵便番号
                  </FormLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow
                paddingtop={0}
                paddingbottom={showConfirm ? undefined : 0}
              >
                <DefaultColumn>
                  <CommonTextBox
                    id="postal_code"
                    name="postal_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postal_code}
                    disabled={loading}
                    error={
                      touched.postal_code && errors.postal_code
                        ? errors.postal_code
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"1008228"}
                  />
                </DefaultColumn>
              </PaddingRow>
              {!showConfirm && (
                <PaddingRow paddingtop={0} columns="equal">
                  <DefaultColumn textAlign="left">
                    <Annotation>※ハイフンなしで入力してください</Annotation>
                  </DefaultColumn>
                </PaddingRow>
              )}
              <PaddingRow paddingbottom={0}>
                <DefaultColumn width={16}>
                  <FormLabel htmlFor="prefecture">都道府県</FormLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingtop={0} columns="equal">
                <DefaultColumn>
                  {showConfirm ? (
                    <ItemText id="prefecture">{values.prefecture}</ItemText>
                  ) : (
                    <SelectBox
                      error={
                        touched.prefecture && errors.prefecture
                          ? errors.prefecture
                          : undefined
                      }
                      id="prefecture"
                      name="prefecture"
                      value={values.prefecture || "東京都"}
                      disabled={loading}
                      onBlur={handleBlur}
                      options={prefectures.map((item) => ({
                        ...item,
                        id: item.key,
                        onClick: (e) => {
                          handleChange({
                            ...e,
                            target: {
                              ...e.target,
                              id: "prefecture",
                              name: "prefecture",
                              value: item.value,
                            },
                          });
                        },
                      }))}
                      selection
                    />
                  )}
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel id="CityLabel" htmlFor="city">
                    市区町村
                  </FormLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingtop={0}>
                <DefaultColumn>
                  <CommonTextBox
                    id="city"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    disabled={loading}
                    error={
                      touched.city && errors.city ? errors.city : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"千代田区大手町"}
                  />
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel id="Address1Label" htmlFor="address1">
                    番地
                  </FormLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingtop={0}>
                <DefaultColumn>
                  <CommonTextBox
                    id="address1"
                    name="address1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address1}
                    disabled={loading}
                    error={
                      touched.address1 && errors.address1
                        ? errors.address1
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"2-6-2"}
                  />
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel id="Address2Label" htmlFor="address2">
                    建物名/部屋番号
                  </FormLabel>
                  <Label
                    color="green"
                    size="mini"
                    style={{ marginLeft: "10px" }}
                  >
                    任意
                  </Label>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingtop={0}>
                <DefaultColumn>
                  <CommonTextBox
                    id="address2"
                    name="address2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address2}
                    disabled={loading}
                    error={
                      touched.address2 && errors.address2
                        ? errors.address2
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"ステージグランデ205号室"}
                  />
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel
                    id="ReceiverPlaceNameLabel"
                    htmlFor="receiver_place_name"
                  >
                    お届け先の場所の名前
                  </FormLabel>
                  <Label
                    color="green"
                    size="mini"
                    style={{ marginLeft: "10px" }}
                  >
                    任意
                  </Label>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingtop={0}>
                <DefaultColumn>
                  <CommonTextBox
                    id="receiver_place_name"
                    name="receiver_place_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.receiver_place_name}
                    disabled={loading}
                    error={
                      touched.receiver_place_name && errors.receiver_place_name
                        ? errors.receiver_place_name
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"引越し先の自宅・新しい施設"}
                  />
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel
                    id="ReceiverTelNumber"
                    htmlFor="receiver_tel_number"
                  >
                    電話番号
                  </FormLabel>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow
                paddingtop={0}
                paddingbottom={showConfirm ? undefined : 0}
              >
                <DefaultColumn>
                  <CommonTextBox
                    id="receiver_tel_number"
                    name="receiver_tel_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.receiver_tel_number}
                    disabled={loading}
                    error={
                      touched.receiver_tel_number && errors.receiver_tel_number
                        ? errors.receiver_tel_number
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"0368426766"}
                  />
                </DefaultColumn>
              </PaddingRow>
              {!showConfirm && (
                <PaddingRow paddingtop={0} columns="equal">
                  <DefaultColumn textAlign="left">
                    <Annotation>
                      ※ハイフンなしで入力してください
                      <br />
                    </Annotation>
                    <Annotation>
                      ※個人の電話番号がない場合は、施設の電話番号を入力してください
                    </Annotation>
                  </DefaultColumn>
                </PaddingRow>
              )}
              <PaddingRow paddingbottom={0}>
                <DefaultColumn>
                  <FormLabel id="delivery_date" htmlFor="delivery_date">
                    配送希望日時
                  </FormLabel>
                  <Label
                    color="green"
                    size="mini"
                    style={{ marginLeft: "10px" }}
                  >
                    任意
                  </Label>
                </DefaultColumn>
              </PaddingRow>
              <PaddingRow
                paddingtop={0}
                paddingbottom={showConfirm ? undefined : 0}
              >
                <DefaultColumn>
                  <CommonTextBox
                    id="delivery_date"
                    name="delivery_date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.delivery_date}
                    disabled={loading}
                    error={
                      touched.delivery_date && errors.delivery_date
                        ? errors.delivery_date
                        : undefined
                    }
                    confirm={showConfirm}
                    placeholder={"例）土日の午前中"}
                  />
                </DefaultColumn>
              </PaddingRow>
              {!showConfirm && (
                <PaddingRow paddingtop={0} columns="equal">
                  <DefaultColumn textAlign="left">
                    <Annotation>
                      ※2週間以上先の希望日や希望時間帯を入力してください
                    </Annotation>
                  </DefaultColumn>
                </PaddingRow>
              )}
              <PaddingRow paddingtop={40} columns="equal">
                {showConfirm ? (
                  <>
                    <DefaultColumn textAlign="center">
                      <ResizeableButton
                        key="cancel"
                        onClick={() => {
                          setShowConfirm(false);
                        }}
                        inverted
                        loading={loading}
                        size={{ minHeight: "45px" }}
                      >
                        <ButtonText>修正する</ButtonText>
                      </ResizeableButton>
                    </DefaultColumn>
                    <DefaultColumn textAlign="center">
                      <ResizeableButton
                        key="application"
                        type="submit"
                        onClick={handleSubmit}
                        loading={loading}
                        size={{ minHeight: "45px" }}
                      >
                        <ButtonText>申し込む</ButtonText>
                      </ResizeableButton>
                    </DefaultColumn>
                  </>
                ) : (
                  <DefaultColumn width={16} textAlign="center">
                    <ResizeableButton
                      key="confirm"
                      type="submit"
                      paddingtop={20}
                      paddingbottom={20}
                      size={{ width: "100%", maxWidth: "255px" }}
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      <ButtonText>確認する</ButtonText>
                    </ResizeableButton>
                  </DefaultColumn>
                )}
              </PaddingRow>
            </FluidGrid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DestinationInfo;

const { Row, PaddingColumn, PaddingRow } = DefaultGrid;
const DefaultColumn: typeof PaddingColumn = (props) => (
  <PaddingColumn padding={0} {...props} />
);
const ResizeableGrid = size(padding(DefaultGrid));
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
const FormLabel = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
`;
const ButtonText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
const ResizeableButton = size(DesignedButton);

interface CommonTextBoxProps extends ComponentProps<typeof TextBox> {
  confirm: boolean;
}
const CommonTextBox: React.FC<CommonTextBoxProps> = ({ confirm, ...props }) => {
  return confirm ? (
    <ItemText id={props.id?.toString()}>{props.value || "なし"}</ItemText>
  ) : (
    <TextBox {...props} />
  );
};
