import React, { ComponentProps, useEffect, useMemo } from "react";

import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { Profile } from "./profileTypes";
import ProfileCreators from "./profileActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";

import TextBox from "components/atoms/TextBox";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";

import DefaultGrid from "styles/DefaultGrid";
import MainHeader from "components/atoms/MainHeader";
import LinkButton from "components/atoms/LinkButton";
import ROUTES from "utils/routes";
import Validate from "utils/Validate";

const StyledLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
`;

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

interface RouterProps {
  id: string;
}

const ProfileScheme = Yup.object().shape({
  first_name: Validate.user_name,
  last_name: Validate.user_name,
  first_kana_name: Validate.user_kana_name,
  last_kana_name: Validate.user_kana_name,
  tel_number: Validate.tel_number,
});

const UserEditor: React.FC = () => {
  const { error: addressError } = useSelector((state: State) => state.address);
  const {
    profile: profileData,
    message,
    error: profileError,
  } = useSelector((state: State) => state.profile);

  const initialFormValues: Profile = {
    email: profileData.email || "",
    id: profileData.id || "",
    first_kana_name: profileData.first_kana_name || "",
    last_kana_name: profileData.last_kana_name || "",
    first_name: profileData.first_name || "",
    last_name: profileData.last_name || "",
    tel_number: profileData.tel_number || "",
  };

  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      profile: bindActionCreators(ProfileCreators, dispatch),
    };
  }, [dispatch]);

  useEffect(() => {
    actions.profile.getProfile();
  }, [actions.profile]);

  const handleSubmit = async (values: Profile) => {
    actions.profile.updateProfile(values);
  };

  return (
    <DefaultGrid id="UserEditor">
      <Row id="HeaderRow">
        <Column width={16} style={{ paddingBottom: 16 }}>
          <MainHeader>担当者情報編集</MainHeader>
        </Column>
      </Row>

      {message && (
        <DefaultGrid.Row centered>
          <DefaultGrid.Column textAlign={"center"}>
            <InfoMessage severity="success" message={message as string} />
          </DefaultGrid.Column>
        </DefaultGrid.Row>
      )}

      {(addressError || profileError) && (
        <DefaultGrid.Row centered>
          <DefaultGrid.Column textAlign={"center"}>
            <InfoMessage
              severity="error"
              message={
                addressError
                  ? (addressError.message as string)
                  : (profileError?.message as string)
              }
            />
          </DefaultGrid.Column>
        </DefaultGrid.Row>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={ProfileScheme}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          handleSubmit,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <PaddingRow id="InputRow" padding={0}>
              <DefaultGrid>
                <LabelRow>
                  <LabelColumn width={16}>
                    <StyledLabel htmlFor="last_name">担当者氏名</StyledLabel>
                  </LabelColumn>
                </LabelRow>
                <PaddingRow paddingtop={0} columns="equal">
                  <PaddingColumn paddingright={0}>
                    <TextBox
                      error={
                        touched.last_name &&
                        errors.last_name && { content: errors.last_name }
                      }
                      id="last_name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </PaddingColumn>
                  <Column>
                    <TextBox
                      error={
                        touched.first_name &&
                        errors.first_name && { content: errors.first_name }
                      }
                      id="first_name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Column>
                </PaddingRow>
                <LabelRow>
                  <LabelColumn width={16}>
                    <StyledLabel htmlFor="last_kana_name">
                      担当者氏名（かな）
                    </StyledLabel>
                  </LabelColumn>
                </LabelRow>
                <PaddingRow paddingtop={0} columns="equal">
                  <PaddingColumn paddingright={0}>
                    <TextBox
                      error={
                        touched.last_kana_name &&
                        errors.last_kana_name && {
                          content: errors.last_kana_name,
                        }
                      }
                      id="last_kana_name"
                      name="last_kana_name"
                      value={values.last_kana_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </PaddingColumn>
                  <Column>
                    <TextBox
                      error={
                        touched.first_kana_name &&
                        errors.first_kana_name && {
                          content: errors.first_kana_name,
                        }
                      }
                      id="first_kana_name"
                      name="first_kana_name"
                      value={values.first_kana_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fluid={true}
                    />
                  </Column>
                </PaddingRow>
              </DefaultGrid>
            </PaddingRow>
            <Row id="ControlsRow" style={{ paddingTop: 32, paddingBottom: 32 }}>
              <Column width={16}>
                <DefaultGrid>
                  <PaddingRow padding={0} centered>
                    <FitColumn
                      id="CancelColumn"
                      padding={0}
                      paddingright="0.5rem"
                    >
                      <LinkButton
                        to={ROUTES.SETTINGS}
                        design="primary"
                        inverted
                      >
                        キャンセル
                      </LinkButton>
                    </FitColumn>
                    <FitColumn
                      id="EntryColumn"
                      padding={0}
                      paddingleft="0.5rem"
                    >
                      <DesignedButton type="submit" onClick={handleSubmit}>
                        保存する
                      </DesignedButton>
                    </FitColumn>
                  </PaddingRow>
                </DefaultGrid>
              </Column>
            </Row>
          </Form>
        )}
      </Formik>
    </DefaultGrid>
  );
};

export default UserEditor;

const LabelColumn = styled(Column)`
  text-align: left;
`;

const FitColumn = styled(PaddingColumn)`
  &&& {
    width: fit-content !important;
  }
`;
