import {
  ApplicationChildState,
  DonationApplicationInputState,
} from "./DonationApplicationTypes";

export const donationApplicationInitialState = (
  initial?: DonationApplicationInputState
): DonationApplicationInputState => {
  return Object.assign(
    {
      name: "",
      kana: "",
      child_user_id: "",
      first_name: "",
      last_name: "",
      first_kana_name: "",
      last_kana_name: "",
      postal_code: "",
      prefecture: "",
      city: "",
      address1: "",
      address2: "",
      receiver_place_name: "",
      tel_number: "",
      free_comment: "",
      sameAddress: false,
      sameApplicant: false,
      pending: false,
    },
    initial
  );
};

type ApplicationChildLabelMap = {
  [K in keyof ApplicationChildState]: string;
};

export const DONATION_APPLICATION_CHILD_LABEL_MAP: ApplicationChildLabelMap = {
  sameAddress: "登録された住所に届ける",
  pending: "あとで登録する",
  sameApplicant: "施設に届ける",
  child_user_id: "",
  name: "受取人氏名",
  kana: "受取人氏名（かな）",
  first_name: "",
  last_name: "",
  first_kana_name: "",
  last_kana_name: "",
  postal_code: "郵便番号",
  prefecture: "都道府県",
  city: "市区町村",
  address1: "番地",
  address2: "建物名／部屋番号",
  receiver_place_name: "お届け先の住所名",
  tel_number: "電話番号",
  free_comment: "コメント",
};

enum ActionTypes {
  CONFIRMATION = "b4s/DonationApplication/CONFIRMATION",
}

export const INITIAL_FORM_VALUES = {
  name: "",
  kana: "",
  child_user_id: "",
  first_name: "",
  last_name: "",
  first_kana_name: "",
  last_kana_name: "",
  postal_code: "",
  prefecture: "",
  city: "",
  address1: "",
  address2: "",
  receiver_place_name: "",
  tel_number: "",
  free_comment: "",
  sameAddress: false,
  sameApplicant: false,
  pending: false,
};

export default ActionTypes;
