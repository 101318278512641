import * as Yup from "yup";
import ERROR_MESSAGES from "./errorMessages";
import REGEX_STRINGS from "./regexStrings";

export default {
  email: Yup.string()
    .email(ERROR_MESSAGES.VALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED),
  receiver_user_name: Yup.string().required(
    ERROR_MESSAGES.CHOOSE_RECIEVER_ERROR
  ),
  receiver_user_kana: Yup.string().required(
    ERROR_MESSAGES.CHOOSE_RECIEVER_ERROR
  ),
  password: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .required(ERROR_MESSAGES.REQUIRED),
  user_name: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .required(ERROR_MESSAGES.REQUIRED),
  user_kana_name: Yup.string()
    .matches(
      REGEX_STRINGS.HIRAGANA_SPECIAL_CHARS_NOT_ALLOWED,
      ERROR_MESSAGES.ONLY_HIRAGANA_ERROR
    )
    .required(ERROR_MESSAGES.REQUIRED),
  postal_code: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .length(7, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .required(ERROR_MESSAGES.REQUIRED),
  prefecture: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .required(ERROR_MESSAGES.REQUIRED),
  city: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .required(ERROR_MESSAGES.REQUIRED),
  address1: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  tel_number: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .min(10, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .max(11, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  birthdate: Yup.string().test(
    "birthdate",
    ERROR_MESSAGES.INVALID_DATE_ERROR,
    (value: string) => !isNaN(new Date(value).getTime())
  ),
  free_comment: Yup.string().max(255, ERROR_MESSAGES.FREE_COMMENT_MAX_LENGTH),
  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      ERROR_MESSAGES.CONFIRMATION_PASSWORD_MUST_MATCH
    )
    .required(ERROR_MESSAGES.REQUIRED),
  email_confirmation: Yup.string()
    .email(ERROR_MESSAGES.VALID_EMAIL)
    .oneOf(
      [Yup.ref("email"), null],
      ERROR_MESSAGES.CONFIRMATION_EMAIL_MUST_MATCH
    )
    .required(ERROR_MESSAGES.REQUIRED),
  birthday_year: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  birthday_month: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  birthday_date: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  privacy_policy: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  acceptTerms: Yup.bool().oneOf([true], ERROR_MESSAGES.REQUIRED),
};
