import DesignedButton from "components/atoms/DesignedButton";
import Todokun from "images/todokun_charactor.png";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router";
import { bindActionCreators } from "redux";
import {
  Card,
  CardContent,
  Dropdown,
  Image,
  Menu,
  Modal,
} from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { isString } from "util";
import { DateFormatter, navigateActionsCreators } from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";

type ChildrenCardProps = ChildUser;

const ChildrenCard: React.FC<ChildrenCardProps> = (props) => {
  const {
    id,
    last_name,
    first_name,
    last_kana_name,
    first_kana_name,
    birthdate,
    account_notified_at,
    available_point,
  } = props;
  const actions = {
    navigate: bindActionCreators(navigateActionsCreators, useDispatch()),
  };
  return (
    <Card key={id} fluid>
      <CardContent>
        <BorderlessMenu floated="right" icon>
          <Dropdown item icon="ellipsis horizontal">
            <FittedDropDownMenu>
              <Dropdown.Item
                onClick={() => {
                  actions.navigate.push(
                    generatePath(ROUTES.EDIT_CHILD, { id })
                  );
                }}
              >
                編集する
              </Dropdown.Item>
              <ModalDropDownItem {...props} />
            </FittedDropDownMenu>
          </Dropdown>
        </BorderlessMenu>
        <DefaultGrid>
          <DefaultGrid.Row>
            <TitleColumn paddingleft={0} paddingright={0}>
              氏名
            </TitleColumn>
            <StyledColumn paddingleft={0} paddingright={0}>
              {last_name + first_name}
            </StyledColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row>
            <TitleColumn paddingleft={0} paddingright={0}>
              氏名（かな）
            </TitleColumn>
            <StyledColumn paddingleft={0} paddingright={0}>
              {(last_kana_name ? last_kana_name : "") +
                (first_kana_name ? first_kana_name : "")}
            </StyledColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row>
            <TitleColumn paddingleft={0} paddingright={0}>
              生年月日
            </TitleColumn>
            <StyledColumn paddingleft={0} paddingright={0}>
              {DateFormatter(birthdate)}
            </StyledColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row>
            <TitleColumn paddingleft={0} paddingright={0}>
              ポイント
            </TitleColumn>
            <StyledColumn paddingleft={0} paddingright={0}>
              {available_point + "pt"}
            </StyledColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row>
            <TitleColumn paddingleft={0} paddingright={0}>
              アカウント
            </TitleColumn>
            <StyledColumn paddingleft={0} paddingright={0}>
              {account_notified_at ? "通知済み" : "未通知"}
            </StyledColumn>
          </DefaultGrid.Row>
        </DefaultGrid>
      </CardContent>
    </Card>
  );
};

export default ChildrenCard;

type ModalDropDownItemProps = ChildUser;
const ModalDropDownItem: React.FC<ModalDropDownItemProps> = ({
  last_name,
  first_name,
  email,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const actions = bindActionCreators(childrenListActionsCreators, dispatch);
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <StyledModal
      trigger={
        <Dropdown.Item onClick={() => setDialogOpen(true)}>
          アカウント通知
        </Dropdown.Item>
      }
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <Modal.Content>
        <DefaultGrid>
          <DefaultGrid.Row id="ModalHeaderRow" centered>
            <DefaultGrid.PaddingColumn
              padding={0}
              width={16}
              textAlign="center"
            >
              <ModalHeader id="ModalHeader">
                {last_name}
                {first_name}さん
                {email ? (
                  <>
                    (<ModalText style={{ color: "red" }}>{email}</ModalText>)
                  </>
                ) : (
                  ""
                )}
                <br />
                にアカウント通知メールを送信します。
                <br />
                正しいメールアドレスが入力されているか確認してください。メールを送りますか？
              </ModalHeader>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalImageRow">
            <DefaultGrid.Column textAlign="center">
              <CenteredImage id="ModalImage" alt="トドクン" src={Todokun} />
            </DefaultGrid.Column>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalTextRow">
            <DefaultGrid.PaddingColumn
              padding={0}
              width={16}
              textAlign="center"
            >
              <ModalText id="ModalText">
                通知することで寄付品の申し込みや申込履歴の確認、アカウント情報編集をすることができます。
              </ModalText>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalControlsRow">
            <DefaultGrid.PaddingColumn
              padding={0}
              width={16}
              textAlign="center"
            >
              <DefaultGrid id="ModalControlsContainer">
                <DefaultGrid.PaddingRow padding={0} columns="equal">
                  <DefaultGrid.PaddingColumn id="CancelButtonContainer">
                    <DesignedButton
                      disabled={loading}
                      loading={loading}
                      inverted
                      paddingleft="0.5rem"
                      paddingright="0.5rem"
                      onClick={() => setDialogOpen(false)}
                    >
                      キャンセル
                    </DesignedButton>
                  </DefaultGrid.PaddingColumn>
                  <DefaultGrid.PaddingColumn id="SubmitButtonContainer">
                    <DesignedButton
                      disabled={loading}
                      loading={loading}
                      paddingleft="0.5rem"
                      paddingright="0.5rem"
                    >
                      <ModalText
                        onClick={() => {
                          setLoading(true);
                          actions.sendResetPasswordEmail(email);
                        }}
                      >
                        通知メール送信
                      </ModalText>
                    </DesignedButton>
                  </DefaultGrid.PaddingColumn>
                </DefaultGrid.PaddingRow>
              </DefaultGrid>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
        </DefaultGrid>
      </Modal.Content>
    </StyledModal>
  );
};

const TitleColumn = styled(DefaultGrid.PaddingColumn)`
  &&& {
    width: 100px !important;
    min-width: 0px;
  }
`;
const StyledColumn = styled(DefaultGrid.PaddingColumn)`
  &&& {
    width: ${(props) =>
      isString(props.children)
        ? props.children.length + "rem !important;"
        : undefined};
  }
`;

const StyledModal = styled(Modal)`
  &&& {
    width: 315px;
  }
`;

const ModalHeader = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ModalText = styled.span`
  font-size: 12px;
  font-weight: 700;
`;

const BorderlessMenu = styled(Menu)`
  &&& {
    border: 0px;
    box-shadow: none;
  }
`;

const FittedDropDownMenu = styled(Dropdown.Menu)`
  &&& {
    left: unset !important;
    top: 60% !important;
    right: 0px !important;
  }
`;

const CenteredImage = styled(Image)`
  margin: 0 auto;
`;
