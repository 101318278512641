/* eslint-disable no-irregular-whitespace */
import React, { useMemo, useState, useEffect } from "react";

import {
  Checkbox,
  Form,
  Segment,
  Dropdown,
  Modal,
  Image,
} from "semantic-ui-react";

import { Formik } from "formik";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";
import { LogInState } from "containers/App/GlobalTypes";

import DefaultGrid from "styles/DefaultGrid";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";
import TextArea from "components/atoms/TextArea";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";

import { DeleteAccount } from "./DeleteAccountTypes";
import { deleteAccountActionsCreators } from "./DeleteAccountActions";
import { withHeaderAndFooter } from "components/organisms/Header";
import { BREAKPOINTS } from "utils/ComponentUtils";
import padding from "styles/Padding";
import MainHeader from "components/atoms/MainHeader";
import LinkButton from "components/atoms/LinkButton";
import ROUTES from "utils/routes";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import Todokun from "images/todokun_charactor.png";

const PaddingGrid = padding(DefaultGrid);

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 600px !important;
`;
const SegmentTitle = styled.h4`
  text-align: center;
`;
const SegmentContent = styled.p`
  font-size: 0.9rem;
`;

const ResetHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 1.1rem;
    }
  }
`;

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const FitColumn = styled(PaddingColumn)`
  &&& {
    width: fit-content !important;
  }
`;

const CheckForm = styled(Checkbox)`
  &&& {
    font-size: 0.9rem;
    margin: auto;
  }
`;

interface RouterProps {
  id: string;
}

const MyPage: React.FC<RouteComponentProps<RouterProps>> = (props) => {
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);
  const { error } = useSelector((state: State) => state.deleteAccount);
  const [checked, setChecked] = useState(true);
  const initialFormValues: DeleteAccount = {
    comment: "",
  };

  const handleSubmit = async () => {};
  const changeChecked = () => {
    setChecked(!checked);
  };

  return (
    <PaddingGrid id="DeleteAccount" paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>
      <ContentsRow id="Contents">
        <DefaultGrid.Column id="InputColumn" width={16}>
          <DefaultGrid width={16}>
            <PaddingRow centered>
              <DefaultGrid.Column textAlign={"center"}>
                <ResetHeader>退会</ResetHeader>
              </DefaultGrid.Column>
            </PaddingRow>
          </DefaultGrid>
          <Segment secondary>
            <SegmentTitle>注意事項</SegmentTitle>
            {scope === "child_user" && (
              <>
                <SegmentContent>
                  ・退会申請をすると、5日以内に退会処理が行われ、退会完了のメールが通知されます。
                </SegmentContent>
                <SegmentContent>
                  ・退会申請をすると、保有していたポイントは全て失効します。
                </SegmentContent>
                <SegmentContent>
                  ・退会しても、ブリッジフォースマイルで保有している個人情報（氏名・住所・電話番号・メールアドレス・生年月日など）は削除されません。
                </SegmentContent>
                <SegmentContent>
                  ・個人情報を削除したい場合は、ブリッジフォースマイル（info@b4s.jp）までご連絡ください。
                </SegmentContent>
                <SegmentContent>
                  ・退会しても、ブリッジフォースマイルはあなたをずっと応援しています。困った時、仲間に会いたい時など、いつでも気軽に連絡してください。
                </SegmentContent>
              </>
            )}
            {scope === "orphanage_user" && (
              <>
                <SegmentContent>
                  ・退会申請をすると、5日以内に退会処理が行われ、退会完了のメールが通知されます。
                </SegmentContent>
                <SegmentContent>
                  ・退会しても、ブリッジフォースマイルで保有している施設情報（住所・電話番号・メールアドレス）は削除されません。
                </SegmentContent>
                <SegmentContent>
                  ・子どもを退会させたいなどのご相談は、ブリッジフォースマイル（info@b4s.jp）までご連絡ください。
                </SegmentContent>
              </>
            )}
          </Segment>
          {error && (
            <DefaultGrid.Row centered>
              <DefaultGrid.Column textAlign={"center"}>
                <InfoMessage severity="error" message={error.toString()} />
              </DefaultGrid.Column>
            </DefaultGrid.Row>
          )}

          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              handleSubmit,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column width={6}>
                    <Form.Group widths="equal">
                      <TextArea
                        error={
                          touched.comment &&
                          errors.comment && { content: errors.comment }
                        }
                        id="comment"
                        name="comment"
                        label="退会理由を教えてください"
                        value={values.comment}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ height: 130 }}
                      />
                    </Form.Group>
                  </DefaultGrid.Column>
                </DefaultGrid.Row>

                <DefaultGrid.Row>
                  <DefaultGrid.Column>
                    <Form.Group>
                      <CheckForm
                        id="confirmation"
                        name="confirmation"
                        onChange={changeChecked}
                        label="上記すべてのサービス注意事項を確認しました。"
                      />
                    </Form.Group>
                  </DefaultGrid.Column>
                </DefaultGrid.Row>

                <Row
                  id="ControlsRow"
                  style={{ paddingTop: 32, paddingBottom: 32 }}
                >
                  <Column width={16}>
                    <DefaultGrid>
                      <PaddingRow padding={0} centered>
                        <FitColumn
                          id="CancelColumn"
                          padding={0}
                          paddingright="0.5rem"
                        >
                          <LinkButton
                            to={ROUTES.SETTINGS}
                            design="primary"
                            inverted
                          >
                            キャンセル
                          </LinkButton>
                        </FitColumn>
                        <FitColumn
                          id="EntryColumn"
                          padding={0}
                          paddingleft="0.5rem"
                        >
                          <DesignedButton disabled={checked}>
                            <ModalDropDownItem values={values} />
                          </DesignedButton>
                        </FitColumn>
                      </PaddingRow>
                    </DefaultGrid>
                  </Column>
                </Row>
              </Form>
            )}
          </Formik>
        </DefaultGrid.Column>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withHeaderAndFooter(MyPage);

const StyledModal = styled(Modal)`
  &&& {
    width: 315px;
  }
`;

const ModalHeader = styled.span`
  font-size: 15px;
  font-weight: 500;
`;

const ModalText = styled.span`
  font-size: 12px;
  font-weight: 500;
`;
const ModalButtonText = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const CenteredImage = styled(Image)`
  margin: 0 auto;
`;

type DropdownProps = {
  values: DeleteAccount; //
};
const ModalDropDownItem: React.FC<DropdownProps> = ({ values }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const actions = useMemo(() => {
    return {
      account: bindActionCreators(deleteAccountActionsCreators, dispatch),
    };
  }, [dispatch]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <StyledModal
      trigger={
        <Dropdown.Item onClick={() => setDialogOpen(true)}>
          退会する
        </Dropdown.Item>
      }
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <Modal.Content>
        <DefaultGrid>
          <DefaultGrid.Row id="ModalHeaderRow" centered>
            <DefaultGrid.PaddingColumn
              padding={0}
              width={16}
              textAlign="center"
            >
              <ModalHeader id="ModalHeader">
                本当に退会申請をしても
                <br />
                よろしいですか？
              </ModalHeader>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalImageRow">
            <DefaultGrid.Column textAlign="center">
              <CenteredImage id="ModalImage" alt="トドクン" src={Todokun} />
            </DefaultGrid.Column>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalTextRow">
            <DefaultGrid.PaddingColumn padding={0} width={16} textAlign="left">
              <ModalText id="ModalText">
                ・退会後はアカウントおよび、登録データは
                <br />
                　復旧できませんのでご注意ください。
                <br />
                ・退会申請を送信後、退会完了メールを受信し
                <br />
                　退会完了となります。
              </ModalText>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ModalControlsRow">
            <DefaultGrid.PaddingColumn
              padding={0}
              width={16}
              textAlign="center"
            >
              <DefaultGrid id="ModalControlsContainer">
                <DefaultGrid.PaddingRow padding={0} columns="equal">
                  <DefaultGrid.PaddingColumn id="CancelButtonContainer">
                    <DesignedButton
                      disabled={loading}
                      loading={loading}
                      inverted
                      paddingleft="0.5rem"
                      paddingright="0.5rem"
                      onClick={() => setDialogOpen(false)}
                    >
                      キャンセル
                    </DesignedButton>
                  </DefaultGrid.PaddingColumn>
                  <DefaultGrid.PaddingColumn id="SubmitButtonContainer">
                    <DesignedButton
                      disabled={loading}
                      loading={loading}
                      style={{ fontSize: 14, fontWeight: 700 }}
                    >
                      <ModalButtonText
                        onClick={() => {
                          setLoading(true);
                          actions.account.deleteAccount(values);
                        }}
                      >
                        {" "}
                        退会申請{" "}
                      </ModalButtonText>
                    </DesignedButton>
                  </DefaultGrid.PaddingColumn>
                </DefaultGrid.PaddingRow>
              </DefaultGrid>
            </DefaultGrid.PaddingColumn>
          </DefaultGrid.Row>
        </DefaultGrid>
      </Modal.Content>
    </StyledModal>
  );
};
