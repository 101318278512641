import InfoMessage from "components/atoms/InfoMessage";
import { State } from "interfaces/State";
import React, { useEffect, useMemo, Props } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Tab,
  TabProps,
  Dropdown,
  StrictDropdownItemProps,
} from "semantic-ui-react";
import TransactionBox from "./TransactionBox";
import { transactionsActionsCreators } from "modules/db/Transactions/TransactionsActions";
import { transactionsActionsCreators as actionCreators } from "./TransactionsActions";
import { LogInState } from "containers/App/GlobalTypes";
import { bindActionCreators, Dispatch } from "redux";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import { Transaction } from "interfaces/db/Transaction";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import { GetTransactionsOptions } from "modules/db/Transactions/TransactionsTypes";
import { withHeaderAndFooter } from "components/organisms/Header";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import MainHeader from "components/atoms/MainHeader";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import styled from "styled-components";
import DesignedButtonPager from "components/organisms/DesignedButtonPager";
import { COLORS, BREAKPOINTS } from "utils/ComponentUtils";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const PaneStyled = styled(Tab.Pane)`
  &&&.ui.segment {
    border: 0;
    box-shadow: initial;
    padding: 0 0 20px 0;
  }
`;
const TabStyled = styled(Tab)`
  &&& {
    text-align: center;
    .ui.secondary.menu .active.item {
      background-color: ${COLORS.SECONDARY};
      color: white;
      font-weight: bold;
    }
    .ui.secondary.menu .item {
      font-weight: bold;
    }
  }
`;
const StyledDropdown = styled(Dropdown)`
  &&& {
    width: 50%;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 100%;
    }
  }
`;

const Transactions: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();

  const {
    data: transactionsData,
    loading,
    error,
  } = useSelector((state: State) => state.transactions);
  const transactions = transactionsData.donation_applications;
  const totalPages = transactionsData.total_pages;
  // const transactions_count = transactionsData?.total_num;
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);
  const { pagination, searchCondition } = useSelector(
    (state: State) => state.transactionsPage
  );
  const { children } = useSelector((state: State) => state.childrenList);

  const actions = useMemo(() => {
    return {
      transactions: bindActionCreators(transactionsActionsCreators, dispatch),
      childrenList: bindActionCreators(childrenListActionsCreators, dispatch),
      ...bindActionCreators(actionCreators, dispatch),
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      actions.setSearchCondition({
        child_user_id_eq: undefined,
        status_in: [2, 3],
      })
    );
  }, [dispatch, actions]);

  useEffect(() => {
    let pagination = {
      page: undefined,
      limit: undefined,
    };
    dispatch(actions.childrenList.getChildrenList(pagination));
  }, [dispatch, actions, pagination]);

  const handleChangeTab = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    if (data.activeIndex === 0) {
      dispatch(actionCreators.setSearchCondition({ status_in: [2, 3] }));
    } else if (data.activeIndex === 1) {
      dispatch(actionCreators.setSearchCondition({ status_in: [1] }));
    } else if (data.activeIndex === 2) {
      dispatch(actionCreators.setSearchCondition({ status_in: undefined }));
    }
  };
  const panel = () => (
    <>
      <PaneStyled attached={false} loading={loading}>
        {transactions === undefined || transactions.length === 0
          ? "対象となるデータがありません"
          : transactions.map((transaction: Transaction) => (
              <TransactionBox key={transaction.id} transaction={transaction} />
            ))}
      </PaneStyled>
      {transactions !== undefined && transactions.length !== 0 && (
        <DesignedButtonPager
          activePage={pagination.page}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => {
            if (activePage) {
              dispatch(
                actionCreators.setPagination({
                  ...pagination,
                  page: +activePage,
                })
              );
              window.scroll(0, 0); //表示位置をページ冒頭に戻す
            }
          }}
        />
      )}
    </>
  );

  const panes = [
    {
      menuItem: "受取確認待ち",
      render: panel,
    },
    {
      menuItem: "送付先未確定",
      render: panel,
    },
    {
      menuItem: "すべて",
      render: panel,
    },
  ];

  interface KeepHeightProps {
    height: number;
  }

  const KeepHeight: React.FC<KeepHeightProps> = ({ height }) => {
    return <div style={{ height }} />;
  };

  return (
    <>
      <PaddingGrid id="transaction" paddingleft={16} paddingright={16} centered>
        <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
          <PaddingColumn width={16} paddingleft={0}>
            <CustomBreadcrumbs {...props} />
          </PaddingColumn>
        </PaddingRow>

        <DefaultGrid.Row id="MainContents" centered>
          <DefaultGrid.PaddingColumn
            mobile={16}
            tablet={12}
            computer={8}
            padding={0}
          >
            <DefaultGrid>
              <DefaultGrid.Row id="TitleContainer" columns="equal">
                <DefaultGrid.Column id="TitleColumn">
                  <MainHeader id="Title">申し込み履歴</MainHeader>
                </DefaultGrid.Column>
                <DefaultGrid.Column />
              </DefaultGrid.Row>
              <DefaultGrid.PaddingRow
                id="ControllsContainer"
                paddingleft={0}
                paddingright={0}
              >
                <DefaultGrid.PaddingColumn
                  id="ControllsColumn"
                  width={16}
                  padding={0}
                >
                  {error && (
                    <InfoMessage severity="error" message={error.toString()} />
                  )}

                  {scope === "orphanage_user" && (
                    <StyledDropdown
                      placeholder="子どもを検索する"
                      selection
                      options={childListDropdownItems(
                        dispatch,
                        children,
                        searchCondition
                      )}
                      value={searchCondition?.child_user_id_eq}
                    />
                  )}
                  <KeepHeight height={30} />
                  <TabStyled
                    menu={{ secondary: true }}
                    onTabChange={handleChangeTab}
                    panes={panes}
                  />
                </DefaultGrid.PaddingColumn>
              </DefaultGrid.PaddingRow>
            </DefaultGrid>
          </DefaultGrid.PaddingColumn>
        </DefaultGrid.Row>
      </PaddingGrid>
    </>
  );
};

export default withHeaderAndFooter(Transactions);

export const childListDropdownItems = (
  dispatch: Dispatch,
  children: ChildUser[] = [],
  searchCondition?: GetTransactionsOptions
): Props<StrictDropdownItemProps>[] => {
  return [
    {
      key: "all",
      active: "" === searchCondition?.child_user_id_eq,
      text: "すべての子ども",
      value: "",
      onClick: () => {
        dispatch(actionCreators.setSearchCondition({ child_user_id_eq: "" }));
      },
    },
  ].concat(
    children.map((child) => {
      return {
        key: child.id,
        active: child.id === searchCondition?.child_user_id_eq,
        text: `${child.last_name} ${child.first_name}`,
        value: child.id,
        onClick: () => {
          dispatch(
            actionCreators.setSearchCondition({ child_user_id_eq: child.id })
          );
        },
      };
    })
  );
};
