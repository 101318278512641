import React, { useMemo, useEffect } from "react";

import { Form } from "semantic-ui-react";
import styled from "styled-components";

import { ChangeEmail, AdditionalChangeEmailValues } from "./ChangeEmailTypes";
import ChangeEmailCreators from "./ChangeEmailActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";
import Validate from "utils/Validate";

import DefaultGrid from "styles/DefaultGrid";

import TextBox from "components/atoms/TextBox";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";

import { withHeaderAndFooter } from "components/organisms/Header";
import { RouteComponentProps } from "react-router";
import MainHeader from "components/atoms/MainHeader";
import { BREAKPOINTS } from "utils/ComponentUtils";
import padding from "styles/Padding";
import LinkButton from "components/atoms/LinkButton";
import ROUTES from "utils/routes";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";

const PaddingGrid = padding(DefaultGrid);

const TextBoxStyled = styled(TextBox)`
  &&&.field.disabled > label,
  .ui.disabled.input,
  &&&.disabled {
    opacity: 1;
  }
`;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 600px !important;
`;

const ResetHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 1.3rem;
    }
  }
`;
const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

interface RouterProps {
  id: string;
}

const ProfileScheme = Yup.object().shape({
  email: Validate.email,
  email_confirmation: Validate.email_confirmation,
});

const ChangeEmailPage: React.FC<RouteComponentProps<RouterProps>> = (props) => {
  const { error, message } = useSelector((state: State) => state.changeEmail);

  const initialFormValues: ChangeEmail & AdditionalChangeEmailValues = {
    email: "",
    email_confirmation: "",
  };

  const dispatch = useDispatch();

  const actions = useMemo(() => {
    return {
      email: bindActionCreators(ChangeEmailCreators, dispatch),
    };
  }, [dispatch]);

  const handleSubmit = async (values: ChangeEmail) => {
    actions.email.changeEmail(values);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <PaddingGrid id="ChangeEmail" paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>
      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <PaddingGrid padding={0}>
                <PaddingRow paddingtop={30} centered>
                  <DefaultGrid.PaddingColumn padding={0}>
                    <ResetHeader>
                      新しいメールアドレスを設定してください
                    </ResetHeader>
                  </DefaultGrid.PaddingColumn>
                </PaddingRow>
              </PaddingGrid>
              {message && (
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column textAlign={"center"}>
                    <InfoMessage
                      severity="success"
                      message={"保存しました。"}
                    />
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
              )}

              {error && (
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column textAlign={"center"}>
                    <InfoMessage severity="error" message={error.toString()} />
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
              )}

              <PaddingRow paddingtop={30} centered>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialFormValues}
                  validationSchema={ProfileScheme}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    handleSubmit,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <DefaultGrid.Row centered>
                        <DefaultGrid.Column width={6}>
                          <Form.Group widths="equal">
                            <TextBoxStyled
                              type="text"
                              error={
                                touched.email &&
                                errors.email && { content: errors.email }
                              }
                              id="email"
                              name="email"
                              label="新しいメールアドレス"
                              placeholder="新しいメールアドレス"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fluid={true}
                            />
                          </Form.Group>
                        </DefaultGrid.Column>
                      </DefaultGrid.Row>

                      <DefaultGrid.Row centered>
                        <DefaultGrid.Column width={6}>
                          <Form.Group widths="equal">
                            <TextBoxStyled
                              type="text"
                              error={
                                touched.email_confirmation &&
                                errors.email_confirmation && {
                                  content: errors.email_confirmation,
                                }
                              }
                              id="email_confirmation"
                              name="email_confirmation"
                              label="メールアドレス確認"
                              placeholder="メールアドレス確認"
                              value={values.email_confirmation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fluid={true}
                            />
                          </Form.Group>
                        </DefaultGrid.Column>
                      </DefaultGrid.Row>

                      <Row
                        id="ControlsRow"
                        style={{ paddingTop: 32, paddingBottom: 32 }}
                      >
                        <Column width={16}>
                          <DefaultGrid>
                            <PaddingRow padding={0} centered>
                              <FitColumn
                                id="CancelColumn"
                                padding={0}
                                paddingright="0.5rem"
                              >
                                <LinkButton
                                  to={ROUTES.SETTINGS}
                                  design="primary"
                                  inverted
                                >
                                  キャンセル
                                </LinkButton>
                              </FitColumn>
                              <FitColumn
                                id="EntryColumn"
                                padding={0}
                                paddingleft="0.5rem"
                              >
                                <DesignedButton type="submit">
                                  保存する
                                </DesignedButton>
                              </FitColumn>
                            </PaddingRow>
                          </DefaultGrid>
                        </Column>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </PaddingRow>
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withHeaderAndFooter(ChangeEmailPage);

const FitColumn = styled(PaddingColumn)`
  &&& {
    width: fit-content !important;
  }
`;
