import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, LocationState } from "history";
import { Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "../reducers";

export const history = createBrowserHistory<LocationState>();

export const sagaMiddleware = createSagaMiddleware();

interface MiddlewareConfig {
  env: "production" | "development" | "all";
  middleware: Middleware;
}

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: [routerMiddleware(history), sagaMiddleware],
  devTools: process.env.NODE_ENV === "development",
});
