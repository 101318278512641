import { Reducer } from "redux";
import { OrphanageState, ActionTypes } from "./orphanageTypes";

import actions from "./orphanageConstants";

const profileInitialState: OrphanageState = {
  message: false,
  loading: false,
  orphanage: {
    name: "",
    category: "",
    postal_code: "",
    prefecture: "",
    city: "",
    address1: "",
    address2: "",
    tel_number: "",
  },
  error: undefined,
};

const orphanageReducer: Reducer<OrphanageState, ActionTypes> = (
  state = profileInitialState,
  action
) => {
  switch (action.type) {
    case actions.UPDATE_ORPHANAGE:
    case actions.GET_ORPHANAGE:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ORPHANAGE_SUCCESS:
      return {
        loading: false,
        error: undefined,
        message: false,
        orphanage: action.payload.orphanage,
      };
    case actions.UPDATE_ORPHANAGE_SUCCESS:
      return {
        error: undefined,
        loading: false,
        message: action.payload.message as string,
        orphanage: profileInitialState.orphanage,
      };
    case actions.ORPHANAGE_ERROR:
      return {
        loading: false,
        message: false,
        error: action.payload.error,
        orphanage: profileInitialState.orphanage,
      };
    default:
      return state;
  }
};

export default orphanageReducer;
