import actions from "./orphanageConstants";

import {
  Orphanage,
  GetOrphanageAction,
  UpdateOrphanageAction,
  GetOrphanageSuccessAction,
  UpdateOrphanageSuccessAction,
  OrphanageErrorAction,
} from "./orphanageTypes";

const getOrphanage = (): GetOrphanageAction => {
  return {
    type: actions.GET_ORPHANAGE,
  };
};

const updateOrphanage = (orphanage: Orphanage): UpdateOrphanageAction => {
  return {
    type: actions.UPDATE_ORPHANAGE,
    payload: {
      orphanage,
    },
  };
};

const getOrphanageSuccess = (
  orphanage: Orphanage
): GetOrphanageSuccessAction => {
  return {
    type: actions.GET_ORPHANAGE_SUCCESS,
    payload: {
      orphanage,
    },
  };
};

const updateOrphanageSuccess = (
  message: string
): UpdateOrphanageSuccessAction => {
  return {
    type: actions.UPDATE_ORPHANAGE_SUCCESS,
    payload: {
      message,
    },
  };
};

const OrphanageError = (error: Error): OrphanageErrorAction => {
  return {
    type: actions.ORPHANAGE_ERROR,
    payload: {
      error,
    },
  };
};

const OrphanageCreators = {
  getOrphanage,
  updateOrphanage,
  getOrphanageSuccess,
  updateOrphanageSuccess,
  OrphanageError,
};

export default OrphanageCreators;
