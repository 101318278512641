import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import DesignedButton, { DesignedButtonProps } from "./DesignedButton";

interface Props extends DesignedButtonProps {
  to: NavLinkProps["to"];
}

const LinkButton: React.FC<Props> = ({ to, ...props }) => {
  return (
    <NavLink to={to}>
      <DesignedButton {...props} />
    </NavLink>
  );
};

export default LinkButton;
