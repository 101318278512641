import request, { requestHeadersAuthorized } from "utils/request";

export default {
  verify: async (resetPasswordToken: string, birthdate: string) =>
    request(
      `${process.env.REACT_APP_API_URL}/child_user/password/verify?reset_password_token=${resetPasswordToken}&birthdate=${birthdate}`,
      {
        method: "GET",
        ...requestHeadersAuthorized(resetPasswordToken as string),
      }
    ) as unknown as Promise<void>,
};
