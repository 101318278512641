import { put, takeLatest, select } from "redux-saga/effects";
import Actions from "./TransactionsConstants";
import { State } from "interfaces/State";
import { GetTransactionsOptions } from "modules/db/Transactions/TransactionsTypes";
import { transactionsActionsCreators } from "modules/db/Transactions/TransactionsActions";
import { TransactionPagination } from "./TransactionsTypes";

export const setSearchConditionSaga = function* () {
  const pagination = (yield select(
    (state: State) => state.transactionsPage.pagination
  )) as TransactionPagination;
  const searchCondition = (yield select(
    (state: State) => state.transactionsPage.searchCondition
  )) as GetTransactionsOptions | undefined;
  yield put(
    transactionsActionsCreators.getTransactions(pagination, searchCondition)
  );
};

export default function* transactionsSaga() {
  yield takeLatest(Actions.SET_PAGINATION, setSearchConditionSaga);
  yield takeLatest(Actions.SET_SEARCH_CONDITION, setSearchConditionSaga);
}
