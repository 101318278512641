import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "semantic-ui-less/semantic.less";
import { history } from "store/configureStore";
import App from "./containers/App/App";
import "./index.css";
import { rootSaga } from "./sagas";
import * as serviceWorker from "./serviceWorker";
import { sagaMiddleware, store } from "./store/configureStore";

sagaMiddleware.run(rootSaga);

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
    plugins: [new BugsnagPluginReact(React)],
  });
}

const ErrorBoundary =
  process.env.NODE_ENV === "production"
    ? Bugsnag.getPlugin("react")!.createErrorBoundary(React)
    : React.Fragment;

const render = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

store.subscribe(render);
render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
