import React, { ComponentProps } from "react";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { size } from "styles/Size";
import { DateFormatter, statusMapper } from "utils/ComponentUtils";
import { TransactionDetailState } from "../TransactionDetailReducer";
import { ItemText } from "components/atoms/ItemText";
import { ItemLabel } from "components/atoms/ItemLabel";
import styled from "styled-components";

type TransactionInfoProps = TransactionDetailState;

const SubHeader = styled.h2`
  font-size: 1.2rem;
`;

export const TransactionInfo: React.FC<TransactionInfoProps> = ({
  transaction,
}) => {
  return transaction === undefined ? (
    <></>
  ) : (
    <FluidGrid id="TransactionsInfoGrid">
      <Row>
        <PaddingColumn paddingleft={0}>
          <SubHeader id="TransactionsInfoSubHeader">取引情報</SubHeader>
        </PaddingColumn>
      </Row>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="ApplicationDateLabel">申込日時</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="ApplicationDate">
            {DateFormatter(transaction.created_at, "YYYY/MM/DD hh:mm")}
          </ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="StatusLabel">ステータス</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="Status">{statusMapper(transaction.status)}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="PointLabel">使用ポイント</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="Point">{transaction.donation.point + "pt"}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="RecieverNameLabel">受取人氏名</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="RecieverName">
            {transaction.receiver_last_name +
              "  " +
              transaction.receiver_first_name}
          </ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="RecieverNameKanaLabel">受取人氏名（かな）</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="RecieverNameKana">
            {transaction.receiver_last_kana_name +
              "  " +
              transaction.receiver_first_kana_name}
          </ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="PhoneNumberLabel">電話番号</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="PhoneNumber">
            {transaction.receiver_tel_number
              ? transaction.receiver_tel_number
              : "未設定"}
          </ItemText>
        </PaddingColumn>
      </PaddingRow>
    </FluidGrid>
  );
};

export default TransactionInfo;

const { Row, PaddingColumn, PaddingRow } = DefaultGrid;
const ResizeableGrid = size(padding(DefaultGrid));
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
