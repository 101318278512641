import ActionTypes from "./DonationConfirmationConstants";
import { FixAction } from "./DonationConfirmationTypes";

import { DonationApplicationInputState } from "../Application/DonationApplicationTypes";

export const fix = (
  donation_id: string,
  initialValues: DonationApplicationInputState
): FixAction => {
  return {
    type: ActionTypes.FIX,
    payload: {
      donation_id,
      initialValues,
    },
  };
};

export const donationConfirmationActionCreators = {
  fix,
};
