import ChildUser from "api/v1/ChildUser";
import OrphanageUser from "api/v1/OrphanageUser";
import { push } from "connected-react-router";
import { generatePath } from "react-router";
import { call, put, takeEvery } from "redux-saga/effects";
import ROUTES from "utils/routes";
import personalIdentificationActionCreators, {
  ChildSubmitAction,
  OrphanageSubmitAction,
} from "./PersonalIdentificationActions";
import ActionTypes from "./PersonalIdentificationConstants";

const personalIdentificationSaga = function* () {
  yield takeEvery(ActionTypes.ORPHANAGE_SUBMIT, orphanageSubmitSaga);
  yield takeEvery(ActionTypes.CHILD_SUBMIT, childSubmitSaga);
};
export default personalIdentificationSaga;

const orphanageSubmitSaga = function* ({
  payload: { resetPasswordToken, phoneNumber, userType },
}: OrphanageSubmitAction) {
  try {
    yield call(OrphanageUser.Password.verify, resetPasswordToken, phoneNumber);
    yield put(
      push(
        generatePath(
          `${ROUTES.CHANGE_PASSWORD}?reset_password_token=${resetPasswordToken}&user_type=${userType}`
        )
      )
    );
  } catch (error) {
    yield put(
      personalIdentificationActionCreators.setMessage({
        message: error.message,
      })
    );
  }
};

const childSubmitSaga = function* ({
  payload: { resetPasswordToken, birthDate, userType },
}: ChildSubmitAction) {
  try {
    yield call(ChildUser.Password.verify, resetPasswordToken, birthDate);
    yield put(
      push(
        generatePath(
          `${ROUTES.CHANGE_PASSWORD}?reset_password_token=${resetPasswordToken}&user_type=${userType}`
        )
      )
    );
  } catch (error) {
    yield put(
      personalIdentificationActionCreators.setMessage({
        message: error.message,
      })
    );
  }
};
