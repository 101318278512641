import {
  SetSearchConditionAction,
  SetPaginationAction,
  TransactionPagination,
} from "./TransactionsTypes";
import Actions from "./TransactionsConstants";
import { GetTransactionsOptions } from "modules/db/Transactions/TransactionsTypes";

const setPagination = (
  pagination: TransactionPagination
): SetPaginationAction => {
  return {
    type: Actions.SET_PAGINATION,
    payload: {
      pagination,
    },
  };
};

const setSearchCondition = (
  searchCondition?: GetTransactionsOptions
): SetSearchConditionAction => {
  return {
    type: Actions.SET_SEARCH_CONDITION,
    payload: {
      searchCondition,
    },
  };
};

export const transactionsActionsCreators = {
  setPagination,
  setSearchCondition,
};
