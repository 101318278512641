import { State } from "interfaces/State";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { isArray } from "util";
import ROUTES from "utils/routes";
import { LogInState, Scopes, SCOPE } from "./App/GlobalTypes";
import { useLocation } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  allowedScopes?: Scopes | Scopes[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  allowedScopes = [SCOPE.CHILD, SCOPE.ORPHANAGE],
  ...routeProps
}) => {
  const { loggedIn, scope } = useSelector<State, LogInState>(
    (state) => state.logIn
  );
  const location = useLocation();

  if (loggedIn && isAuthedRole(allowedScopes, scope)) {
    return <Route {...routeProps} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          search: `?redirectURL=${encodeURIComponent(location.pathname)}`,
        }}
      />
    );
  }
};

const isAuthedRole = (allowedRoles: Scopes | Scopes[], role: Scopes): boolean =>
  (isArray(allowedRoles) ? allowedRoles : [allowedRoles]).some(
    (allowedRole) => allowedRole === role
  );

export default PrivateRoute;
