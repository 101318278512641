import React from "react";

import { Form, FormDropdownProps } from "semantic-ui-react";

const SelectBox: React.FC<FormDropdownProps> = (props) => {
  const { options, ...propsMinusOptions } = props;

  return <Form.Dropdown options={options} {...propsMinusOptions} />;
};

export default SelectBox;
