import MainHeader from "components/atoms/MainHeader";
import Todokun from "images/アセット20@2x.png";
import React, { useEffect } from "react";
import { generatePath, NavLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbSectionProps, Image } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { size } from "styles/Size";
import ROUTES from "utils/routes";
import { withHeaderAndFooter } from "components/organisms/Header";

const TransactionEditComplete: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <DefaultGrid id="TransactionsEdit">
      <Row id="BreadcrumbsRow">
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumb icon="left angle" sections={breadcrumbsSections} />
        </PaddingColumn>
      </Row>
      <Row id="MainRow" centered>
        <SizeableGrid
          id="MainGrid"
          centered
          size={{
            maxWidth: 315,
          }}
        >
          <Row id="TitleRow">
            <MainHeader>申し込みが完了しました</MainHeader>
          </Row>
          <Row id="ContentsRow">
            <DefaultGrid centered>
              <Row id="ContentsHeaderRow">
                <TitleTextSpan>
                  お申し込みありがとうございました。
                  <br />
                  またのご利用をお待ちしております。
                </TitleTextSpan>
              </Row>
              <Row id="ContentsBodyRow">
                <CenteredImg alt="トドクン" src={Todokun} />
              </Row>
              <Row id="ContentsBodyRow">
                <BodyTextSpan>
                  登録されたアドレスにお申し込み完了の
                  <br />
                  メールをお送りしますのでご確認ください。
                  <br />
                  申込の履歴は<NavLink to={ROUTES.TRANSACTIONS}>こちら</NavLink>
                  よりご確認ください
                </BodyTextSpan>
              </Row>
            </DefaultGrid>
          </Row>
        </SizeableGrid>
      </Row>
    </DefaultGrid>
  );
};

const SizeableGrid = size(DefaultGrid);

const TitleTextSpan = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

const BodyTextSpan = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

const CenteredImg = styled(Image)`
  margin: 0px auto;
  width: 150px;
`;
export default withHeaderAndFooter(TransactionEditComplete);

const { Row, PaddingColumn } = DefaultGrid;

const breadcrumbsSections: BreadcrumbSectionProps[] = [
  {
    key: "dummy",
  },
  {
    key: "BackToTransactions",
    content: "申し込み履歴へ戻る",
    as: NavLink,
    active: true,
    to: generatePath(ROUTES.TRANSACTIONS),
  },
];
