import { CheckBox } from "components/atoms/CheckBox";
import { withHeaderAndFooter } from "components/organisms/Header";
import { State } from "interfaces/State";
import { DonationState } from "modules/db/Donation/DonationType";
import React, { useMemo, ComponentProps, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { generatePath } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Header, Form } from "semantic-ui-react";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { navigateActionsCreators } from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import { DONATION_APPLICATION_CHILD_LABEL_MAP } from "../Application/DonationApplicationConstants";
import { DonationApplicationState } from "../Application/DonationApplicationTypes";
import { TransactionsState } from "modules/db/Transactions/TransactionsTypes";
import DonationInfo from "../parts/DonationInfo/DonationInfo";
import { donationConfirmationActionCreators } from "./DonationConfirmationActions";

import InfoMessage from "components/atoms/InfoMessage";
import DesignedButton from "components/atoms/DesignedButton";

import MainHeader from "components/atoms/MainHeader";
import styled from "styled-components";
import { Breadcrumbs } from "components/organisms/Breadcrumbs";
import { size } from "styles/Size";
import ERROR_MESSAGES from "utils/errorMessages";
import { LogInState } from "containers/App/GlobalTypes";
import { ItemText } from "components/atoms/ItemText";

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const ColumnStyled = styled(DefaultGrid.Column)`
  &&& {
    margin-bottom: 10px !important;
    text-align: left;
  }
`;
const ButtonColumn = styled(DefaultGrid.Column)`
  &&& {
    max-width: 200px;
  }
`;

const PaddingGrid = padding(DefaultGrid);

const ImageRow = styled(DefaultGrid.Row)`
  max-width: 370px;
`;
const ResizeableButton = size(DesignedButton);

const TextStyled = styled(PaddingColumn)`
  text-align: left;
`;

interface DonationConfirmationParams {
  [k: string]: string;
  id: string;
}

type DonationConfirmationProps =
  RouteComponentProps<DonationConfirmationParams>;

const DonationConfirmation: React.FC<DonationConfirmationProps> = (props) => {
  const dispatch = useDispatch();
  const { data: donation } = useSelector<State, DonationState>(
    (state) => state.donation
  );
  const { error } = useSelector<State, TransactionsState>(
    (state) => state.transactions
  );
  const { initialValues } = useSelector<State, DonationApplicationState>(
    (state) => state.donationApplication
  );

  const { id } = props.match.params;

  const actions = useMemo(() => {
    return {
      navigate: bindActionCreators(navigateActionsCreators, dispatch),
      ...bindActionCreators(donationConfirmationActionCreators, dispatch),
    };
  }, [dispatch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return !donation ? (
    <Redirect
      to={generatePath(ROUTES.DONATION_APPLICATION, props.match.params)}
    />
  ) : (
    <PaddingGrid
      id="DonationComfirmation"
      centered
      paddingleft={16}
      paddingright={16}
    >
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>

      <Row id="MainContentRow">
        <ResizeableColumn mobile={16} tablet={10} computer={6}>
          <FluidGrid id="MainContentGrid">
            <Row id="TitleRow" centered>
              <MainHeader>申込み内容確認</MainHeader>
            </Row>
            <PaddingRow id="BodyRow" paddingtop={0} paddingbottom={0} centered>
              <PaddingGrid paddingtop={16}>
                <ImageRow id="Images" centered>
                  <DonationInfo donation={donation} />
                </ImageRow>
              </PaddingGrid>
            </PaddingRow>
            <DefaultGrid.Row style={{ paddingBottom: 0 }}>
              <Header as="h2">{donation?.name}</Header>
            </DefaultGrid.Row>
            <PaddingRow paddingleft={5}>
              <Header as="h3">{donation?.point + "pt"}</Header>
            </PaddingRow>
            <DefaultGrid.Row>
              <ConfirmationChildRows data={initialValues} />
            </DefaultGrid.Row>
            {error && (
              <DefaultGrid.Row centered>
                <DefaultGrid.Column textAlign={"center"}>
                  <InfoMessage
                    severity="error"
                    message={
                      error.toString() === "Not enoguht point for this donation"
                        ? ERROR_MESSAGES.NO_STOCK
                        : ERROR_MESSAGES.NO_POINT
                    }
                  />
                </DefaultGrid.Column>
              </DefaultGrid.Row>
            )}
            <Row style={{ paddingTop: "0.5rem" }}>
              <DefaultColumn>
                <SubHeader style={{ paddingLeft: "1rem" }}>備考</SubHeader>
              </DefaultColumn>
            </Row>
            {donation?.comment == null ? (
              <>
                <p style={{ fontSize: "16px" }}>特になし</p>
              </>
            ) : (
              <p style={{ fontSize: "16px" }}>{donation?.comment}</p>
            )}
            <Row style={{ paddingTop: "0.5rem" }}>
              <DefaultColumn>
                <SubHeader style={{ paddingLeft: "1rem" }}>コメント</SubHeader>
              </DefaultColumn>
            </Row>
            {initialValues?.free_comment === null ||
            initialValues?.free_comment === "" ? (
              <>
                <ItemText style={{ fontSize: "16px" }}>特になし</ItemText>
              </>
            ) : (
              <ItemText style={{ fontSize: "16px" }}>
                {initialValues.free_comment}
              </ItemText>
            )}
            <DefaultGrid.Row centered>
              <ButtonColumn width={8}>
                <ResizeableButton
                  fluid
                  onClick={() => {
                    actions.navigate.push(
                      generatePath(
                        ROUTES.DONATION_APPLICATION,
                        props.match.params
                      )
                    );
                    sessionStorage.setItem("backToEditScreenFlag", "true");
                  }}
                  inverted
                  style={{ padding: 15, fontSize: 20, fontWeight: "bold" }}
                >
                  修正する
                </ResizeableButton>
              </ButtonColumn>
              <ButtonColumn width={8}>
                <ResizeableButton
                  fluid
                  onClick={(e: React.FormEvent<HTMLInputElement>) => {
                    e.currentTarget.disabled = true;
                    sessionStorage.removeItem("form_values");
                    actions.fix(id, initialValues);
                  }}
                  style={{ padding: 15, fontSize: 20, fontWeight: "bold" }}
                >
                  申し込む
                </ResizeableButton>
              </ButtonColumn>
            </DefaultGrid.Row>
          </FluidGrid>
        </ResizeableColumn>
      </Row>
    </PaddingGrid>
  );
};

export default withHeaderAndFooter(DonationConfirmation);

interface ConfirmationAreaProps {
  data: DonationApplicationState["initialValues"];
}

const ConfirmationChildRows: React.FC<ConfirmationAreaProps> = ({ data }) => {
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);
  return (
    <Form id="ConfirmationChildRows">
      <DefaultGrid>
        <LabelRow>
          <LabelColumn width={16}>
            <StyledLabel htmlFor="last_name">受取人氏名</StyledLabel>
          </LabelColumn>
        </LabelRow>
        <PaddingRow paddingtop={0} columns="equal">
          <PaddingColumn paddingright={0}>
            <TextStyled>
              <ItemText>
                {scope === "orphanage_user"
                  ? data.name
                  : data.last_name + " " + data.first_name}
              </ItemText>
            </TextStyled>
          </PaddingColumn>
        </PaddingRow>

        <LabelRow>
          <LabelColumn width={16}>
            <StyledLabel htmlFor="last_kana_name">
              受取人氏名（かな）
            </StyledLabel>
          </LabelColumn>
        </LabelRow>
        <PaddingRow paddingtop={0} columns="equal">
          <PaddingColumn paddingright={0}>
            <TextStyled>
              <ItemText>
                {scope === "orphanage_user"
                  ? data.kana
                  : data.last_kana_name + " " + data.first_kana_name}
              </ItemText>
            </TextStyled>
          </PaddingColumn>
        </PaddingRow>
      </DefaultGrid>
      <Row>
        <DefaultColumn>
          <SubHeader
            id="DestinationInfoSubHeader"
            style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
          >
            送付先情報
          </SubHeader>
        </DefaultColumn>
      </Row>
      <DefaultGrid>
        <DefaultGrid.Row>
          {!data.pending && (
            <ColumnStyled mobile={16} computer={5}>
              <CheckBox
                id="sameApplicant"
                name="sameApplicant"
                checked={data.sameApplicant}
                value="true"
                label={DONATION_APPLICATION_CHILD_LABEL_MAP["sameApplicant"]}
              />
            </ColumnStyled>
          )}
          {!data.pending && (
            <ColumnStyled mobile={16} computer={6}>
              <CheckBox
                id="sameAddress"
                name="sameAddress"
                checked={data.sameAddress}
                value="true"
                label={DONATION_APPLICATION_CHILD_LABEL_MAP["sameAddress"]}
              />
            </ColumnStyled>
          )}
          {data.pending && (
            <p style={{ color: "red", fontSize: "16px", paddingLeft: "1rem" }}>
              あとから「申込履歴」より入力してください
            </p>
          )}
        </DefaultGrid.Row>
      </DefaultGrid>
      {!data.pending && (
        <DefaultGrid>
          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="postal_code">郵便番号</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>{data.postal_code}</TextStyled>
            </PaddingColumn>
          </PaddingRow>
          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="prefecture">都道府県</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal" textAlign="left">
            <PaddingColumn paddingright={0}>
              <TextStyled>{data.prefecture}</TextStyled>
            </PaddingColumn>
          </PaddingRow>
          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="city">市区町村</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>{data.city}</TextStyled>
            </PaddingColumn>
          </PaddingRow>

          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="address1">番地</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>{data.address1}</TextStyled>
            </PaddingColumn>
          </PaddingRow>

          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="address2">建物名／部屋番号</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>
                {data.address2 ? data.address2 : "未設定"}
              </TextStyled>
            </PaddingColumn>
          </PaddingRow>

          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="receiver_place_name">
                お届け先の場所の名前
              </StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow paddingtop={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>
                {data.receiver_place_name ? data.receiver_place_name : "未設定"}
              </TextStyled>
            </PaddingColumn>
          </PaddingRow>

          <LabelRow>
            <LabelColumn width={16}>
              <StyledLabel htmlFor="tel_number">電話番号</StyledLabel>
            </LabelColumn>
          </LabelRow>
          <PaddingRow padding={0} columns="equal">
            <PaddingColumn paddingright={0}>
              <TextStyled>
                {data.tel_number ? data.tel_number : "未設定"}
              </TextStyled>
            </PaddingColumn>
          </PaddingRow>
        </DefaultGrid>
      )}
    </Form>
  );
};

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const StyledLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
`;

const LabelColumn = styled(Column)`
  text-align: left;
`;

const ResizeableGrid = size(padding(DefaultGrid));
const ResizeableColumn = size(Column);
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
const DefaultColumn: typeof PaddingColumn = (props) => (
  <PaddingColumn padding={0} {...props} />
);
const SubHeader = styled.h2`
  font-size: 1.2rem;
`;
