import { Reducer } from "redux";
import actions from "./GlobalsConstants";
import { GlobalsState, GlobalsActionTypes } from "./GlobalsTypes";

const initialState = (): GlobalsState => ({});

const globals: Reducer<GlobalsState, GlobalsActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};

export default globals;
