import DefaultGrid from "styles/DefaultGrid";
import React from "react";
import { Image } from "semantic-ui-react";
import { DonationInfoProps } from "./DonationInfoType";
import Swiper from "react-id-swiper";
import { COLORS } from "utils/ComponentUtils";
import styled from "styled-components";

const SwiperStyled = styled.div`
  --swiper-theme-color: ${COLORS.SWIPER};
  max-width: 100vw;
`;

const ImageStyled = styled(Image)`
  &&& {
    object-fit: contain;
    height: 270px;
  }
`;
const ImageRow = styled(DefaultGrid.Row)`
  max-width: 370px;
`;

const params = {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
};

const DonationInfo: React.FC<DonationInfoProps> = ({ donation }) => {
  return (
    <ImageRow id="DonationInfo">
      <SwiperStyled>
        <Swiper {...params}>
          {donation?.images.map((image) => (
            <div key={image.url}>
              <ImageStyled
                id="DonationImage"
                src={image.url}
                size="medium"
                centered
              />
            </div>
          ))}
        </Swiper>
      </SwiperStyled>
    </ImageRow>
  );
};

export default DonationInfo;
