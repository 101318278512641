import OrphanageUser from "api/v1/OrphanageUser";
import { accessToken } from "containers/App/GlobalSelectors";
import { call, put, select, takeLatest } from "redux-saga/effects";
import serialize from "serialize-javascript";
import request, {
  AsyncReturnType,
  requestHeaders,
  requestHeadersAuthorized,
} from "utils/request";
import { globalActionsCreators } from "./GlobalActions";
import Actions from "./GlobalConstants";
import { LogInAction, SCOPE } from "./GlobalTypes";

const loginRequestUrl = `${process.env.REACT_APP_API_URL}/oauth/login`;
const logoutRequestUrl = `${process.env.REACT_APP_API_URL}/oauth/logout`;

export const clickLogInSaga = function* (action: LogInAction) {
  try {
    const payload = action.payload;
    let loginObject = {};

    if (action.payload.grantType === "password") {
      loginObject = {
        grant_type: payload.grantType,
        email: payload.email,
        password: payload.password,
        scope: payload.scope,
      };
    }

    const response = yield call(request, loginRequestUrl, {
      method: "POST",
      body: serialize(loginObject),
      ...requestHeaders,
    });

    const userName = (yield getUserName(
      payload.scope,
      response.access_token
    )) as string;

    sessionStorage.setItem("access_token", response.access_token);
    sessionStorage.setItem("scope", payload.scope);

    yield put(
      globalActionsCreators.logInSuccess(
        response.access_token,
        userName,
        response.id,
        payload.scope
      )
    );
  } catch (error) {
    yield put(globalActionsCreators.logOut());
    yield put(globalActionsCreators.logInError(error));
  }
};

export function* logoutSaga() {
  try {
    let responseAccessToken = yield select(accessToken);
    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }
    if (responseAccessToken) {
      yield call(request, logoutRequestUrl, {
        method: "POST",
        body: serialize({ token: responseAccessToken }),
        ...requestHeaders,
      });
    }

    sessionStorage.removeItem("scope");
    sessionStorage.removeItem("access_token");

    yield put(globalActionsCreators.logOutSuccess());
  } catch (error) {
    sessionStorage.removeItem("scope");
    sessionStorage.removeItem("access_token");

    yield put(globalActionsCreators.logOutError(error));
  }
}

export const logInSaga = function* () {
  yield takeLatest(Actions.LOGIN, clickLogInSaga);
  yield takeLatest(Actions.LOGOUT, logoutSaga);
};

export const getUserName = function* (scope: SCOPE, accessToken: string) {
  if (scope === SCOPE.CHILD) {
    const childUserResponse = yield call(
      request,
      `${process.env.REACT_APP_API_URL}/child_user`,
      {
        method: "GET",
        ...requestHeadersAuthorized(accessToken),
      }
    );
    return `${childUserResponse.data.last_name}${childUserResponse.data.first_name}`;
  } else {
    const orphonageUserResponse = (yield call(
      OrphanageUser.orphanage,
      accessToken
    )) as AsyncReturnType<typeof OrphanageUser.orphanage>;
    return `${orphonageUserResponse.data.name}`;
  }
};
