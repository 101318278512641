import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";
import MainHeader from "components/atoms/MainHeader";
import { Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItemProps, Form, Grid } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { BREAKPOINTS, navigateActionsCreators } from "utils/ComponentUtils";
import * as Yup from "yup";
import { PersonalIdentificationProps } from ".";
import personalIdentificationActions from "./PersonalIdentificationActions";
import Validate from "utils/Validate";

const { Column, PaddingRow, PaddingColumn } = DefaultGrid;

const MAX_AGE = 29;

const MainTextHeader = styled(MainHeader)`
  &&& {
    @media (max-width: 400px) {
      text-align: left;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 18px;
    }
    text-align: center;
    font-size: 24px;
  }
`;

const MainGrid = styled(Grid)`
  &&& {
    margin-top: 100px;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      margin-top: 40px;
    }
  }
`;

type ChildUserPersonalIdentificationProps = PersonalIdentificationProps;

const ChildUserPersonalIdentification: React.FC<
  ChildUserPersonalIdentificationProps
> = ({ resetPasswordToken, message, userType }) => {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      ...bindActionCreators(personalIdentificationActions, dispatch),
      navigate: bindActionCreators(navigateActionsCreators, dispatch),
    };
  }, [dispatch]);

  interface FormValues {
    birthday_year: string;
    birthday_month: string;
    birthday_date: string;
  }
  const initialFormValues: FormValues = {
    birthday_year: "",
    birthday_month: "",
    birthday_date: "",
  };
  const childUserValidationScheme = Yup.object().shape({
    birthday_year: Validate.birthday_year,
    birthday_month: Validate.birthday_month,
    birthday_date: Validate.birthday_date,
  });

  const handleSubmit = (values: FormValues) => {
    actions.childSubmit({
      resetPasswordToken,
      birthDate:
        values.birthday_year +
        "-" +
        values.birthday_month +
        "-" +
        values.birthday_date,
      userType,
    });
  };

  const getYears = () => {
    const years = [];
    const maxYear = new Date().getFullYear();
    const minYear = maxYear - MAX_AGE;
    for (let i = minYear; i <= maxYear; i++) {
      years.push({ key: i, value: i, text: i });
    }
    return years;
  };

  // messageのクリア
  useEffect(() => {
    return () => {
      actions.setMessage({ message: "" });
    };
  }, [actions]);

  return (
    <MainGrid id="MainContents" textAlign="center">
      <PaddingRow id="TextContainer" padding={30}>
        <PaddingColumn width={14}>
          <MainTextHeader>
            本人認証のため生年月日を入力してください
          </MainTextHeader>
        </PaddingColumn>
      </PaddingRow>
      {message && (
        <PaddingRow centered padding={10}>
          <Column textAlign={"center"}>
            <InfoMessage severity="error" message={message} />
          </Column>
        </PaddingRow>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={childUserValidationScheme}
      >
        {({ values, handleChange, handleSubmit, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DefaultGrid
                id="InputContainer"
                centered
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              >
                <DefaultGrid
                  style={{ paddingTop: "20px", paddingBottom: "20px" }}
                >
                  <Column>
                    <DefaultGrid centered>
                      <PaddingRow padding={0} textAlign="center">
                        <Column>
                          <Form.Group style={{ verticalAlign: "middle" }}>
                            {errors.birthday_year && (
                              <InfoMessage severity="error" message={"error"} />
                            )}
                            <Form.Select
                              name="birthday_year"
                              id="birthday_year"
                              selection
                              scrolling
                              handleChange={handleChange}
                              value={values.birthday_year}
                              options={getYears().map((item) => ({
                                ...item,
                                id: item.key,
                                onClick: (e) => {
                                  handleChange({
                                    ...e,
                                    target: {
                                      ...e.target,
                                      id: "birthday_year",
                                      name: "birthday_year",
                                      value: item.value,
                                    },
                                  });
                                },
                              }))}
                              style={{ minWidth: "90px", height: "40px" }}
                            />
                            <label style={{ margin: "auto 0" }}>年</label>
                            <Form.Select
                              name="birthday_month"
                              id="birthday_month"
                              selection
                              scrolling
                              handleChange={handleChange}
                              value={values.birthday_month}
                              options={MONTHS.map((item) => ({
                                ...item,
                                id: item.key,
                                onClick: (e) => {
                                  handleChange({
                                    ...e,
                                    target: {
                                      ...e.target,
                                      id: "birthday_month",
                                      name: "birthday_month",
                                      value: item.value,
                                    },
                                  });
                                },
                              }))}
                              style={{ minWidth: "65px", height: "40px" }}
                            />
                            <label style={{ margin: "auto 0" }}>月</label>
                            <Form.Select
                              name="birthday_date"
                              id="birthday_date"
                              selection
                              scrolling
                              handleChange={handleChange}
                              value={values.birthday_date}
                              options={DATES.map((item) => ({
                                ...item,
                                id: item.key,
                                onClick: (e) => {
                                  handleChange({
                                    ...e,
                                    target: {
                                      ...e.target,
                                      id: "birthday_date",
                                      name: "birthday_date",
                                      value: item.value,
                                    },
                                  });
                                },
                              }))}
                              style={{ minWidth: "65px", height: "40px" }}
                            />
                            <label style={{ margin: "auto 0" }}>日</label>
                          </Form.Group>
                          <p style={{ fontSize: "12px" }}>(例)2002/02/12</p>
                        </Column>
                      </PaddingRow>
                    </DefaultGrid>
                  </Column>
                </DefaultGrid>
                <PaddingRow id="ButtonContainer" padding={40}>
                  <Column style={{ width: "150px" }}>
                    <DesignedButton type="submit" fluid={true}>
                      進む
                    </DesignedButton>
                  </Column>
                </PaddingRow>
              </DefaultGrid>
            </Form>
          );
        }}
      </Formik>
    </MainGrid>
  );
};

export default ChildUserPersonalIdentification;

const MONTHS: Readonly<DropdownItemProps[]> = [
  { key: "01", value: "01", text: "1" },
  { key: "02", value: "02", text: "2" },
  { key: "03", value: "03", text: "3" },
  { key: "04", value: "04", text: "4" },
  { key: "05", value: "05", text: "5" },
  { key: "06", value: "06", text: "6" },
  { key: "07", value: "07", text: "7" },
  { key: "08", value: "08", text: "8" },
  { key: "09", value: "09", text: "9" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];

const DATES: Readonly<DropdownItemProps[]> = [
  { key: "01", value: "01", text: "1" },
  { key: "02", value: "02", text: "2" },
  { key: "03", value: "03", text: "3" },
  { key: "04", value: "04", text: "4" },
  { key: "05", value: "05", text: "5" },
  { key: "06", value: "06", text: "6" },
  { key: "07", value: "07", text: "7" },
  { key: "08", value: "08", text: "8" },
  { key: "09", value: "09", text: "9" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
  { key: "13", value: "13", text: "13" },
  { key: "14", value: "14", text: "14" },
  { key: "15", value: "15", text: "15" },
  { key: "16", value: "16", text: "16" },
  { key: "17", value: "17", text: "17" },
  { key: "18", value: "18", text: "18" },
  { key: "19", value: "19", text: "19" },
  { key: "20", value: "20", text: "20" },
  { key: "21", value: "21", text: "21" },
  { key: "22", value: "22", text: "22" },
  { key: "23", value: "23", text: "23" },
  { key: "24", value: "24", text: "24" },
  { key: "25", value: "25", text: "25" },
  { key: "26", value: "26", text: "26" },
  { key: "27", value: "27", text: "27" },
  { key: "28", value: "28", text: "28" },
  { key: "29", value: "29", text: "29" },
  { key: "30", value: "30", text: "30" },
  { key: "31", value: "31", text: "31" },
];
