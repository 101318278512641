import { Category } from "interfaces/db/Category";
import actions from "./CategoriesConstants";
import { GetCategoriesAction, SetCategoriesAction } from "./CategoriesTypes";

export const getCategories = (): GetCategoriesAction => {
  return {
    type: actions.GET_CATEGORIES,
  };
};

export const setCategories = (data: Category[]): SetCategoriesAction => {
  return {
    type: actions.SET_CATEGORIES,
    payload: {
      data,
    },
  };
};

export const categoriesActionsCreators = {
  getCategories,
  setCategories,
};
