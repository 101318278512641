import Orphanage from "api/v1/Orphanage";
import { push } from "connected-react-router";
import { accessToken } from "containers/App/GlobalSelectors";
import { State } from "interfaces/State";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AsyncReturnType } from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import requestMock from "utils/requestMock";
import ROUTES from "utils/routes";
import childActionsCreators from "./ChildActions";
import actions from "./ChildConstants";
import { ChildState, DeleteChildAction, GetChildAction } from "./ChildTypes";

function* deleteChildSaga(params: DeleteChildAction) {
  try {
    const responseAccessToken = yield select(accessToken);
    const requestURL = `${process.env.REACT_APP_API_URL}/child_user/${params.payload.id}`;

    yield call(requestMock, requestURL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        charset: "UTF-8",
        Authorization: `Bearer ${responseAccessToken}`,
      },
    });

    yield put(childActionsCreators.childSuccess());
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(childActionsCreators.childError(error));
  }
}

function* getChildSaga({ payload: { id } }: GetChildAction) {
  try {
    const response = (yield call(
      Orphanage.getChildUser,
      yield select(accessToken),
      id
    )) as AsyncReturnType<typeof Orphanage.getChildUser>;

    yield put(childActionsCreators.getChildSuccess(response.data));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(childActionsCreators.childError(error));
  }
}

function* writeChildSaga() {
  try {
    const { id, child } = (yield select<(s: State) => ChildState>(
      (s) => s.child
    )) as ChildState;
    yield call(Orphanage.writeChildUsers, yield select(accessToken), id, child);

    yield put(push(ROUTES.CHILDREN_LIST));
    yield put(childActionsCreators.childSuccess());
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(childActionsCreators.childError(error));
  }
}

export default function* childRootSaga() {
  yield takeLatest(actions.GET_CHILD, getChildSaga);
  yield takeLatest(actions.WRITE_CHILD, writeChildSaga);
  yield takeLatest(actions.DELETE_CHILD, deleteChildSaga);
}
