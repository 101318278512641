import actions from "./ChangePasswordConstants";

import {
  ChangePasswordAction,
  ChangePasswordSuccessAction,
  ChangePasswordErrorAction,
} from "./ChangePasswordTypes";

const changePassword = (
  reset_password_token: string | null,
  scope: string,
  password: string,
  password_confirmation: string
): ChangePasswordAction => {
  return {
    type: actions.CHANGE_PASSWORD,
    payload: {
      reset_password_token,
      scope,
      password,
      password_confirmation,
    },
  };
};

const changePasswordSuccess = (): ChangePasswordSuccessAction => {
  return {
    type: actions.CHANGE_PASSWORD_SUCCESS,
  };
};

const changePasswordError = (error: Error): ChangePasswordErrorAction => {
  return {
    type: actions.CHANGE_PASSWORD_ERROR,
    payload: {
      error,
    },
  };
};

const ChangePasswordCreators = {
  changePassword,
  changePasswordSuccess,
  changePasswordError,
};

export default ChangePasswordCreators;
