import React, { ComponentProps, useEffect, useMemo } from "react";

import { Form, Label } from "semantic-ui-react";
import styled from "styled-components";
import { ProfileChild } from "./profileChildTypes";
import ProfileChildCreators from "./profileChildActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";

import TextBox from "components/atoms/TextBox";
import DesignedButton from "components/atoms/DesignedButton";
import SelectBox from "components/atoms/SelectBox";
import InfoMessage from "components/atoms/InfoMessage";

import prefecturesData from "utils/prefectures";

import { withHeaderAndFooter } from "components/organisms/Header";
import { RouteComponentProps, withRouter } from "react-router";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import MainHeader from "components/atoms/MainHeader";
import Annotation from "components/atoms/Annotation";
import { align } from "styles/Align";
import LinkButton from "components/atoms/LinkButton";
import ROUTES from "utils/routes";
import REGEX_STRINGS from "utils/regexStrings";
import ERROR_MESSAGES from "utils/errorMessages";
import { ItemText } from "components/atoms/ItemText";

const PaddingGrid = padding(DefaultGrid);
const ContentsRow = styled(DefaultGrid.Row)`
  &&& {
    max-width: 375px;
  }
`;

const StyledLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
`;

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

interface RouterProps {
  id: string;
}

const ProfileScheme = Yup.object().shape({
  tel_number: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .min(10, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .max(11, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .nullable(),
  postal_code: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .length(7, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .nullable(),
  prefecture: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .nullable(),
  city: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
});

const ChildrenProfilePage: React.FC<RouteComponentProps<RouterProps>> = (
  props
) => {
  const { error: addressError } = useSelector((state: State) => state.address);

  const {
    profileChild: profileData,
    message,
    error: profileError,
  } = useSelector((state: State) => state.profileChild);
  const initialFormValues: ProfileChild = {
    id: profileData.id || "",
    email: profileData.email || "",
    available_point: profileData?.available_point || 0,
    first_name: profileData.first_name || "",
    last_name: profileData.last_name || "",
    first_kana_name: profileData.first_kana_name || "",
    last_kana_name: profileData.last_kana_name || "",
    address1: profileData.address1 || "",
    address2: profileData.address2 || "",
    city: profileData.city || "",
    postal_code: profileData.postal_code || "",
    prefecture: profileData.prefecture || "東京都",
    tel_number: profileData.tel_number || "",
  };

  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      profileChild: bindActionCreators(ProfileChildCreators, dispatch),
    };
  }, [dispatch]);

  useEffect(() => {
    actions.profileChild.getProfileChild();
  }, [actions.profileChild]);

  const handleSubmit = async (values: ProfileChild) => {
    actions.profileChild.updateProfileChild(values);
  };
  return (
    <PaddingGrid paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>

      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <Form>
                <DefaultGrid id="ChildEditor">
                  <Row id="HeaderRow">
                    <Column width={16} style={{ paddingBottom: 32 }}>
                      <MainHeader>プロフィール編集</MainHeader>
                    </Column>
                  </Row>

                  {message && (
                    <DefaultGrid.Row centered>
                      <DefaultGrid.Column textAlign={"center"}>
                        <InfoMessage
                          severity="success"
                          message={message as string}
                        />
                      </DefaultGrid.Column>
                    </DefaultGrid.Row>
                  )}

                  {(addressError || profileError) && (
                    <DefaultGrid.Row centered>
                      <DefaultGrid.Column textAlign={"center"}>
                        <InfoMessage
                          severity="error"
                          message={
                            addressError
                              ? (addressError.message as string)
                              : (profileError?.message as string)
                          }
                        />
                      </DefaultGrid.Column>
                    </DefaultGrid.Row>
                  )}

                  <Formik
                    enableReinitialize={true}
                    initialValues={initialFormValues}
                    validationSchema={ProfileScheme}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      errors,
                      handleSubmit,
                      touched,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <PaddingRow id="InputRow" padding={0}>
                          <DefaultGrid>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="last_name">
                                  氏名
                                </StyledLabel>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow columns="equal">
                              <PaddingColumn paddingright={0}>
                                <ItemText>{values.last_name}</ItemText>
                              </PaddingColumn>
                              <Column>
                                <ItemText>{values.first_name}</ItemText>
                              </Column>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="last_kana_name">
                                  氏名（かな）
                                </StyledLabel>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow columns="equal">
                              <PaddingColumn paddingright={0}>
                                <ItemText>{values.last_kana_name}</ItemText>
                              </PaddingColumn>
                              <Column>{values.first_kana_name}</Column>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="point">
                                  保有ポイント
                                </StyledLabel>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow padingbottom={20}>
                              <LabelColumn width={16}>
                                <ItemText>{values.available_point}pt</ItemText>
                              </LabelColumn>
                            </PaddingRow>

                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="tel_number">
                                  電話番号
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow padding={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <TextBox
                                  error={
                                    touched.tel_number &&
                                    errors.tel_number && {
                                      content: errors.tel_number,
                                    }
                                  }
                                  id="tel_number"
                                  name="tel_number"
                                  value={values.tel_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                />
                              </PaddingColumn>
                            </PaddingRow>
                            <PaddingRow
                              paddingtop={0}
                              paddingbottom={0}
                              columns="equal"
                            >
                              <AnnotationColumn
                                paddingright={0}
                                textAlign="left"
                              >
                                <Annotation>
                                  ※ハイフンなしで入力してください
                                </Annotation>
                              </AnnotationColumn>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="postal_code">
                                  郵便番号
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow padding={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <TextBox
                                  error={
                                    touched.postal_code &&
                                    errors.postal_code && {
                                      content: errors.postal_code,
                                    }
                                  }
                                  id="postal_code"
                                  name="postal_code"
                                  value={values.postal_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                />
                              </PaddingColumn>
                            </PaddingRow>
                            <PaddingRow
                              paddingtop={0}
                              paddingbottom={0}
                              columns="equal"
                            >
                              <AnnotationColumn
                                paddingright={0}
                                textAlign="left"
                              >
                                <Annotation>
                                  ※ハイフンなしで入力してください
                                </Annotation>
                              </AnnotationColumn>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="prefecture">
                                  都道府県
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow paddingtop={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <SelectBox
                                  error={
                                    touched.prefecture && errors.prefecture
                                      ? errors.prefecture
                                      : undefined
                                  }
                                  id={"prefecture"}
                                  name={"prefecture"}
                                  value={values.prefecture}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                  options={prefecturesData.map((item) => ({
                                    ...item,
                                    id: item.key,
                                    onClick: (e) => {
                                      handleChange({
                                        ...e,
                                        target: {
                                          ...e.target,
                                          id: "prefecture",
                                          name: "prefecture",
                                          value: item.value,
                                        },
                                      });
                                    },
                                  }))}
                                  selection
                                />
                              </PaddingColumn>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="city">
                                  市区町村
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow paddingtop={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <TextBox
                                  error={
                                    touched.city &&
                                    errors.city && { content: errors.city }
                                  }
                                  id="city"
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                />
                              </PaddingColumn>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="address1">
                                  番地
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow paddingtop={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <TextBox
                                  error={
                                    touched.address1 &&
                                    errors.address1 && {
                                      content: errors.address1,
                                    }
                                  }
                                  id="address1"
                                  name="address1"
                                  value={values.address1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                />
                              </PaddingColumn>
                            </PaddingRow>
                            <LabelRow>
                              <LabelColumn width={16}>
                                <StyledLabel htmlFor="address2">
                                  建物名／部屋番号
                                </StyledLabel>
                                <Label
                                  color="green"
                                  size="mini"
                                  style={{ marginLeft: "10px" }}
                                >
                                  任意
                                </Label>
                              </LabelColumn>
                            </LabelRow>
                            <PaddingRow paddingtop={0} columns="equal">
                              <PaddingColumn paddingright={0}>
                                <TextBox
                                  error={
                                    touched.address2 &&
                                    errors.address2 && {
                                      content: errors.address2,
                                    }
                                  }
                                  id="address2"
                                  name="address2"
                                  value={values.address2}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  fluid={true}
                                />
                              </PaddingColumn>
                            </PaddingRow>
                          </DefaultGrid>
                        </PaddingRow>
                        <Row
                          id="ControlsRow"
                          style={{ paddingTop: 32, paddingBottom: 32 }}
                        >
                          <Column width={16}>
                            <DefaultGrid>
                              <PaddingRow padding={0} centered>
                                <FitColumn
                                  id="CancelColumn"
                                  padding={0}
                                  paddingright="0.5rem"
                                >
                                  <LinkButton
                                    to={ROUTES.SETTINGS}
                                    design="primary"
                                    inverted
                                  >
                                    キャンセル
                                  </LinkButton>
                                </FitColumn>
                                <FitColumn
                                  id="EntryColumn"
                                  padding={0}
                                  paddingleft="0.5rem"
                                >
                                  <DesignedButton
                                    type="submit"
                                    onClick={handleSubmit}
                                  >
                                    保存する
                                  </DesignedButton>
                                </FitColumn>
                              </PaddingRow>
                            </DefaultGrid>
                          </Column>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </DefaultGrid>
              </Form>
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withRouter(withHeaderAndFooter(ChildrenProfilePage));

const LabelColumn = styled(Column)`
  text-align: left;
`;

const AnnotationColumn = align(PaddingColumn);

const FitColumn = styled(PaddingColumn)`
  &&& {
    width: fit-content !important;
  }
`;
