import Actions from "./GlobalConstants";
import {
  LogInAction,
  LogInErrorAction,
  LogInSuccessAction,
  LogOutAction,
  LogOutErrorAction,
  LogOutSuccessAction,
  SCOPE,
} from "./GlobalTypes";

const logIn = (
  grantType: string,
  email: string,
  password: string,
  scope: SCOPE
): LogInAction => {
  return {
    type: Actions.LOGIN,
    payload: {
      grantType,
      email,
      password,
      scope,
    },
  };
};

const logInSuccess = (
  accessToken: string,
  userName: string,
  id: number,
  scope: SCOPE
): LogInSuccessAction => {
  return {
    type: Actions.LOGIN_SUCCESS,
    payload: {
      accessToken,
      userName,
      id,
      scope,
    },
  };
};

const logInError = (error: Error): LogInErrorAction => {
  return {
    type: Actions.LOGIN_ERROR,
    payload: {
      error,
    },
  };
};

const logOut = (): LogOutAction => {
  return {
    type: Actions.LOGOUT,
  };
};

const logOutSuccess = (): LogOutSuccessAction => {
  return {
    type: Actions.LOGOUT_SUCCESS,
  };
};

const logOutError = (error: Error): LogOutErrorAction => {
  return {
    type: Actions.LOGOUT_ERROR,
    payload: {
      error,
    },
  };
};

// reduxが提供しているActionCreatorsMapObjectは関数の型を失うので使用しない
export const globalActionsCreators = {
  logIn,
  logInSuccess,
  logInError,
  logOut,
  logOutSuccess,
  logOutError,
};
