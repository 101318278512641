import React from "react";
import { Icon } from "semantic-ui-react";

interface Props {
  flex_placement: string;
  like_num: number;
  color: string;
}

const LikeLabel: React.FC<Props> = ({ like_num, color, flex_placement }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: flex_placement,
        color: color,
      }}
    >
      <Icon name="heart" />
      <div>{like_num}</div>
    </div>
  );
};

export default LikeLabel;
