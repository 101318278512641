import React, { ComponentProps, useEffect } from "react";

import { Label } from "semantic-ui-react";

import { State } from "interfaces/State";
import { ChildrenListState } from "modules/db/ChildrenList/ChildrenListTypes";
import { useSelector } from "react-redux";

import DefaultGrid from "styles/DefaultGrid";

import SelectBox from "components/atoms/SelectBox";

import TextBox from "components/atoms/TextBox";

import prefectures from "utils/prefectures";

import {
  FormikInputAreaProps,
  DonationApplicationInputState,
} from "./DonationApplicationTypes";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import styled from "styled-components";
import Annotation from "components/atoms/Annotation";
import { align } from "styles/Align";
import ERROR_MESSAGES from "utils/errorMessages";
import { LogInState } from "containers/App/GlobalTypes";
import { ItemText } from "components/atoms/ItemText";

interface ApplicationChildRowsInterface {
  setChildValue: React.Dispatch<React.SetStateAction<ChildUser | undefined>>;
  setCheckFlag: React.Dispatch<React.SetStateAction<boolean>>;
  donationPoint?: number;
  donationFledgedYear?: string;
  donationTags?: string[];
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  profileData?: ChildUser;
}

export const ApplicationChildRows: React.FC<
  FormikInputAreaProps<DonationApplicationInputState> &
    ApplicationChildRowsInterface
> = ({
  touched,
  errors,
  handleBlur,
  setChildValue,
  setCheckFlag,
  donationPoint,
  donationFledgedYear,
  donationTags,
  isDisabled,
  setIsDisabled,
  profileData,
}) => {
  const { children: childrenList } = useSelector<State, ChildrenListState>(
    (s) => s.childrenList
  );

  const searchChildWithTags =
    childrenList &&
    childrenList.filter(function (element) {
      if (donationTags && donationTags.length !== 0) {
        return (
          [...element.tags, ...donationTags].filter(
            (item) => element.tags.includes(item) && donationTags.includes(item)
          ).length > 0
        );
      } else {
        return true;
      }
    });
  const selectChildUser =
    searchChildWithTags &&
    searchChildWithTags.filter(function (element) {
      return (
        element.fledged_year === donationFledgedYear ||
        donationFledgedYear === null ||
        donationFledgedYear === ""
      );
    });

  const { scope } = useSelector<State, LogInState>((state) => state.logIn);

  useEffect(() => {
    if (scope === "child_user") {
      if (childrenList) {
        const data = childrenList[0];
        setChildValue(data);
        setCheckFlag(false);
      }
    }
  });
  useEffect(() => {
    if (
      donationPoint &&
      profileData &&
      profileData.available_point < donationPoint
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    if (scope === "child_user") {
      if (childrenList) {
        if (donationPoint && childrenList[0].available_point < donationPoint) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    }
  }, [donationPoint, setIsDisabled, childrenList, scope, profileData]);

  return (
    <DefaultGrid id="ApplicationChildRows">
      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="last_name">受取人氏名</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        {scope === "orphanage_user" ? (
          <PaddingColumn paddingright={0} paddingleft={0}>
            {childrenList === undefined || childrenList.length === 0 ? (
              ""
            ) : (
              <SelectBox
                error={touched.name && errors.name ? errors.name : undefined}
                id={"name"}
                name={"name"}
                onChange={(event, value: Record<string, string | number>) => {
                  sessionStorage.setItem("backToEditScreenFlag", "false");
                  const childData = childrenList.filter(
                    (child) => value.value === `${child.id}`
                  );
                  const data = childData[0] as ChildUser;
                  setChildValue(data);
                  setCheckFlag(false);
                }}
                onBlur={handleBlur}
                text={
                  profileData && profileData.last_name + profileData.first_name
                }
                options={
                  selectChildUser &&
                  selectChildUser.map((child) => ({
                    id: "name",
                    name: "name",
                    key: `${child.id}`,
                    value: `${child.id}`,
                    text: `${child.last_name}${child.first_name}`,
                  }))
                }
                selection
              />
            )}
          </PaddingColumn>
        ) : (
          <PaddingColumn paddingright={0} paddingleft={14}>
            {childrenList && (
              <ItemText>
                {childrenList[0].last_name + "  " + childrenList[0].first_name}
              </ItemText>
            )}
          </PaddingColumn>
        )}
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="last_kana_name">受取人氏名（かな）</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        {scope === "orphanage_user" ? (
          <PaddingColumn paddingright={0} paddingleft={14}>
            {profileData ? (
              <ItemText>
                {profileData.last_kana_name + profileData.first_kana_name}
              </ItemText>
            ) : (
              <br />
            )}
          </PaddingColumn>
        ) : (
          <PaddingColumn paddingright={0} paddingleft={14}>
            {childrenList && (
              <ItemText>
                {childrenList[0].last_kana_name +
                  "  " +
                  childrenList[0].first_kana_name}
              </ItemText>
            )}
          </PaddingColumn>
        )}
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="available_point">
            {scope === "orphanage_user"
              ? "受取人のポイント"
              : "あなたのポイント"}
          </StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn>
          {scope === "orphanage_user" ? (
            <>
              <ItemText>
                {profileData?.available_point !== undefined ? (
                  `${profileData?.available_point} pt`
                ) : (
                  <br />
                )}
              </ItemText>
              {isDisabled && (
                <p style={{ color: "red" }}>{ERROR_MESSAGES.NO_POINT}</p>
              )}
            </>
          ) : (
            <>
              <ItemText>
                {childrenList !== undefined ? (
                  `${childrenList[0].available_point} pt`
                ) : (
                  <br />
                )}
              </ItemText>
              {isDisabled && (
                <p style={{ color: "red" }}>{ERROR_MESSAGES.NO_POINT}</p>
              )}
            </>
          )}
        </PaddingColumn>
      </PaddingRow>
    </DefaultGrid>
  );
};

interface ApplicationRowsInterface {
  setChildValue: React.Dispatch<React.SetStateAction<ChildUser | undefined>>;
  setCheckFlag: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ApplicationRows: React.FC<
  FormikInputAreaProps<DonationApplicationInputState> & ApplicationRowsInterface
> = ({ touched, errors, values, handleChange, handleBlur }) => {
  return (
    <DefaultGrid id="ApplicationRows">
      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="postal_code">郵便番号</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow padding={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={
              touched.postal_code && errors.postal_code
                ? errors.postal_code
                : undefined
            }
            id={"postal_code"}
            name={"postal_code"}
            value={values.postal_code}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="1008228"
          />
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0} paddingbottom={0} columns="equal">
        <AnnotationColumn paddingright={0} textAlign="left">
          <Annotation>※ハイフンなしで入力してください</Annotation>
        </AnnotationColumn>
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="prefecture">都道府県</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <SelectBox
            error={
              touched.prefecture && errors.prefecture
                ? errors.prefecture
                : undefined
            }
            id={"prefecture"}
            name={"prefecture"}
            value={values.prefecture}
            onBlur={handleBlur}
            options={prefectures.map((item) => ({
              ...item,
              onClick: (e) => {
                handleChange({
                  ...e,
                  target: {
                    ...e.target,
                    id: "prefecture",
                    name: "prefecture",
                    value: item.value,
                  },
                });
              },
            }))}
            selection
          />
        </PaddingColumn>
      </PaddingRow>
      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="city">市区町村</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={touched.city && errors.city ? errors.city : undefined}
            id={"city"}
            name={"city"}
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="千代田区大手町"
          />
        </PaddingColumn>
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="address1">番地</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={
              touched.address1 && errors.address1 ? errors.address1 : undefined
            }
            id={"address1"}
            name={"address1"}
            value={values.address1}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="2-6-2"
          />
        </PaddingColumn>
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="address2">建物名／部屋番号</StyledLabel>
          <Label color="green" size="mini" style={{ marginLeft: "10px" }}>
            任意
          </Label>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={
              touched.address2 &&
              errors.address2 && { content: errors.address2 }
            }
            id="address2"
            name="address2"
            value={values.address2}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid={true}
            placeholder="ステージグランデ205号室"
          />
        </PaddingColumn>
      </PaddingRow>
      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="receiver_place_name">
            お届け先の場所の名前
          </StyledLabel>
          <Label color="green" size="mini" style={{ marginLeft: "10px" }}>
            任意
          </Label>
        </LabelColumn>
      </LabelRow>
      <PaddingRow paddingtop={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={
              touched.receiver_place_name &&
              errors.receiver_place_name && {
                content: errors.receiver_place_name,
              }
            }
            id="receiver_place_name"
            name="receiver_place_name"
            value={values.receiver_place_name}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid={true}
            placeholder="引越し先の自宅、新しい施設"
          />
        </PaddingColumn>
      </PaddingRow>

      <LabelRow>
        <LabelColumn width={16}>
          <StyledLabel htmlFor="tel_number">電話番号</StyledLabel>
        </LabelColumn>
      </LabelRow>
      <PaddingRow padding={0} columns="equal">
        <PaddingColumn paddingright={0} paddingleft={0}>
          <TextBox
            error={
              touched.tel_number &&
              errors.tel_number && { content: errors.tel_number }
            }
            id="tel_number"
            name="tel_number"
            value={values.tel_number}
            onChange={handleChange}
            onBlur={handleBlur}
            fluid={true}
            placeholder="0368426766"
          />
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0} paddingbottom={0} columns="equal">
        <AnnotationColumn paddingright={0} textAlign="left">
          <Annotation>
            ※ハイフンなしで入力してください
            <br />
          </Annotation>
          <Annotation>
            ※個人の電話番号がない場合は、施設の電話番号を入力してください
          </Annotation>
        </AnnotationColumn>
      </PaddingRow>
    </DefaultGrid>
  );
};

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const { Column, PaddingRow, PaddingColumn } = DefaultGrid;

const StyledLabel = styled.label`
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
`;

const LabelColumn = styled(Column)`
  text-align: left;
`;

const AnnotationColumn = align(PaddingColumn);
