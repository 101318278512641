import React, { useMemo, useState, useEffect } from "react";

import { Form } from "semantic-ui-react";

import { RequestChangePassword } from "./RequestChangePasswordTypes";
import requestChangePasswordCreators from "./RequestChangePasswordActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";

import { Formik } from "formik";
import * as Yup from "yup";

import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";

import TextBox from "components/atoms/TextBox";
import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";

import { SCOPE } from "containers/App/GlobalTypes";
import { Breadcrumb } from "semantic-ui-react";
import styled from "styled-components";
import MainHeader from "components/atoms/MainHeader";
import { NavLink } from "react-router-dom";
import { withHeaderAndFooter } from "components/organisms/Header";
import { BREAKPOINTS } from "utils/ComponentUtils";
import Validate from "utils/Validate";
import ERROR_MESSAGES from "utils/errorMessages";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const ResetHeader = styled(MainHeader)`
  &&& {
    font-size: 1.5rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 1.4rem;
    }
  }
`;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 600px !important;
`;

const requestChangePasswordScheme = Yup.object().shape({
  email: Validate.email,
});

const RequestChangePasswordPage: React.FC = () => {
  const { success, error, loading } = useSelector(
    (state: State) => state.requestChangePassword
  );
  const [clickedScope, setClickedScope] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const initialFormValues: RequestChangePassword = {
    email: "",
  };

  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      changePassword: bindActionCreators(
        requestChangePasswordCreators,
        dispatch
      ),
    };
  }, [dispatch]);

  const clickAndSetRole = (scope: string) => {
    setClickedScope(scope);
  };

  const handleSubmit = async (values: RequestChangePassword) => {
    if (clickedScope) {
      actions.changePassword.requestChangePassword(clickedScope, values.email);
    }
  };

  const sections = [
    { key: "", content: "", link: false },
    {
      key: "トップに戻る",
      content: "トップに戻る",
      to: "/",
      active: true,
      as: NavLink,
    },
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (success) {
      setIsDisabled(true);
    }
  }, [success]);

  return (
    <PaddingGrid
      id="RequestChangePassword"
      paddingleft={16}
      paddingright={16}
      centered
    >
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <Breadcrumb icon="left angle" sections={sections} />
        </PaddingColumn>
      </PaddingRow>
      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <DefaultGrid>
                <DefaultGrid.Row centered>
                  <DefaultGrid.Column textAlign={"center"}>
                    <ResetHeader>パスワード再設定メール送信</ResetHeader>
                  </DefaultGrid.Column>
                </DefaultGrid.Row>
              </DefaultGrid>
              <PaddingRow paddingtop={50} centered>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialFormValues}
                  validationSchema={requestChangePasswordScheme}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    handleSubmit,
                    touched,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <PaddingRow paddingbottom={20} centered>
                        <DefaultGrid.Column width={6}>
                          <Form.Group widths="equal">
                            <TextBox
                              type="text"
                              error={
                                touched.email &&
                                errors.email && { content: errors.email }
                              }
                              id="email"
                              name="email"
                              label="メールアドレス："
                              placeholder="メールアドレス"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              fluid={true}
                            />
                          </Form.Group>
                          {error && (
                            <p style={{ color: "red" }}>
                              {ERROR_MESSAGES.INVARID_MAIL}
                            </p>
                          )}
                        </DefaultGrid.Column>
                      </PaddingRow>
                      <DefaultGrid>
                        <DefaultGrid.Row centered>
                          <DefaultGrid.Column style={{ width: "250px" }}>
                            <DesignedButton
                              type="button"
                              onClick={() => {
                                clickAndSetRole(SCOPE.ORPHANAGE);
                                handleSubmit();
                              }}
                              fluid={true}
                              disabled={isDisabled || loading}
                            >
                              施設・里親として送信
                            </DesignedButton>
                          </DefaultGrid.Column>
                        </DefaultGrid.Row>
                      </DefaultGrid>
                      <DefaultGrid>
                        <DefaultGrid.Row centered>
                          <DefaultGrid.Column style={{ width: "250px" }}>
                            <DesignedButton
                              type="button"
                              onClick={() => {
                                clickAndSetRole(SCOPE.CHILD);
                                handleSubmit();
                              }}
                              fluid={true}
                              disabled={isDisabled || loading}
                            >
                              個人として送信
                            </DesignedButton>
                          </DefaultGrid.Column>
                        </DefaultGrid.Row>
                      </DefaultGrid>
                    </Form>
                  )}
                </Formik>
              </PaddingRow>
              {success && (
                <InfoMessage
                  severity="success"
                  message={"再設定メールを送信しました"}
                />
              )}
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withHeaderAndFooter(RequestChangePasswordPage);
