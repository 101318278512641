enum ActionTypes {
  SET_SEARCH_CONDITION = "Donations/SET_SEARCH_CONDITION",
  SET_PAGINATION = "Donations/SET_PAGINATION",
  SET_SORT = "Donations/SET_SORT",
}

export default ActionTypes;

export const POINT_LIMITS: Readonly<(number | undefined)[]> = [
  5000,
  10000,
  15000,
  20000,
  25000,
  30000,
  undefined,
];
