import { globalsActionsCreators } from "modules/Globals/GlobalsActions";
import { accessToken } from "containers/App/GlobalSelectors";
import { call, debounce, select, put } from "redux-saga/effects";
import request, {
  AsyncReturnType,
  requestHeadersAuthorized,
} from "utils/request";
import { donationsActionsCreators } from "./DonationsActions";
import actions from "./DonationsConstants";
import { isArray } from "util";
import { GetDonationsAction, GetDonationsResponse } from "./DonationsTypes";

export const donationsSaga = function* () {
  yield debounce(300, actions.GET_DONATIONS, getDonationsSaga);
};

export const getDonationsSaga = function* (action: GetDonationsAction) {
  try {
    yield put(globalsActionsCreators.setLoading(true));
    const responseAccessToken = yield select(accessToken);
    const donations: AsyncReturnType<typeof getDonations> = yield call(
      getDonations,
      responseAccessToken,
      action.payload.searchCondition,
      action.payload.pagination,
      action.payload.sort
    );
    yield put(donationsActionsCreators.setDonations(donations.data));
    yield put(globalsActionsCreators.setLoading(false));
  } catch (e) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};

const getDonations = async (
  responseAccessToken: string,
  searchCondition: GetDonationsAction["payload"]["searchCondition"],
  pagination: GetDonationsAction["payload"]["pagination"],
  sort: GetDonationsAction["payload"]["sort"]
) => {
  const q = Object.entries(searchCondition || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      if (isArray(value)) {
        return value.map((item) => `q[${key}][]=${encodeURIComponent(item)}`);
      } else {
        return `q[${key}]=` + (value || "");
      }
    })
    .join("&");

  const p = `pagination[limit]=${pagination?.limit}&pagination[page]=${pagination?.page}`;

  const s = `q[s][]=${sort?.type}%20${sort?.order}`;

  return request(
    process.env.REACT_APP_API_URL +
      "/donations" +
      (p.length !== 0 ? "?" + p : "") +
      (q.length !== 0 ? "&" + q : "") +
      ("&" + s),
    { method: "GET", ...requestHeadersAuthorized(responseAccessToken) }
  ) as Promise<GetDonationsResponse>;
};
