import actions from "./GlobalsConstants";
import { SetLoadingAction } from "./GlobalsTypes";

const setLoading = (isLoading: boolean): SetLoadingAction => {
  return {
    type: actions.SET_LOADING,
    payload: { isLoading },
  };
};

export const globalsActionsCreators = {
  setLoading,
};
