import {
  Thumbnail,
  ThumbnailProps,
} from "components/molecules/Thumbnail/Thumbnail";
import React from "react";
import { Card, Responsive } from "semantic-ui-react";

export type ThumbListItem = ThumbnailProps;

export interface ThumbListProps {
  items: ThumbListItem[];
  mobileMaxItems?: number;
  computerMaxItems?: number;
}

export const ThumbList: React.FC<ThumbListProps> = ({
  items,
  mobileMaxItems,
  computerMaxItems,
}) => {
  return (
    <>
      <Responsive
        maxWidth={768}
        as={Card.Group}
        itemsPerRow={2}
        style={{ marginTop: "1rem" }}
      >
        {(mobileMaxItems ? items.slice(0, mobileMaxItems) : items).map(
          ({ ...thubsProps }) => {
            return <Thumbnail key={thubsProps.key} {...thubsProps} />;
          }
        )}
      </Responsive>
      <Responsive
        minWidth={769}
        as={Card.Group}
        itemsPerRow={4}
        style={{ marginTop: "1rem" }}
      >
        {(computerMaxItems ? items.slice(0, computerMaxItems) : items).map(
          ({ ...thubsProps }) => {
            return <Thumbnail key={thubsProps.key} {...thubsProps} />;
          }
        )}
      </Responsive>
    </>
  );
};
