import transactions from "api/v1/Transactions";
import { push } from "connected-react-router";
import { accessToken as accessTokenSelector } from "containers/App/GlobalSelectors";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { AsyncReturnType } from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import transactionDetailActionCreator, {
  GetTransactionAction,
} from "./TransactionDetailAction";
import ActionTypes from "./TransactionDetailConstants";

const transactionDetailSaga = function* () {
  yield takeEvery(ActionTypes.GET_TRANSACTION, getTransactionSaga);
};

export default transactionDetailSaga;

const getTransactionSaga = function* ({
  payload: { id },
}: GetTransactionAction) {
  try {
    yield put(transactionDetailActionCreator.setLoading(true));
    const accessToken = (yield select(accessTokenSelector)) as string;
    const response = (yield call(
      transactions.get,
      accessToken,
      id
    )) as AsyncReturnType<typeof transactions.get>;
    yield put(transactionDetailActionCreator.setTransaction(response.data));
    yield put(transactionDetailActionCreator.setLoading(false));
  } catch (error) {
    yield requestErrorCheck(error);
    // dummy
    yield put(push("/error"));
  }
};
