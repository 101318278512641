import React, { ComponentProps } from "react";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { size } from "styles/Size";
import { TransactionDetailState } from "../TransactionDetailReducer";
import { ItemText } from "components/atoms/ItemText";
import { ItemLabel } from "components/atoms/ItemLabel";
import styled from "styled-components";

type DestinationInfoProps = TransactionDetailState;

const SubHeader = styled.h2`
  font-size: 1.2rem;
`;

export const DestinationInfo: React.FC<DestinationInfoProps> = ({
  transaction,
}) => {
  return transaction === undefined ? (
    <></>
  ) : (
    <FluidGrid id="DestinationInfoGrid">
      <Row>
        <PaddingColumn paddingleft={0}>
          <SubHeader id="DestinationInfoSubHeader">送付先情報</SubHeader>
        </PaddingColumn>
      </Row>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="ZipCodeLabel">郵便番号</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="ZipCode">{transaction.postal_code}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="PrefectureLabel">都道府県</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="Prefecture">{transaction.prefecture}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="CityLabel">市区町村</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="City">{transaction.city}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="Address1Label">番地</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="Address1">{transaction.address1}</ItemText>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemLabel id="Address2Label">建物名／部屋番号</ItemLabel>
        </PaddingColumn>
      </PaddingRow>
      <PaddingRow paddingtop={0}>
        <PaddingColumn paddingleft={0}>
          <ItemText id="Address2">
            {transaction.address2 ? transaction.address2 : "未設定"}
          </ItemText>
        </PaddingColumn>
      </PaddingRow>
      {transaction.donation.register_later && (
        <>
          <PaddingRow paddingtop={0}>
            <PaddingColumn paddingleft={0}>
              <ItemLabel id="delivery_dateLabel">配達希望日時</ItemLabel>
            </PaddingColumn>
          </PaddingRow>
          <PaddingRow paddingtop={0}>
            <PaddingColumn paddingleft={0}>
              <ItemText id="delivery_date">
                {transaction.delivery_date
                  ? transaction.delivery_date
                  : "未設定"}
              </ItemText>
            </PaddingColumn>
          </PaddingRow>
        </>
      )}
      {transaction.receiver_place_name && (
        <>
          <PaddingRow paddingtop={0}>
            <PaddingColumn paddingleft={0}>
              <ItemLabel id="ReceiverPlaceNameLabel">
                お届け先の場所の名前
              </ItemLabel>
            </PaddingColumn>
          </PaddingRow>
          <PaddingRow paddingtop={0}>
            <PaddingColumn paddingleft={0}>
              <ItemText id="ReceiverPlaceName">
                {transaction.receiver_place_name}
              </ItemText>
            </PaddingColumn>
          </PaddingRow>
        </>
      )}
    </FluidGrid>
  );
};

export default DestinationInfo;

const { Row, PaddingColumn, PaddingRow } = DefaultGrid;
const ResizeableGrid = size(padding(DefaultGrid));
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
