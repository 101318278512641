import { Reducer } from "redux";
import {
  DonationApplicationActionTypes,
  DonationApplicationState,
} from "./DonationApplicationTypes";
import ActionTypes, {
  donationApplicationInitialState,
} from "./DonationApplicationConstants";

const initialState = (): DonationApplicationState => ({
  initialValues: donationApplicationInitialState(),
});

const donationApplication: Reducer<
  DonationApplicationState,
  DonationApplicationActionTypes
> = (state = initialState(), action) => {
  switch (action.type) {
    case ActionTypes.CONFIRMATION:
      return {
        ...state,
        initialValues: action.payload.form,
      };
    default:
      return state;
  }
};

export default donationApplication;
