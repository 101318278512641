import { Reducer } from "redux";
import actions from "./ChildConstants";
import { ActionTypes, ChildState } from "./ChildTypes";
import { initialChildEditorState } from "./parts/ChildEditorType";

const childInitialState: ChildState = {
  id: undefined,
  child: initialChildEditorState(),
  success: false,
  loading: false,
  error: undefined,
};

const childReducer: Reducer<ChildState, ActionTypes> = (
  state = childInitialState,
  action
) => {
  switch (action.type) {
    case actions.CHANGE_CHILD:
      return {
        ...state,
        child: {
          ...state.child,
          ...action.payload.child,
        },
      };
    case actions.DELETE_CHILD:
    case actions.GET_CHILD:
      return {
        ...state,
        loading: true,
      };
    case actions.WRITE_CHILD:
      return {
        ...state,
        ...action.payload,
        loading: true,
      };
    case actions.GET_CHILD_SUCCESS:
      return {
        ...state,
        success: false,
        loading: false,
        child: action.payload.child,
      };
    case actions.CHILD_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case actions.CHILD_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default childReducer;
