import request from "utils/request";
import { requestHeadersAuthorized } from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import serialize from "serialize-javascript";

import { push } from "connected-react-router";
import { globalsActionsCreators } from "modules/Globals/GlobalsActions";
import { transactionsActionsCreators } from "modules/db/Transactions/TransactionsActions";
import { call, put, takeLatest, select } from "redux-saga/effects";
import ROUTES from "utils/routes";
import ActionTypes from "./DonationConfirmationConstants";
import { accessToken } from "containers/App/GlobalSelectors";

import { FixAction } from "./DonationConfirmationTypes";
import { ConfirmationValue } from "./DonationConfirmationTypes";

const donationConfirmationSaga = function* () {
  yield takeLatest(ActionTypes.FIX, fixSaga);
};

export const fixSaga = function* (params: FixAction) {
  try {
    yield put(globalsActionsCreators.setLoading(true));

    const requestURL = `${process.env.REACT_APP_API_URL}/transactions`;
    const responseAccessToken = yield select(accessToken);

    const confirmationValue: ConfirmationValue = {
      donation_id: parseInt(params.payload.donation_id),
      child_user_id: params.payload.initialValues.child_user_id,
      receiver_first_name: params.payload.initialValues.first_name,
      receiver_last_name: params.payload.initialValues.last_name,
      receiver_first_kana_name: params.payload.initialValues.first_kana_name,
      receiver_last_kana_name: params.payload.initialValues.last_kana_name,
      postal_code: params.payload.initialValues.postal_code,
      prefecture: params.payload.initialValues.prefecture,
      city: params.payload.initialValues.city,
      address1: params.payload.initialValues.address1,
      address2: params.payload.initialValues.address2,
      receiver_tel_number: params.payload.initialValues.tel_number,
      receiver_place_name: params.payload.initialValues.receiver_place_name,
      free_comment: params.payload.initialValues.free_comment,
    };

    yield call(request, requestURL, {
      method: "POST",
      ...requestHeadersAuthorized(responseAccessToken),
      body: serialize(confirmationValue),
    });

    yield alert("登録に成功しました");
    yield put(push(ROUTES.DONATION_APPLICATION_COMPLETE));
    yield put(globalsActionsCreators.setLoading(true));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(transactionsActionsCreators.getTransactionsError(error));
    yield put(globalsActionsCreators.setLoading(true));
  }
};

export default donationConfirmationSaga;
