import React, { useEffect } from "react";

import OrphanagesProfilePage from "./orphanage_user/index";
import ChildrenProfilePage from "./child_user/index";

const ProfilePage: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (sessionStorage.getItem("scope") === "orphanage_user") {
    return <OrphanagesProfilePage />;
  } else {
    return <ChildrenProfilePage />;
  }
};

export default ProfilePage;
