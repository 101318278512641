import actions from "./DonationApplicationConstants";
import {
  ConfirmationAction,
  DonationApplicationInputState,
} from "./DonationApplicationTypes";
import { DonationApplicationParams } from "./DonationApplication";

export const confirmation = (
  params: DonationApplicationParams,
  form: DonationApplicationInputState
): ConfirmationAction => {
  return {
    type: actions.CONFIRMATION,
    payload: {
      params,
      form,
    },
  };
};

export const donationApplicationActionsCreators = {
  confirmation,
};
