import actions from "./ChildConstants";

import {
  GetChildAction,
  DeleteChildAction,
  GetChildSuccessAction,
  ChildAction,
  ChildSuccessAction,
  ChildErrorAction,
  ChangeChildAction,
} from "./ChildTypes";

const changeChild = (
  child: ChangeChildAction["payload"]["child"]
): ChangeChildAction => {
  return {
    type: actions.CHANGE_CHILD,
    payload: {
      child,
    },
  };
};

const getChild = (id: GetChildAction["payload"]["id"]): GetChildAction => {
  return {
    type: actions.GET_CHILD,
    payload: {
      id,
    },
  };
};

const getChildSuccess = (
  child: GetChildSuccessAction["payload"]["child"]
): GetChildSuccessAction => {
  return {
    type: actions.GET_CHILD_SUCCESS,
    payload: {
      child,
    },
  };
};

const writeChild = (
  id: ChildAction["payload"]["id"],
  child: ChildAction["payload"]["child"]
): ChildAction => {
  return {
    type: actions.WRITE_CHILD,
    payload: {
      id,
      child,
    },
  };
};

const deleteChild = (id: number): DeleteChildAction => {
  return {
    type: actions.DELETE_CHILD,
    payload: {
      id,
    },
  };
};

const childSuccess = (): ChildSuccessAction => {
  return {
    type: actions.CHILD_SUCCESS,
  };
};

const childError = (error: Error): ChildErrorAction => {
  return {
    type: actions.CHILD_ERROR,
    payload: {
      error,
    },
  };
};

const childCreators = {
  changeChild,
  getChild,
  getChildSuccess,
  writeChild,
  deleteChild,
  childSuccess,
  childError,
};

export default childCreators;
