import React, { ComponentProps, useEffect, useMemo } from "react";

import { Form, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { Orphanage } from "./orphanageTypes";
import OrphanageCreators from "./orphanageActions";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { State } from "interfaces/State";

import InfoMessage from "components/atoms/InfoMessage";

import DefaultGrid from "styles/DefaultGrid";
import MainHeader from "components/atoms/MainHeader";
import padding from "styles/Padding";
import { ItemText } from "components/atoms/ItemText";

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const StyledLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
`;

const TextStyled = styled(PaddingColumn)`
  text-align: left;
`;

const PaddingGrid = padding(DefaultGrid);

interface RouterProps {
  id: string;
}

const OrphanagesEditor: React.FC = () => {
  const {
    orphanage: orphanageData,
    message,
    error: orphanageError,
  } = useSelector((state: State) => state.orphanage);
  const Data: Orphanage = {
    category: "",
    address1: orphanageData.address1 || "",
    address2: orphanageData.address2 || "",
    city: orphanageData.city || "",
    name: orphanageData.name || "",
    postal_code: orphanageData.postal_code || "",
    prefecture: orphanageData.prefecture || "",
    tel_number: orphanageData.tel_number || "",
  };

  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      orphanage: bindActionCreators(OrphanageCreators, dispatch),
    };
  }, [dispatch]);

  useEffect(() => {
    actions.orphanage.getOrphanage();
  }, [actions.orphanage]);

  return (
    <PaddingGrid id="OrphanageEditor" paddingbottom={30}>
      <Row id="HeaderRow">
        <Column width={16} style={{ paddingBottom: 16 }}>
          <MainHeader>施設情報</MainHeader>
        </Column>
      </Row>

      {message && (
        <DefaultGrid.Row centered>
          <DefaultGrid.Column textAlign={"center"}>
            <InfoMessage severity="success" message={message as string} />
          </DefaultGrid.Column>
        </DefaultGrid.Row>
      )}

      {orphanageError && (
        <DefaultGrid.Row centered>
          <DefaultGrid.Column textAlign={"center"}>
            <InfoMessage
              severity="error"
              message={orphanageError?.message as string}
            />
          </DefaultGrid.Column>
        </DefaultGrid.Row>
      )}

      <Form>
        <Row id="InputRow">
          <DefaultGrid>
            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="name">施設名</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.name}</ItemText>
              </TextStyled>
            </PaddingRow>

            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="postal_code">郵便番号</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.postal_code}</ItemText>
              </TextStyled>
            </PaddingRow>

            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="prefecture">都道府県</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.prefecture}</ItemText>
              </TextStyled>
            </PaddingRow>
            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="city">市区町村</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.city}</ItemText>
              </TextStyled>
            </PaddingRow>
            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="address1">番地</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.address1}</ItemText>
              </TextStyled>
            </PaddingRow>
            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="address2">建物名／部屋番号</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.address2 ? Data.address2 : "未設定"}</ItemText>
              </TextStyled>
            </PaddingRow>
            <LabelRow>
              <LabelColumn width={16}>
                <StyledLabel htmlFor="tel_number">電話番号</StyledLabel>
              </LabelColumn>
            </LabelRow>
            <PaddingRow columns="equal">
              <TextStyled>
                <ItemText>{Data.tel_number}</ItemText>
              </TextStyled>
            </PaddingRow>
          </DefaultGrid>
        </Row>
        <Segment>
          施設情報の編集に関してのご連絡は
          <br />
          todokun@b4s.jpまでお送りください。
        </Segment>
      </Form>
    </PaddingGrid>
  );
};

export default OrphanagesEditor;

const LabelColumn = styled(Column)`
  text-align: left;
`;
