import SearchBar from "components/atoms/SearchBar";
import { State } from "interfaces/State";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import {
  AccountNoticed,
  ChildrenListState,
} from "modules/db/ChildrenList/ChildrenListTypes";
import DesignedButton from "components/atoms/DesignedButton";
import { BREAKPOINTS } from "utils/ComponentUtils";

const StyledSearchBar = styled(SearchBar)`
  &&& {
    width: 351px;
    position: absolute;
    right: 0;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      width: 100%;
    }
  }
`;

const StyledColumn = styled(DefaultGrid.PaddingColumn)`
  &&& {
    display: contents !important;
  }
`;

const Controlls: React.FC = () => {
  const { accountNoticed } = useSelector<State, ChildrenListState>(
    (s) => s.childrenList
  );
  const dispatch = useDispatch();
  const actions = bindActionCreators(childrenListActionsCreators, dispatch);

  return (
    <DefaultGrid id="Controlls">
      <DefaultGrid.PaddingRow id="SearchRow" paddingtop={4}>
        <DefaultGrid.PaddingColumn id="SearchColumn" paddingbottom={0}>
          <StyledSearchBar
            id="Keyword"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              actions.changeValues({ searchWord: e.target.value });
            }}
          />
          <KeepHeight height={50} />
        </DefaultGrid.PaddingColumn>
      </DefaultGrid.PaddingRow>
      <DefaultGrid.PaddingRow id="ButtonsRow" columns="equal" padding={0}>
        {BUTTON_LIST.map(({ key, label }) => {
          return (
            <StyledColumn key={key} id={key + "Column"}>
              <DesignedButton
                id={key}
                design="secondary"
                inverted={accountNoticed !== key}
                paddingleft="0.5rem"
                paddingright="0.5rem"
                onClick={() => {
                  actions.changeValues({ accountNoticed: key, activePage: 1 });
                }}
              >
                {label}
              </DesignedButton>
            </StyledColumn>
          );
        })}
      </DefaultGrid.PaddingRow>
    </DefaultGrid>
  );
};

export default Controlls;

interface KeepHeightProps {
  height: number;
}

const KeepHeight: React.FC<KeepHeightProps> = ({ height }) => {
  return <div style={{ height }} />;
};

const BUTTON_LIST = [
  {
    key: AccountNoticed.ALL,
    label: "全て",
  },
  {
    key: AccountNoticed.NOT_NOTICED,
    label: "アカウント未通知",
  },
  {
    key: AccountNoticed.NOTICED,
    label: "通知済み",
  },
];
