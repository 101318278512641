import React from "react";
import styled from "styled-components";

import { withHeaderAndFooter } from "components/organisms/Header";
import { RouteComponentProps, withRouter } from "react-router";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import OrphanagesEditor from "./orphanage/OrphanageEditor";
import UserEditor from "./orphanage_user/UserEditor";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 375px;
`;
interface RouterProps {
  id: string;
}

const OrphanagesProfilePage: React.FC<RouteComponentProps<RouterProps>> = (
  props
) => {
  return (
    <PaddingGrid paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>

      <ContentsRow id="Contents">
        <PaddingGrid padding={0}>
          <DefaultGrid.PaddingRow padding={0} centered={false}>
            <DefaultGrid.Column id="InputColumn" width={16}>
              <OrphanagesEditor {...props} />
              <UserEditor {...props} />
            </DefaultGrid.Column>
          </DefaultGrid.PaddingRow>
        </PaddingGrid>
      </ContentsRow>
    </PaddingGrid>
  );
};

export default withRouter(withHeaderAndFooter(OrphanagesProfilePage));
