import { accessToken } from "containers/App/GlobalSelectors";
import { globalsActionsCreators } from "modules/Globals/GlobalsActions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { isArray } from "util";
import request, {
  AsyncReturnType,
  requestHeadersAuthorized,
} from "utils/request";
import requestErrorCheck from "utils/requestErrorCheck";
import { transactionsActionsCreators } from "./TransactionsActions";
import Actions from "./TransactionsConstants";
import {
  GetTransactionsAction,
  CompleteTransactionAction,
  GetTransactionsResponse,
} from "./TransactionsTypes";
import serialize from "serialize-javascript";
import { push } from "connected-react-router";
import ROUTES from "utils/routes";

export const getTransactionsSaga = function* (Action: GetTransactionsAction) {
  try {
    yield put(globalsActionsCreators.setLoading(true));
    const responseAccessToken = yield select(accessToken);
    const transactions: AsyncReturnType<typeof getTransactions> = yield call(
      getTransactions,
      responseAccessToken,
      Action.payload.pagination,
      Action.payload.searchCondition
    );
    yield put(
      transactionsActionsCreators.getTransactionsSuccess(transactions.data)
    );
    yield put(globalsActionsCreators.setLoading(false));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(transactionsActionsCreators.getTransactionsError(error));
  }
};

const getTransactions = async (
  responseAccessToken: string,
  pagination: GetTransactionsAction["payload"]["pagination"],
  searchCondition?: GetTransactionsAction["payload"]["searchCondition"]
) => {
  const q = Object.entries(searchCondition || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      if (isArray(value)) {
        return value
          .map((item) => `q[${key}][]=${encodeURIComponent(item)}`)
          .join("&");
      } else {
        return `q[${key}]=` + (value || "");
      }
    })
    .join("&");

  const page = Object.entries(pagination || {})
    .filter((entry) => entry[1] !== undefined) // 値がないものを除去
    .map(function ([key, value]) {
      return `pagination[${key}]=` + (value || "");
    })
    .join("&");

  return request(
    process.env.REACT_APP_API_URL +
      "/transactions" +
      (page.length !== 0 ? "?" + page : "") +
      (q.length !== 0 ? "&" + q : ""),
    { method: "GET", ...requestHeadersAuthorized(responseAccessToken) }
  ) as Promise<GetTransactionsResponse>;
};

const completeTransactionSaga = function* (Action: CompleteTransactionAction) {
  const requestURL = `${process.env.REACT_APP_API_URL}/transactions/${Action.payload.id}/receive`;
  try {
    yield put(globalsActionsCreators.setLoading(true));
    const responseAccessToken = yield select(accessToken);
    yield call(request, requestURL, {
      method: "PATCH",
      ...requestHeadersAuthorized(responseAccessToken),
      body: serialize({
        donation_application: {
          receiver_message: Action.payload.receiver_message,
        },
      }),
    });
    yield alert("受取を確認しました");
    yield put(push(ROUTES.TRANSACTIONS));
    yield put(globalsActionsCreators.setLoading(false));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(transactionsActionsCreators.getTransactionsError(error));
  }
};

export default function* transactionsSaga() {
  yield takeLatest(Actions.GET_TRANSACTIONS, getTransactionsSaga);
  yield takeLatest(Actions.COMPLETE_TRANSACTION, completeTransactionSaga);
}
