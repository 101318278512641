import React, { CSSProperties } from "react";
import styled from "styled-components";
import { px } from "./styleUtil";

type SizeProps = {
  size: Partial<
    Pick<
      CSSProperties,
      "maxWidth" | "minWidth" | "width" | "maxHeight" | "minHeight" | "height"
    >
  >;
};

export const size = function <T>(Component: React.ComponentType<T>) {
  return styled(({ size, ...props }: T & Partial<SizeProps>) => {
    return (
      <Component
        data-max-width={size?.maxWidth}
        data-min-width={size?.minWidth}
        data-width={size?.width}
        data-max-height={size?.maxHeight}
        data-min-height={size?.minHeight}
        data-height={size?.height}
        {...(props as T)}
      />
    );
  })`
    &&& {
      max-width: ${(props) =>
        props.size?.maxWidth && px(props.size.maxWidth, true)};
      min-width: ${(props) =>
        props.size?.minWidth && px(props.size.minWidth, true)};
      width: ${(props) => props.size?.width && px(props.size.width, true)};
      max-height: ${(props) =>
        props.size?.maxHeight && px(props.size.maxHeight, true)};
      min-height: ${(props) =>
        props.size?.minHeight && px(props.size.minHeight, true)};
      height: ${(props) => props.size?.height && px(props.size.height, true)};
    }
  `;
};
