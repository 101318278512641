import { Action } from "redux";
import Actions from "./GlobalConstants";

export type ActionTypes =
  | LogInAction
  | LogInSuccessAction
  | LogInErrorAction
  | LogOutAction
  | LogOutSuccessAction
  | LogOutErrorAction;

export enum SCOPE {
  ORPHANAGE = "orphanage_user",
  CHILD = "child_user",
}

export interface Login {
  email: string;
  password: string;
  scope?: SCOPE;
}

export type Scopes = SCOPE | undefined;

export interface LogInState {
  accessToken: string;
  userName: string;
  scope: Scopes;
  id: number;
  isLoading: boolean;
  loggedIn: boolean;
  error: boolean | Error;
}

export interface LogInAction extends Action {
  type: typeof Actions.LOGIN;
  payload: {
    grantType: string;
    email: string;
    password: string;
    scope: SCOPE;
  };
}

export interface LogInSuccessAction extends Action {
  type: typeof Actions.LOGIN_SUCCESS;
  payload: {
    accessToken: string;
    userName: string;
    scope: SCOPE;
    id: number;
  };
}

export interface LogInErrorAction extends Action {
  type: typeof Actions.LOGIN_ERROR;
  payload: {
    error: Error;
  };
}

export interface LogOutAction extends Action {
  type: typeof Actions.LOGOUT;
}

export interface LogOutSuccessAction extends Action {
  type: typeof Actions.LOGOUT_SUCCESS;
}

export interface LogOutErrorAction extends Action {
  type: typeof Actions.LOGOUT_ERROR;
  payload: {
    error: Error;
  };
}
