import Logo from "images/アセット17@2x.png";
import React from "react";
import { Grid, GridColumnProps, Image } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { px } from "styles/styleUtil";
import { EXTRANAL_ROUTES } from "utils/routes";

import { BREAKPOINTS } from "utils/ComponentUtils";

export const FOOTER_HEIGHT_DESKTOP = 270;
export const FOOTER_HEIGHT_MOBILE = 300;

const FooterWrapper = styled(Grid)`
  height: ${px(FOOTER_HEIGHT_MOBILE)};
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    height: ${px(FOOTER_HEIGHT_DESKTOP)};
  }
  background-color: #6cb4b1;
  &&&.ui.grid {
    margin-left: 0;
    margin-right: 0;
  }
`;

const SquareLogo = styled(Image)`
  height: 76px;
  width: 65px;
`;

const LogoGridRow = styled(Grid.Row)`
  &&& {
    margin-top: 1.5em;
    padding: 0;
  }
`;

const FooterAnchor = styled.a`
  color: white;
  height: 25px;
  justify-content: center;
  flex-direction: column;
`;

interface FooterLinkProps {
  href: string;
  description: string;
}

const FooterLink: React.FC<FooterLinkProps & GridColumnProps> = ({
  href,
  description,
  ...props
}) => {
  return (
    <Grid.Column
      {...props}
      computer={4}
      mobile={16}
      textAlign="center"
      style={{ padding: "3px 0" }}
    >
      <FooterAnchor href={href} target="_blank" rel="noopener noreferrer">
        {description}
      </FooterAnchor>
    </Grid.Column>
  );
};

const FooterLine = styled.hr`
  width: 90%;
`;

const CompanyInfo = styled.div`
  color: white;
  font-size: 12px;
  margin: 0 auto;
`;

export const Footer: React.FC = () => {
  return (
    <FooterWrapper id="Footer">
      <LogoGridRow id="FooterLogo">
        <SquareLogo src={Logo} alt="トップ画像" centered />
      </LogoGridRow>
      <DefaultGrid.PaddingRow id="FooterLinks" padding={0} centered>
        <DefaultGrid style={{ width: "65%" }}>
          <DefaultGrid.Row width={10}>
            <FooterLink
              id="About"
              href={EXTRANAL_ROUTES.ABOUT_TODOKUN}
              description={"トドクンとは"}
            />
            <FooterLink
              id="Faq"
              href={EXTRANAL_ROUTES.FAQ}
              description={"よくある質問"}
            />
            <FooterLink
              id="Tos"
              href={EXTRANAL_ROUTES.TOS}
              description={"利用規約"}
            />
            <FooterLink
              id="PrivacyPolicy"
              href={EXTRANAL_ROUTES.PRIVACY_POLICY}
              description={"ブライバシーポリシー"}
            />
          </DefaultGrid.Row>
        </DefaultGrid>
      </DefaultGrid.PaddingRow>
      <FooterLine />
      <DefaultGrid.Row id="FooterCompanyName">
        <CompanyInfo>©︎認定NPO法人ブリッジフォースマイル, 2020</CompanyInfo>
      </DefaultGrid.Row>
    </FooterWrapper>
  );
};
