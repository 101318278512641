import {
  SetPaginationAction,
  ChildrenListPagination,
} from "./ChildrenListTypes";
import Actions from "./ChildrenListConstants";

const setPagination = (
  pagination: ChildrenListPagination
): SetPaginationAction => {
  return {
    type: Actions.SET_PAGINATION,
    payload: {
      pagination,
    },
  };
};

export const childrenListActionsCreators = {
  setPagination,
};
