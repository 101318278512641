import actions from "./addressConstants";
import {
  GetAddressAction,
  GetAddressSuccessAction,
  GetAddressErrorAction,
} from "./addressTypes";

const getAddress = (zipcode: string | number): GetAddressAction => {
  return {
    type: actions.GET_ADDRESS,
    payload: {
      zipcode,
    },
  };
};

const getAddressSuccess = (
  prefecture: string,
  city: string,
  area: string
): GetAddressSuccessAction => {
  return {
    type: actions.GET_ADDRESS_SUCCESS,
    payload: { prefecture, city, area },
  };
};

const getAddressError = (error: Error): GetAddressErrorAction => {
  return {
    type: actions.GET_ADDRESS_ERROR,
    payload: {
      error,
    },
  };
};

const ZipcodeActionsCreators = {
  getAddress,
  getAddressSuccess,
  getAddressError,
};

export default ZipcodeActionsCreators;
