import {
  GetChildrenListAction,
  GetChildrenListSuccessAction,
  GetChildrenListErrorAction,
  ChildrenListState,
  ChangeValuesAction,
  SendResetPasswordEmail,
  ChildrenListPagination,
} from "./ChildrenListTypes";
import Actions from "./ChildrenListConstants";

export const childrenListActionsCreators = {
  changeValues: (newState: Partial<ChildrenListState>): ChangeValuesAction => ({
    type: Actions.CHANGE_VALUES,
    payload: newState,
  }),
  getChildrenList: (
    pagination: ChildrenListPagination
  ): GetChildrenListAction => ({
    type: Actions.GET_CHILDREN_LIST,
    payload: {
      pagination,
    },
  }),
  getChildrenListSuccess: (
    children: ChildrenListState["children"],
    total_pages: number
  ): GetChildrenListSuccessAction => ({
    type: Actions.GET_CHILDREN_LIST_SUCCESS,
    payload: {
      children,
      total_pages,
    },
  }),
  getChildrenListError: (error: Error): GetChildrenListErrorAction => ({
    type: Actions.GET_CHILDREN_LIST_ERROR,
    payload: { error },
  }),
  sendResetPasswordEmail: (email: string): SendResetPasswordEmail => ({
    type: Actions.SEND_RESET_PASSWORD_EMAIL,
    payload: { email },
  }),
};
