import DesignedButton from "components/atoms/DesignedButton";
import React, { ComponentProps } from "react";
import { NavLink, generatePath } from "react-router-dom";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { size } from "styles/Size";
import ROUTES from "utils/routes";

interface DestinationInputButtonProps {
  id: string;
}
const DestinationInputButton: React.FC<DestinationInputButtonProps> = ({
  id,
}) => {
  return (
    <FluidGrid id="DestinationInfoGrid">
      <Row>
        <Column width={16} textAlign="center">
          <WarnSpan>送付先住所が未確定です</WarnSpan>
        </Column>
      </Row>
      <Row>
        <Column width={16} textAlign="center">
          <NavLink to={generatePath(ROUTES.TRANSACTIONS_EDIT, { id })}>
            <ResizeableButton
              paddingtop={20}
              paddingbottom={20}
              size={{ width: "100%", maxWidth: "255px" }}
            >
              <ButtonText>送付先を入力</ButtonText>
            </ResizeableButton>
          </NavLink>
        </Column>
      </Row>
    </FluidGrid>
  );
};

export default DestinationInputButton;

const { Row, Column } = DefaultGrid;
const ResizeableGrid = size(padding(DefaultGrid));
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
const WarnSpan = styled.span`
  color: red;
  font-size: 14px;
  font-weight: bold;
`;

const ButtonText = styled.span`
  font-size: 20px;
  font-weight: bold;
`;
const ResizeableButton = size(DesignedButton);
