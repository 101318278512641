import LoginButton from "components/atoms/LoginButton";
import { globalActionsCreators } from "containers/App/GlobalActions";
import { LogInState } from "containers/App/GlobalTypes";
import Logo from "images/charactor_logo.png";
import { State } from "interfaces/State";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Icon, Image, Menu } from "semantic-ui-react";
import styled from "styled-components";
import { px } from "styles/styleUtil";
import ROUTES from "utils/routes";
import { COLORS, getDisplayName } from "../../utils/ComponentUtils";
import { Footer, FOOTER_HEIGHT_DESKTOP, FOOTER_HEIGHT_MOBILE } from "./Footer";
import { BREAKPOINTS } from "utils/ComponentUtils";
import { Link } from "react-router-dom";

const HEADER_HEIGHT = 50;

const MainDiv = styled.div`
  min-height: calc(100vh - ${px(HEADER_HEIGHT + FOOTER_HEIGHT_MOBILE)});
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    min-height: calc(100vh - ${px(HEADER_HEIGHT + FOOTER_HEIGHT_DESKTOP)});
  }
  margin-bottom: 100px;
`;

const HeaderWrapper = styled(Menu)`
  height: ${px(HEADER_HEIGHT)};
  width: 100vw;
  &&& {
    & .ui.dropdown .menu > .item {
      font-size: 14px !important;
      @media (min-width: ${BREAKPOINTS.TABLET}) {
        font-size: 18px !important;
      }
    }
  }
`;

const SideDropdownMenu = styled(Dropdown.Menu)`
  top: 0 !important;
  width: 200px;
  @media (min-width: ${BREAKPOINTS.TABLET}) {
    width: 270px;
  }
`;

const Name = styled.p`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const NameWrap = styled.div`
  @media (max-width: ${BREAKPOINTS.MOBILE}) {
    max-width: 180px;
  }
`;

const GreenIcon = styled(Icon)`
  color: ${COLORS.PRIMARY};
`;

const Header: React.FC = () => {
  const loggedIn = useSelector<State>((state) => state.logIn.loggedIn);
  const { userName } = useSelector<State, LogInState>((state) => state.logIn);

  return (
    <HeaderWrapper id="header" borderless style={{ marginBottom: 0 }} fluid>
      {loggedIn && <BurgerIconDropdown />}
      <Image
        src={Logo}
        alt="トドクん"
        as={Link}
        to={ROUTES.TOP}
        width={130}
        style={{
          margin: "auto 0 auto 1rem",
        }}
      />
      <Menu.Menu position="right">
        {!loggedIn ? (
          <Menu.Item>
            <LoginButton />
          </Menu.Item>
        ) : (
          <NameWrap>
            <Menu.Item>
              <Name>{`${userName}さん`}</Name>
            </Menu.Item>
          </NameWrap>
        )}
      </Menu.Menu>
    </HeaderWrapper>
  );
};

export function withHeaderAndFooter<P>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> {
  const withHeaderAndFooter: React.FC<P> = (props: P) => {
    return (
      <div>
        <Header />
        <MainDiv id="main">
          <WrappedComponent {...props} />
        </MainDiv>
        <Footer />
      </div>
    );
  };
  withHeaderAndFooter.displayName = `withHeaderAndFooter(${getDisplayName(
    WrappedComponent
  )})`;
  return withHeaderAndFooter;
}

const BurgerIconDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);

  const handleLogOut = () => {
    dispatch(globalActionsCreators.logOut());
    alert(`ログアウトしました`);
    window.location.reload();
  };

  return (
    <Dropdown
      item
      icon={<GreenIcon name="bars" />}
      style={{ padding: "0 0 0 10px" }}
    >
      <SideDropdownMenu>
        <Dropdown.Item as={Link} to={ROUTES.DONATIONS}>
          寄付一覧
        </Dropdown.Item>
        {scope === "orphanage_user" && (
          <Dropdown.Item as={Link} to={ROUTES.CHILDREN_LIST}>
            子ども一覧
          </Dropdown.Item>
        )}
        <Dropdown.Item as={Link} to={ROUTES.LIKES}>
          お気に入りリスト
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={ROUTES.TRANSACTIONS}>
          申込履歴
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={ROUTES.SETTINGS}>
          設定
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogOut}>ログアウト</Dropdown.Item>
      </SideDropdownMenu>
    </Dropdown>
  );
};
