import { Reducer } from "redux";
import actions from "./DonationsConstants";
import { DonationsState, DonationsActionTypes } from "./DonationsTypes";

const initialState = (): DonationsState => ({
  data: {
    donations: [],
    total_num: 0,
    total_pages: 0,
  },
});

const donations: Reducer<DonationsState, DonationsActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case actions.SET_DONATIONS:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default donations;
