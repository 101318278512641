import DesignedButtonPager from "components/organisms/DesignedButtonPager";
import React, { useMemo } from "react";
import DefaultGrid from "styles/DefaultGrid";
import {
  ChildrenListState,
  AccountNoticed,
} from "modules/db/ChildrenList/ChildrenListTypes";
import ChildrenCard from "../ChildrenCard";
import { bindActionCreators } from "redux";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import { childrenListActionsCreators as childrenListPageActionsCreators } from "../../ChildrenListActions";
import { useDispatch, useSelector } from "react-redux";
import { State } from "interfaces/State";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";

const CenteredColumn = styled(DefaultGrid.PaddingColumn)`
  &&& {
    display: contents !important;
  }
`;

const FluidGrid = styled(DefaultGrid)`
  &&&& {
    width: 100% !important;
  }
`;

const ChildrenList: React.FC = () => {
  const {
    children: data,
    total_pages,
    accountNoticed,
    isLoading,
    searchWord,
  } = useSelector<State, ChildrenListState>((s) => s.childrenList);
  const { pagination } = useSelector((state: State) => state.childrenListPage);
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      childrenList: bindActionCreators(childrenListActionsCreators, dispatch),
      pagination: bindActionCreators(childrenListPageActionsCreators, dispatch),
    };
  }, [dispatch]);

  const filteredData = searchWord
    ? childNameFilter(data, searchWord)
    : accountNoticedFilter(data, accountNoticed);

  return (
    <DefaultGrid id="ChildrenListContainer">
      <DefaultGrid.Row>
        <FluidGrid id="ChildrenListBody">
          {isLoading ? (
            <Loader />
          ) : filteredData === undefined || filteredData.length === 0 ? (
            "対象となるデータがありません"
          ) : (
            <ChildrenRows data={filteredData} />
          )}
        </FluidGrid>
      </DefaultGrid.Row>
      <DefaultGrid.PaddingRow centered padding={0}>
        <CenteredColumn padding={0}>
          <DesignedButtonPager
            activePage={pagination.page}
            totalPages={total_pages}
            onPageChange={(e, { activePage }) => {
              if (activePage) {
                dispatch(
                  actions.pagination.setPagination({
                    ...pagination,
                    page: +activePage,
                  })
                );
                window.scroll(0, 0); //表示位置をページ冒頭に戻す
              }
              actions.childrenList.changeValues({
                activePage: isNaN(Number(activePage)) ? 1 : Number(activePage),
              });
            }}
          />
        </CenteredColumn>
      </DefaultGrid.PaddingRow>
    </DefaultGrid>
  );
};
export default ChildrenList;

interface ChildrenCardsProps {
  data: ChildrenListState["children"];
}
const ChildrenRows: React.FC<ChildrenCardsProps> = ({ data }) => {
  return (
    <>
      {data?.map((childUser) => (
        <DefaultGrid.PaddingRow key={childUser.id} paddingtop={0}>
          <DefaultGrid.PaddingColumn width={16} padding={0}>
            <ChildrenCard {...childUser} />
          </DefaultGrid.PaddingColumn>
        </DefaultGrid.PaddingRow>
      ))}
    </>
  );
};

const accountNoticedFilter = (
  data?: ChildUser[],
  accountNoticed?: ChildrenListState["accountNoticed"]
) =>
  data?.filter((childUser) => {
    switch (accountNoticed) {
      case AccountNoticed.NOTICED:
        return childUser.account_notified_at !== null;
      case AccountNoticed.NOT_NOTICED:
        return childUser.account_notified_at === null;
      default:
        return true;
    }
  });

const childNameFilter = (data?: ChildUser[], searchWord?: string) =>
  data?.filter((childUser) => {
    const childUserName = `${childUser.last_name}${childUser.first_name}`;
    if (searchWord && childUserName.includes(searchWord)) {
      return true;
    }
  });
