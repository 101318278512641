import { Reducer } from "redux";
import { TopActionTypes } from "./TopActions";

export type TopState = {};

const initialState = (): TopState => ({});

export const top: Reducer<TopState, TopActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
