import React from "react";
import { generatePath, NavLink, RouteComponentProps } from "react-router-dom";
import { Breadcrumb, BreadcrumbSectionProps } from "semantic-ui-react";
import ROUTES from "utils/routes";
import BREADCRUMB_MAP from "./BreadcrumbMap";

const SPLITTER = /\/(?!:)/g;
const SEPARATER = "/";

interface SectionsItem extends BreadcrumbSectionProps {
  key: React.Key;
}

const SECTION_TOP: SectionsItem = {
  key: ROUTES.TOP,
  as: NavLink,
  to: ROUTES.TOP,
  active: true,
  content: BREADCRUMB_MAP[ROUTES.TOP],
};

const CustomBreadcrumbs = <P extends RouteComponentProps>(props: P) => {
  const path = props.match.path;

  const sections: SectionsItem[] = [
    SECTION_TOP,
    ...path
      .split(SPLITTER)
      .filter((x) => x && x !== "top") // ブランクと"top"を消す
      .reduce((routeList, currentSlug, idx) => {
        if (idx === 0) {
          return [SEPARATER + currentSlug];
        } else {
          return [...routeList, routeList[idx - 1] + SEPARATER + currentSlug];
        }
      }, [] as string[]) // ルートからのパスに加工する
      .map((route) => {
        const path = generatePath(route, props.match.params);
        return {
          key: path,
          content: BREADCRUMB_MAP[route],
          as: NavLink,
          to: path,
        };
      }), // Linkのリストを返す
  ];
  return <Breadcrumb icon="right angle" sections={sections} />;
};

export default CustomBreadcrumbs;
