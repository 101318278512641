import { put } from "redux-saga/effects";
import { globalActionsCreators } from "containers/App/GlobalActions";
import { ResponseError } from "./request";
import ERROR_STATUS_CODES from "utils/errorStatusCodes";

function* requestErrorCheck(error: ResponseError) {
  if (
    error.response &&
    (error.response.status === ERROR_STATUS_CODES.UNAUTHORIZED ||
      error.response.status === ERROR_STATUS_CODES.SERVER_ERROR ||
      error.response.status === ERROR_STATUS_CODES.SERVICE_UNAVAILABLE)
  ) {
    yield put(globalActionsCreators.logOut());
  }
}
export default requestErrorCheck;
