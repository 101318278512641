import { call, put, takeLatest, select } from "redux-saga/effects";

import actions from "./orphanageConstants";
import OrphanageActionsCreators from "./orphanageActions";

import requestErrorCheck from "utils/requestErrorCheck";
import { accessToken } from "containers/App/GlobalSelectors";

import request from "utils/request";
import { requestHeadersAuthorized } from "utils/request";

function* getOrphanageSaga() {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/orphanage`;
    let responseAccessToken = yield select(accessToken);
    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }
    const orphanage = yield call(request, requestURL, {
      method: "GET",
      ...requestHeadersAuthorized(responseAccessToken),
    });
    yield put(OrphanageActionsCreators.getOrphanageSuccess(orphanage.data));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(OrphanageActionsCreators.OrphanageError(error));
  }
}

export default function* registerRootSaga() {
  yield takeLatest(actions.GET_ORPHANAGE, getOrphanageSaga);
}
