import { logInSaga } from "containers/App/GlobalSaga";
import zipcodeSaga from "modules/Address/addressSaga";
import { categoriesSaga } from "modules/db/Categories/CategoriesSaga";
import { donationsSaga } from "modules/db/Donations/DonationsSaga";
import { donationSaga } from "modules/db/Donation/DonationSaga";
import transactionsSaga from "modules/db/Transactions/TransactionsSaga";
import changePasswordSaga from "pages/ChangePassword/ChangePasswordSaga";
import donationApplicationSaga from "pages/Donations/Application/DonationApplicationSaga";
import donationConfirmationSaga from "pages/Donations/Confirmation/DonationConfirmationSaga";
import donationsPageSaga from "pages/Donations/List/DonationsSaga";
import changeEmailSaga from "pages/MyPage/ChangeEmail/ChangeEmailSaga";
import addChildSaga from "pages/MyPage/Child/ChildSaga";
import childrenListSaga from "modules/db/ChildrenList/ChildrenListSaga";
import deleteAccountSaga from "pages/MyPage/DeleteAccount/DeleteAccountSaga";
import profileChildSaga from "pages/MyPage/Profile/child_user/profileChildSaga";
import orphanageSaga from "pages/MyPage/Profile/orphanage_user/orphanage/orphanageSaga";
import profileSaga from "pages/MyPage/Profile/orphanage_user/orphanage_user/profileSaga";
import transactionDetailSaga from "pages/MyPage/Transactions/TransactionDetail/TransactionDetailSaga";
import transactionEditSaga from "pages/MyPage/Transactions/TransactionEdit/TransactionEditSaga";
import transactionsPageSaga from "pages/MyPage/Transactions/TransactionsSaga";
import personalIdentificationSaga from "pages/PersonalIdentification/PersonalIdentificationSaga";
import requestChangePasswordSaga from "pages/RequestChangePassword/RequestChangePasswordSaga";
import { topSaga } from "pages/Top/TopSaga";
import { all, fork } from "redux-saga/effects";
import LikeSaga from "modules/db/Like/LikeSaga";
import LikesSaga from "modules/db/Likes/LikesSaga";

export const rootSaga = function* () {
  yield all(
    [
      topSaga,
      logInSaga,
      donationsPageSaga,
      donationApplicationSaga,
      donationConfirmationSaga,
      zipcodeSaga,
      transactionsSaga,
      transactionsPageSaga,
      transactionDetailSaga,
      transactionEditSaga,
      childrenListSaga,
      addChildSaga,
      requestChangePasswordSaga,
      changePasswordSaga,
      changeEmailSaga,
      profileSaga,
      profileChildSaga,
      deleteAccountSaga,
      orphanageSaga,
      donationsSaga,
      donationSaga,
      categoriesSaga,
      personalIdentificationSaga,
      LikeSaga,
      LikesSaga,
    ].map((saga) => fork(saga))
  );
};
