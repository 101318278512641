import { call, put, takeLatest, select } from "redux-saga/effects";

import { UpdateProfileChildAction } from "./profileChildTypes";
import actions from "./profileChildConstants";
import ProfileChildActionsCreators from "./profileChildActions";

import requestErrorCheck from "utils/requestErrorCheck";
import { accessToken } from "containers/App/GlobalSelectors";

import request from "utils/request";
import { requestHeadersAuthorized } from "utils/request";

import serialize from "serialize-javascript";
import ROUTES from "utils/routes";
import { push } from "connected-react-router";
import { getUserName } from "containers/App/GlobalSaga";
import { SCOPE } from "containers/App/GlobalTypes";
import { globalActionsCreators } from "containers/App/GlobalActions";

function* getProfileChildSaga() {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/child_user`;
    let responseAccessToken = yield select(accessToken);

    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }

    const profileChild = yield call(request, requestURL, {
      method: "GET",
      ...requestHeadersAuthorized(responseAccessToken),
    });

    const userName = (yield getUserName(
      sessionStorage.getItem("scope") as SCOPE,
      responseAccessToken
    )) as string;
    profileChild.data.userName = userName;

    yield put(
      ProfileChildActionsCreators.getProfileChildSuccess(profileChild.data)
    );
  } catch (error) {
    yield put(globalActionsCreators.logOut());
    yield put(ProfileChildActionsCreators.profileChildError(error));
  }
}

function* updateProfileChildSaga(params: UpdateProfileChildAction) {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/child_user`;
    let responseAccessToken = yield select(accessToken);

    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }

    const response = yield call(request, requestURL, {
      method: "PATCH",
      ...requestHeadersAuthorized(responseAccessToken),
      body: serialize(params.payload.profileChild),
    });
    yield put(push(ROUTES.SETTINGS));
    yield put(
      ProfileChildActionsCreators.updateProfileChildSuccess(
        response.data,
        "保存しました。"
      )
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(ProfileChildActionsCreators.profileChildError(error));
  }
}

export default function* registerRootSaga() {
  yield takeLatest(actions.GET_PROFILE_CHILD, getProfileChildSaga);
  yield takeLatest(actions.UPDATE_PROFILE_CHILD, updateProfileChildSaga);
}
