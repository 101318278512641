import { Reducer } from "redux";
import {
  LikesState,
  LikesActionTypes,
  SetPaginationAction,
} from "./LikesTypes";
import Actions from "./LikesConstants";

const initialState = (): LikesState => ({
  data: [],
  total_pages: 0,
  loading: false,
  success: false,
  error: false,
  pagination: {
    limit: 5,
    page: 1,
  },
  activePages: 1,
});

const LikesReducer: Reducer<LikesState, LikesActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case Actions.SET_PAGINATION: {
      const pagination = (action as SetPaginationAction).payload.pagination;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    }
    case Actions.GET_LIKES_CHILD: {
      return {
        ...state,
        loading: true,
      };
    }
    case Actions.GET_LIKES_ORPHANAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case Actions.GET_LIKES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        total_pages: action.payload.total_pages,
        error: false,
        loading: false,
      };
    }
    case Actions.GET_LIKES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default LikesReducer;
