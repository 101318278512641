import DesignedButton from "components/atoms/DesignedButton";
import { Transaction } from "interfaces/db/Transaction";
import React from "react";
import { useDispatch } from "react-redux";
import { generatePath, NavLink } from "react-router-dom";
import { Button, Card, Grid, Image, Label } from "semantic-ui-react";
import styled from "styled-components";
import {
  statusMapper,
  DateFormatter,
  navigateActionsCreators,
} from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import { BREAKPOINTS } from "utils/ComponentUtils";
import { bindActionCreators } from "redux";

interface TransactionBoxProps {
  transaction: Transaction;
}

const LabelStyled = styled(Grid.Column)`
  &&& {
    white-space: nowrap;
  }
`;
const TitleStyled = styled(Grid.Row)`
  &&& {
    font-size: 1.3rem;
    flex-grow: 0 !important;
    padding-bottom: 1rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 0.9rem;
      padding-bottom: 0.5rem;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const RowStyled = styled(Grid.Row)`
  &&& {
    flex-grow: 0 !important;
    padding-bottom: 1rem;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 0.8rem;
      padding-bottom: 0.5rem;
    }
  }
`;

const ImageStyled = styled(Image)`
  &&& {
    height: 200px;
    object-fit: contain;
    @media (max-width: ${BREAKPOINTS.DESKTOP}) {
      height: 110px;
      object-fit: contain;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      height: 50px;
      object-fit: contain;
    }
  }
`;

const TransactionBox: React.FC<TransactionBoxProps> = ({ transaction }) => {
  const {
    id,
    status,
    donation,
    receiver_last_name,
    receiver_first_name,
    created_at,
  } = transaction;
  const actions = {
    navigate: bindActionCreators(navigateActionsCreators, useDispatch()),
  };
  const goToMessagepage = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    actions.navigate.push(generatePath(ROUTES.MESSAGE, { id }));
  };
  return (
    <DefaultNavLink to={generatePath(ROUTES.TRANSACTIONS_DETAIL, { id })}>
      <Card fluid>
        <Card.Content>
          <Grid>
            <Grid.Row stretched>
              <Grid.Column mobile={5}>
                {donation?.images[0] && (
                  <>
                    <Grid.Row>
                      <ImageStyled
                        id="DonationImage"
                        src={donation?.images[0].thumbnail_url}
                        size="medium"
                        centered
                      />
                    </Grid.Row>
                    <TitleStyled textAlign={"center"}>
                      <strong>{donation?.point}pt</strong>
                    </TitleStyled>
                  </>
                )}
              </Grid.Column>
              <LabelStyled textAlign={"left"} mobile={6}>
                <TitleStyled>
                  <strong>{donation?.name}</strong>
                </TitleStyled>
                <RowStyled>
                  受取人 {receiver_last_name + " " + receiver_first_name}
                </RowStyled>
                <RowStyled>
                  取引日時{" "}
                  {DateFormatter(new Date(created_at), "YYYY/MM/DD hh:mm")}{" "}
                </RowStyled>
              </LabelStyled>
              <Grid.Column mobile={5}>
                <Grid.Row textAlign={"right"} style={{ whiteSpace: "nowrap" }}>
                  {status === "address_unset" && (
                    <Grid.Column>
                      <Label color={"red"}>{statusMapper(status)}</Label>
                    </Grid.Column>
                  )}
                  {status === "before_delivery" && (
                    <Label color={"yellow"}>{statusMapper(status)}</Label>
                  )}
                  {status === "delivering" && (
                    <Label color={"green"}>{statusMapper(status)}</Label>
                  )}
                  {status === "delivered" && (
                    <Label color={"grey"}>{statusMapper(status)}</Label>
                  )}
                  {status === "cancelled" && (
                    <Label color={"grey"}>{statusMapper(status)}</Label>
                  )}
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              {status === "before_delivery" || status === "delivering" ? (
                <div style={{ marginLeft: "auto", marginRight: 16 }}>
                  <DesignedButton
                    onClick={(e: { preventDefault: () => void }) =>
                      goToMessagepage(e)
                    }
                  >
                    受取確認
                  </DesignedButton>
                </div>
              ) : (
                <Button
                  disabled
                  style={{ marginLeft: "auto", marginRight: 16 }}
                >
                  {status === "delivered"
                    ? "受取済み"
                    : status === "address_unset"
                    ? "受取確認"
                    : "キャンセル"}
                </Button>
              )}
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </DefaultNavLink>
  );
};

export default TransactionBox;

const DefaultNavLink = styled(NavLink)`
  &&& {
    color: unset;
    width: 100%;
    .ui.card:last-child {
      margin-bottom: 1em;
    }
  }
`;
