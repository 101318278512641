import actions from "./profileConstants";

import {
  Profile,
  GetProfileAction,
  UpdateProfileAction,
  GetProfileSuccessAction,
  UpdateProfileSuccessAction,
  ProfileErrorAction,
} from "./profileTypes";

const getProfile = (): GetProfileAction => {
  return {
    type: actions.GET_PROFILE,
  };
};

const updateProfile = (profile: Profile): UpdateProfileAction => {
  return {
    type: actions.UPDATE_PROFILE,
    payload: {
      profile,
    },
  };
};

const getProfileSuccess = (profile: Profile): GetProfileSuccessAction => {
  return {
    type: actions.GET_PROFILE_SUCCESS,
    payload: {
      profile,
    },
  };
};

const updateProfileSuccess = (
  profile: Profile,
  message: string
): UpdateProfileSuccessAction => {
  return {
    type: actions.UPDATE_PROFILE_SUCCESS,
    payload: {
      profile,
      message,
    },
  };
};

const profileError = (error: Error): ProfileErrorAction => {
  return {
    type: actions.PROFILE_ERROR,
    payload: {
      error,
    },
  };
};

const ProfileCreators = {
  getProfile,
  updateProfile,
  getProfileSuccess,
  updateProfileSuccess,
  profileError,
};

export default ProfileCreators;
