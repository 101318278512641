import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { State } from "interfaces/State";
import { globalActionsCreators } from "./GlobalActions";
import { SCOPE } from "./GlobalTypes";

import Loader from "components/atoms/Loader";
import Routes from "./routes";
import ProfileChildCreators from "pages/MyPage/Profile/child_user/profileChildActions";
import ProfileCreators from "pages/MyPage/Profile/orphanage_user/orphanage_user/profileActions";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading, loggedIn } = useSelector((state: State) => state.logIn);
  const { profileChild } = useSelector((state: State) => state.profileChild);
  const { profile } = useSelector((state: State) => state.profile);
  const access_token = sessionStorage.getItem("access_token");
  const scope = sessionStorage.getItem("scope");

  useEffect(() => {
    if (access_token && scope === SCOPE.CHILD) {
      dispatch(ProfileChildCreators.getProfileChild());
    } else if (access_token && scope === SCOPE.ORPHANAGE) {
      dispatch(ProfileCreators.getProfile());
    } else {
      dispatch(globalActionsCreators.logOut());
    }
  }, [dispatch, access_token, scope]);

  useEffect(() => {
    if (
      !loggedIn &&
      profileChild.id !== "" &&
      profileChild.userName &&
      profileChild.userName !== ""
    ) {
      dispatch(
        globalActionsCreators.logInSuccess(
          access_token as string,
          profileChild.userName,
          profileChild.id as number,
          scope as SCOPE
        )
      );
    }
  }, [loggedIn, dispatch, profileChild, access_token, scope]);

  useEffect(() => {
    if (
      !loggedIn &&
      profile.id !== "" &&
      profile.userName &&
      profile.userName !== ""
    ) {
      dispatch(
        globalActionsCreators.logInSuccess(
          access_token as string,
          profile.userName,
          profile.id as number,
          scope as SCOPE
        )
      );
    }
  }, [loggedIn, dispatch, profile, access_token, scope]);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && <Routes />}
    </>
  );
};

export default App;
