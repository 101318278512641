enum Actions {
  LOGOUT = "Global/LOGOUT",
  LOGOUT_SUCCESS = "Global/LOGOUT_SUCCESS",
  LOGOUT_ERROR = "Global/LOGOUT_ERROR",
  LOGIN = "Global/LOGIN",
  LOGIN_SUCCESS = "Global/LOGIN_SUCCESS",
  LOGIN_ERROR = "Global/LOGIN_ERROR",
}

export default Actions;
