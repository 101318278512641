import { Reducer } from "redux";
import actions from "./CategoriesConstants";
import { CategoriesActionTypes, CategoriesState } from "./CategoriesTypes";

const initialState = (): CategoriesState => ({});

const categories: Reducer<CategoriesState, CategoriesActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case actions.SET_CATEGORIES:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default categories;
