import {
  GetDonationsOptions,
  Pagination,
  Sort,
} from "../../../modules/db/Donations/DonationsTypes";
import { Action } from "redux";
import actions from "./DonationsConstants";

export type DonationsActionTypes =
  | SetSearchConditionAction
  | SetPaginationAction
  | SetSortAction;

export type SetSearchConditionAction = Action<actions.SET_SEARCH_CONDITION> & {
  payload: {
    searchCondition: GetDonationsOptions;
  };
};

export type SetPaginationAction = Action<actions.SET_PAGINATION> & {
  payload: {
    pagination: Pagination;
  };
};

export type SetSortAction = Action<actions.SET_SORT> & {
  payload: {
    sort: Sort;
  };
};

export const setSearchCondition = (
  searchCondition: GetDonationsOptions
): SetSearchConditionAction => {
  return {
    type: actions.SET_SEARCH_CONDITION,
    payload: {
      searchCondition,
    },
  };
};

export const setPagination = (pagination: Pagination): SetPaginationAction => {
  return {
    type: actions.SET_PAGINATION,
    payload: {
      pagination,
    },
  };
};

export const setSort = (sort: Sort): SetSortAction => {
  return {
    type: actions.SET_SORT,
    payload: {
      sort,
    },
  };
};

export const donationsActionsCreators = {
  setSearchCondition,
  setPagination,
  setSort,
};
