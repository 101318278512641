import serialize from "serialize-javascript";
import request, { requestHeadersAuthorized } from "utils/request";
import Password from "./Password";

export default {
  Password: Password,
  accountNotify: async (accessToken: string, email: string) =>
    request(`${process.env.REACT_APP_API_URL}/child_user/account_notify`, {
      method: "POST",
      ...requestHeadersAuthorized(accessToken),
      body: serialize({ email }),
    }),
};
