import React from "react";
import {
  Card,
  CardHeaderProps,
  CardProps,
  Label,
  Icon,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { Images } from "interfaces/db/Image";
import styled from "styled-components";
import LikeLabel from "components/atoms/LikeLabel";
export interface ThumbnailProps {
  key?: string;
  image: Images[];
  alt: string;
  imageLink?: string;
  thumbsTitle?: React.ReactNode;
  thmubsPoint?: string;
  titleOption?: CardHeaderProps;
  like_num?: number;
  liked?: boolean;
}

const CardTitle = styled(Card.Header)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 0.25rem;
`;

const PointText = styled.span`
  font-size: 0.8rem;
  color: white;
`;
export const Thumbnail: React.FC<ThumbnailProps & CardProps> = ({
  image,
  alt,
  imageLink,
  thumbsTitle: title,
  thmubsPoint: point,
  titleOption,
  like_num,
  liked,
  ...cardProps
}) => {
  return (
    <Card as={NavLink} {...cardProps} to={imageLink}>
      {image[0] ? (
        <Card.Content
          style={{
            position: "relative",
            padding: 0,
            textAlign: "center",
          }}
        >
          <img
            src={image[0].thumbnail_url}
            alt={alt}
            style={{ height: "150px", maxWidth: "100%", objectFit: "cover" }}
          />
          <Label
            color={"black"}
            style={{
              opacity: "0.8",
              minWidth: "60px",
              position: "absolute",
              bottom: 3,
              left: 0,
              padding: "0 auto",
            }}
          >
            <PointText>{point}</PointText>
          </Label>
        </Card.Content>
      ) : (
        <Card.Content
          style={{
            position: "relative",
            padding: 0,
            textAlign: "center",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
          }}
        >
          <Icon size="huge" name="image" color="green" />
          <Label
            color={"black"}
            style={{
              opacity: "0.8",
              minWidth: "60px",
              position: "absolute",
              bottom: 3,
              left: 0,
              padding: "0 auto",
            }}
          >
            <PointText>{point}</PointText>
          </Label>
        </Card.Content>
      )}
      <Card.Content>
        {title && (
          <CardTitle
            style={{
              fontSize: "14px",
              fontFamily: "ヒラギノ角ゴシック",
              textAlign: "center",
            }}
            {...titleOption}
          >
            {title}
          </CardTitle>
        )}
        <Card.Meta
          style={{
            fontSize: "0.75rem",
          }}
        >
          {like_num ? (
            liked ? (
              <LikeLabel
                color="#00B5AD"
                like_num={like_num}
                flex_placement={"flex-end"}
              ></LikeLabel>
            ) : (
              <LikeLabel
                color="#9e9e9e"
                like_num={like_num}
                flex_placement={"flex-end"}
              ></LikeLabel>
            )
          ) : (
            <LikeLabel
              color="#9e9e9e"
              like_num={0}
              flex_placement={"flex-end"}
            ></LikeLabel>
          )}
        </Card.Meta>
      </Card.Content>
    </Card>
  );
};
