import { Reducer } from "redux";
import { Actions } from "./PersonalIdentificationActions";
import ActionTypes from "./PersonalIdentificationConstants";

export interface PersonalIdentificationState {
  message: string;
}

const initialState = (): PersonalIdentificationState => {
  return {
    message: "",
  };
};

const personalIdentification: Reducer<PersonalIdentificationState, Actions> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case ActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      };
    case ActionTypes.CHILD_SUBMIT:
    case ActionTypes.ORPHANAGE_SUBMIT:
    default:
      return state;
  }
};

export default personalIdentification;
