import DesignedButton from "components/atoms/DesignedButton";
import InfoMessage from "components/atoms/InfoMessage";
import MainHeader from "components/atoms/MainHeader";
import TextBox from "components/atoms/TextBox";
import { Formik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Grid, Header } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { BREAKPOINTS, navigateActionsCreators } from "utils/ComponentUtils";
import * as Yup from "yup";
import { PersonalIdentificationProps } from ".";
import personalIdentificationActionCreators from "./PersonalIdentificationActions";
import Validate from "utils/Validate";

const { Row, Column, PaddingRow } = DefaultGrid;

const MainTextHeader = styled(MainHeader)`
  &&& {
    @media (max-width: 400px) {
      text-align: left;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      font-size: 18px;
    }
    text-align: center;
    font-size: 24px;
  }
`;

const MainGrid = styled(Grid)`
  &&& {
    margin-top: 100px;
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      margin-top: 40px;
    }
  }
`;

const PaddingGrid = padding(DefaultGrid);

type OrphanageUserPersonalIdentificationProps = PersonalIdentificationProps;

const OrphanageUserPersonalIdentification: React.FC<
  OrphanageUserPersonalIdentificationProps
> = ({ resetPasswordToken, message, userType }) => {
  const dispatch = useDispatch();
  const actions = useMemo(() => {
    return {
      ...bindActionCreators(personalIdentificationActionCreators, dispatch),
      navigate: bindActionCreators(navigateActionsCreators, dispatch),
    };
  }, [dispatch]);

  const initialFormValues: FormValues = {
    tel_number: "",
  };
  interface FormValues {
    tel_number: string;
  }

  const orphanageValidationScheme = Yup.object().shape({
    tel_number: Validate.tel_number,
  });

  const handleSubmit = async (values: FormValues) => {
    actions.orphanageSubmit({
      resetPasswordToken,
      phoneNumber: values.tel_number,
      userType,
    });
  };

  // messageのクリア
  useEffect(
    () => () => {
      actions.setMessage({ message: "" });
    },
    [actions]
  );

  return (
    <MainGrid id="MainContents" textAlign="center">
      <PaddingRow id="TextContainer" padding={30}>
        <Column width={14}>
          <MainTextHeader>
            本人認証のため電話番号を入力してください
          </MainTextHeader>
        </Column>
      </PaddingRow>
      {message && (
        <PaddingRow centered padding={0}>
          <Column textAlign={"center"}>
            <InfoMessage severity="error" message={message} />
          </Column>
        </PaddingRow>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={orphanageValidationScheme}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <PaddingGrid padding={0} centered>
                <PaddingRow id="InputContainer" padding={30} centered>
                  <Column style={{ width: "300px" }}>
                    <Row>
                      <Header>電話番号</Header>
                      <TextBox
                        name="tel_number"
                        id="tel_number"
                        focus
                        fluid={true}
                        size="big"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tel_number}
                        error={
                          touched.tel_number && errors.tel_number
                            ? errors.tel_number
                            : undefined
                        }
                      />
                      <p style={{ fontSize: "12px" }}>
                        ※ハイフンなしで入力してください
                      </p>
                    </Row>
                  </Column>
                </PaddingRow>
                <PaddingRow id="ButtonContainer" padding={40}>
                  <Column style={{ width: "150px" }}>
                    <DesignedButton type="submit" fluid={true}>
                      進む
                    </DesignedButton>
                  </Column>
                </PaddingRow>
              </PaddingGrid>
            </Form>
          );
        }}
      </Formik>
    </MainGrid>
  );
};

export default OrphanageUserPersonalIdentification;
