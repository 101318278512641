import { Reducer } from "redux";

import Actions from "./DeleteAccountConstants";
import { DeleteAccountState, ActionTypes } from "./DeleteAccountTypes";

const initialState = (): DeleteAccountState => ({
  loading: false,
  error: false,
});

const deleteAccountReducer: Reducer<DeleteAccountState, ActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case Actions.DELETE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case Actions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case Actions.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default deleteAccountReducer;
