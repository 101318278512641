import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import { ChildState } from "pages/MyPage/Child/ChildTypes";
import request, { B4SResponse, requestHeadersAuthorized } from "utils/request";
import { APIResponse } from "interfaces/db/Rails";

type EditableKeys =
  | "email"
  | "postal_code"
  | "prefecture"
  | "city"
  | "address1"
  | "address2"
  | "tel_number";
const WRITE_CHILD_KEYS: EditableKeys[] = [
  "email",
  "postal_code",
  "prefecture",
  "city",
  "address1",
  "address2",
  "tel_number",
];

// 特殊なのでこれだけ外だししてjsDocを追加
/***
 * 子供の作成(POST)と更新(PATCH)
 * @param {string} accessToken アクセストークン
 * @param {string|undefined} id 作成時:undefined , 更新時:対象データのid
 * @param {ChildState["child"]} child 子供情報
 */
const writeChildUsers = async (
  accessToken: string,
  id: string | undefined,
  child: ChildState["child"]
) => {
  const q = WRITE_CHILD_KEYS.filter((key) => {
    return child[key] !== null;
  })
    .map((key) => "child_user[" + key + "]=" + encodeURIComponent(child[key]))
    .join("&");

  return request(
    process.env.REACT_APP_API_URL +
      "/orphanage/child_users/" +
      (id === undefined ? "" : id) +
      (q.length === 0 ? "" : "?" + q),
    {
      method: id === undefined ? "POST" : "PATCH",
      ...requestHeadersAuthorized(accessToken),
    }
  ) as Promise<B4SResponse<ChildUser>>;
};

export default {
  writeChildUsers,
  getChildUser: async (
    accessToken: string,
    id: string
  ): Promise<B4SResponse<ChildUser>> => {
    return (
      request(process.env.REACT_APP_API_URL + "/orphanage/child_users/" + id, {
        method: "GET",
        ...requestHeadersAuthorized(accessToken),
      }) as Promise<B4SResponse<APIResponse<ChildUser>>>
    ).then((res) => ({
      ...res,
      data: {
        ...res.data,
        birthdate:
          res.data.birthdate === null ? null : new Date(res.data.birthdate),
        account_notified_at:
          res.data.account_notified_at === null
            ? null
            : new Date(res.data.account_notified_at),
        created_at: new Date(res.data.created_at),
        updated_at: new Date(res.data.updated_at),
      },
    }));
  },
};
