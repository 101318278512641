import { Reducer } from "redux";
import { ChangeEmailState, ActionTypes } from "./ChangeEmailTypes";

import actions from "./ChangeEmailConstants";

const changeEmailInitialState: ChangeEmailState = {
  emailParams: {
    email: "",
  },
  message: false,
  loading: false,
  error: false,
};

const changeEmailReducer: Reducer<ChangeEmailState, ActionTypes> = (
  state = changeEmailInitialState,
  action
) => {
  switch (action.type) {
    case actions.CHANGE_EMAIL:
      return {
        ...state,
        loading: true,
      };
    case actions.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: true,
      };
    case actions.CHANGE_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default changeEmailReducer;
