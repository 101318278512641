import LinkButton from "components/atoms/LinkButton";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import { withHeaderAndFooter } from "components/organisms/Header";
import { State } from "interfaces/State";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import {
  Image,
  Label,
  Loader,
  Dropdown,
  Icon,
  DropdownProps,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import { COLORS } from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import DesignedButton from "components/atoms/DesignedButton";
import { size } from "styles/Size";
import { DonationState } from "modules/db/Donation/DonationType";
import { donationActionsCreators } from "modules/db/Donation/DonationAction";
import ERROR_MESSAGES from "utils/errorMessages";
import { LogInState } from "containers/App/GlobalTypes";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import LikeCreators from "../../../modules/db/Like/LikeActions";
import { ChildrenListState } from "modules/db/ChildrenList/ChildrenListTypes";
import LikeButton from "components/atoms/LikeButton";

interface DonationDetailParams {
  [k: string]: string;
  id: string;
}

type DonationDetailProps = RouteComponentProps<DonationDetailParams>;

const SwiperStyled = styled.div`
  --swiper-theme-color: ${COLORS.SWIPER};
  max-width: 100vw;
`;

const SubHeader = styled.h2`
  font-size: 1.7rem;
  text-align: left;
  margin: 0;
`;

const ContentsRow = styled(DefaultGrid.Row)`
  max-width: 500px;
  padding-top: 2rem;
`;
const ImageRow = styled(DefaultGrid.Row)`
  max-width: 370px;
`;
const ImageStyled = styled(Image)`
  &&& {
    object-fit: contain;
    width: 100%;
    height: 270px;
  }
`;
const RowStyled = styled(DefaultGrid.Row)`
  &&& {
    padding: 0;
  }
`;
const PaddingGrid = padding(DefaultGrid);
const { Row, Column, PaddingRow } = DefaultGrid;

const DonationDetail: React.FC<DonationDetailProps> = (props) => {
  const dispatch = useDispatch();
  const { data: donation, loading } = useSelector<State, DonationState>(
    (state) => state.donation
  );
  const { children: childList } = useSelector<State, ChildrenListState>(
    (s) => s.childrenList
  );

  // user type
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);
  const { profileChild } = useSelector((state: State) => state.profileChild);
  const { id } = props.match.params;
  const [checkDisable, setCheckDisable] = useState(false);
  const [disableState, setDisableState] = useState<string>("");
  const [likedNum, setLikedNum] = useState<number>(donation?.like_num || 0);
  useEffect(() => {
    donation?.like_num && setLikedNum(donation?.like_num);
  }, [donation]);

  const actions = useMemo(() => {
    return {
      donation: bindActionCreators(donationActionsCreators, dispatch),
      like: bindActionCreators(LikeCreators, dispatch),
      child_list: bindActionCreators(childrenListActionsCreators, dispatch),
    };
  }, [dispatch]);

  const params = {
    slidesPerView: 1,
    spaceBetween: 30,
    rebuildOnUpdate: true,
    loop: donation?.images && donation?.images.length > 1 ? true : false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl:
        donation?.images && donation?.images.length > 1
          ? ".swiper-button-next"
          : "",
      prevEl:
        donation?.images && donation?.images.length > 1
          ? ".swiper-button-prev "
          : "",
    },
  };

  useEffect(() => {
    let pagination = {
      page: undefined,
      limit: undefined,
    };
    if (scope === "orphanage_user") {
      if (!childList) {
        actions.child_list.getChildrenList(pagination);
      }
    }
  }, [actions.child_list, childList, scope]);

  useEffect(() => {
    if (donation && donation.id !== +id) {
      actions.donation.resetDonation();
    }
    if (!donation) {
      actions.donation.getDonation(+id);
    }
  }, [id, donation, actions.donation]);

  useEffect(() => {
    return () => {
      actions.donation.resetDonation();
    };
  }, [actions.donation]);

  useEffect(() => {
    sessionStorage.removeItem("form_values");
  }, []);

  useEffect(() => {
    if (donation?.stock_num === 0) {
      setDisableState("stock_num");
      setCheckDisable(true);
    } else if (!donation?.check_disable) {
      setDisableState("check_disable");
      setCheckDisable(true);
    } else {
      setDisableState("");
      setCheckDisable(false);
    }
    // eslint-disable-next-line
  }, [donation?.stock_num]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setLikedChildrenId(
      donation?.liked_children?.map((child) => child.child_user_id) || []
    );
  }, [donation]);

  const [likedChildrenId, setLikedChildrenId] = useState(
    donation?.liked_children?.map((child) => child.child_user_id) || []
  );

  const handleOnChange = async (
    e: React.SyntheticEvent,
    data: DropdownProps
  ) => {
    // check if liked_children is added or removed
    const { value } = data;
    const valueArray = value as string[];
    if (valueArray.length > likedChildrenId.length) {
      // identify the added child
      const addedChildId = valueArray.find(
        (childId) => !likedChildrenId.includes(childId) // if childId is not in likedChildrenId
      );
      // POST /api/v1/likes
      addedChildId &&
        donation?.id &&
        actions.like.Like(addedChildId, donation?.id.toString(), "POST");
      setLikedNum(likedNum + 1);
    } else {
      // identify the removed child
      const removedChildId = likedChildrenId.find(
        (childId) => !valueArray.includes(childId) // if childId is not in valueArray
      );
      // DELETE /api/v1/likes/
      removedChildId &&
        donation?.id &&
        actions.like.Like(removedChildId, donation?.id.toString(), "DELETE");
      setLikedNum(likedNum - 1);
    }
    setLikedChildrenId(valueArray);
  };

  return (
    <DefaultGrid id="DonationsDetail" centered>
      {!loading && donation && donation.id === +id ? (
        <>
          <Row id="Breadcrumbs">
            <DefaultGrid.Column width={16}>
              <CustomBreadcrumbs {...props} />
            </DefaultGrid.Column>
          </Row>
          <ContentsRow id="Contents">
            <ImageRow id="Images">
              {donation?.images[0] && (
                <SwiperStyled>
                  <Swiper {...params}>
                    {donation?.images.map((image) => (
                      <div key={image.url} style={{ textAlign: "center" }}>
                        <ImageStyled
                          id="DonationImage"
                          src={image.url}
                          size="medium"
                          centered
                        />
                      </div>
                    ))}
                  </Swiper>
                </SwiperStyled>
              )}
            </ImageRow>
            <PaddingGrid padding={16}>
              <RowStyled>
                <SubHeader>{donation?.name}</SubHeader>
              </RowStyled>
              <PaddingRow paddingtop={10} paddingbottom={0}>
                <SubHeader>{donation?.point}pt</SubHeader>
              </PaddingRow>
              <PaddingRow
                paddingtop={10}
                paddingbottom={0}
                style={{ fontSize: 16 }}
              >
                残り{donation?.stock_num}点
              </PaddingRow>
              <PaddingRow paddingtop={10} style={{ fontSize: 18 }}>
                <Label color={"orange"}>{donation?.category.name}</Label>
              </PaddingRow>
              {/* if current user is child_user, display this */}
              {scope === "child_user" && (
                <>
                  <PaddingRow
                    paddingtop={10}
                    style={{ fontSize: 14, color: COLORS.PRIMARY }}
                  >
                    <LikeButton
                      like_num={donation?.like_num}
                      liked={donation?.liked}
                      donation_id={donation?.id}
                      child_user_id={profileChild.id.toString()}
                    ></LikeButton>
                    <Popup
                      trigger={
                        <Icon
                          name="info circle"
                          size="large"
                          style={{ paddingLeft: "0.5rem", margin: "auto 0" }}
                        />
                      }
                      content="申し込みたい寄付品を登録するとお気に入りリストで管理できます。"
                      size="small"
                      wide
                    />
                  </PaddingRow>
                </>
              )}
              {/* if current user is orphanage_user, display this */}
              {scope === "orphanage_user" && (
                <>
                  <PaddingRow
                    paddingtop={10}
                    style={{ fontSize: 14, color: COLORS.PRIMARY }}
                  >
                    <Icon name="heart" />
                    お気に入り登録{likedNum ?? 0}件
                    <Popup
                      trigger={
                        <Icon
                          name="info circle"
                          size="large"
                          style={{ paddingLeft: "0.5rem" }}
                        />
                      }
                      content="申し込みたい寄付品を登録するとお気に入りリストで管理できます。"
                      size="small"
                      wide
                    />
                  </PaddingRow>
                  <Dropdown
                    style={{ marginBottom: 30 }}
                    placeholder="子ども名を選択してください"
                    fluid
                    multiple
                    search
                    selection
                    noResultsMessage={"選択できる子供ユーザはいません。"}
                    // default value
                    // children in liked_children and selectChildUser
                    options={
                      (childList &&
                        childList
                          .filter((child) => child.account_notified_at == null)
                          .map((child) => ({
                            key: child.id,
                            text: `${child.last_name}${child.first_name}`,
                            value: child.id,
                          }))) ??
                      []
                    }
                    defaultValue={
                      donation?.liked_children &&
                      donation?.liked_children.map(
                        (child) => child.child_user_id
                      )
                    }
                    onChange={handleOnChange}
                  />
                </>
              )}
              <Row
                style={{
                  fontSize: 18,
                  fontFamily: "ヒラギノ角ゴシック",
                }}
              >
                商品詳細
              </Row>
              <Row
                style={{
                  fontSize: 16,
                  whiteSpace: "pre-line",
                  textAlign: "left",
                }}
              >
                {donation?.description}
              </Row>
              <Row style={{ fontSize: 18, fontFamily: "ヒラギノ角ゴシック" }}>
                備考
              </Row>
              {donation?.comment == null ? (
                <>
                  <Row
                    style={{
                      fontSize: 16,
                      whiteSpace: "pre-line",
                      textAlign: "left",
                    }}
                  >
                    特になし
                  </Row>
                </>
              ) : (
                <Row
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-line",
                    textAlign: "left",
                  }}
                >
                  {donation?.comment}
                </Row>
              )}
            </PaddingGrid>
            <PaddingRow
              id="DestinationInfoRow"
              paddingtop={20}
              style={{ margin: "auto" }}
            >
              <Row>
                <Column width={16} textAlign="center">
                  {checkDisable && (
                    <>
                      <DesignedButton
                        disabled={checkDisable}
                        inverted
                        style={{
                          width: "224px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        申し込みページへ
                      </DesignedButton>
                      <p style={{ color: "#FF3333" }}>
                        {disableState === "stock_num" &&
                          ERROR_MESSAGES.NO_STOCK}
                        {disableState === "check_disable" &&
                          ERROR_MESSAGES.CAN_NOT_APPLY}
                      </p>
                    </>
                  )}
                  {!checkDisable && (
                    <ResizeableLinkButton
                      to={generatePath(
                        ROUTES.DONATION_APPLICATION,
                        props.match.params
                      )}
                      fluid
                      style={{
                        width: "224px",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      申し込みページへ
                    </ResizeableLinkButton>
                  )}
                </Column>
              </Row>
            </PaddingRow>
          </ContentsRow>
        </>
      ) : (
        <Loader active />
      )}
    </DefaultGrid>
  );
};

export default withHeaderAndFooter(DonationDetail);

const ResizeableLinkButton = size(LinkButton);
