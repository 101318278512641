import actions from "./LikeConstants";

import { LikeAction, LikeSuccessAction, LikeErrorAction } from "./LikeTypes";
import { methodUnion } from "./methodUnion";

const Like = (
  child_user_id: string,
  donation_id: string,
  method: methodUnion
): LikeAction => {
  return {
    type: actions.REQUEST_LIKE,
    payload: {
      child_user_id,
      donation_id,
      method,
    },
  };
};

const LikeSuccess = (): LikeSuccessAction => {
  return {
    type: actions.REQUEST_LIKE_SUCCESS,
  };
};

const LikeError = (error: Error): LikeErrorAction => {
  return {
    type: actions.REQUEST_LIKE_ERROR,
    payload: {
      error,
    },
  };
};

const LikeCreators = {
  Like: Like,
  LikeSuccess: LikeSuccess,
  LikeError: LikeError,
};

export default LikeCreators;
