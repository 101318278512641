import {
  DeleteAccount,
  DeleteAccountAction,
  DeleteAccountSuccessAction,
  DeleteAccountErrorAction,
} from "./DeleteAccountTypes";
import Actions from "./DeleteAccountConstants";

const deleteAccount = (accountParams: DeleteAccount): DeleteAccountAction => {
  return {
    type: Actions.DELETE_ACCOUNT,
    payload: {
      accountParams,
    },
  };
};

const deleteAccountSuccess = (): DeleteAccountSuccessAction => {
  return {
    type: Actions.DELETE_ACCOUNT_SUCCESS,
  };
};

const deleteAccountError = (error: Error): DeleteAccountErrorAction => {
  return {
    type: Actions.DELETE_ACCOUNT_ERROR,
    payload: {
      error,
    },
  };
};

export const deleteAccountActionsCreators = {
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountError,
};
