import { Reducer } from "redux";
import {
  RequestChangePasswordState,
  ActionTypes,
} from "./RequestChangePasswordTypes";

import actions from "./RequestChangePasswordConstants";

const requestChangePasswordInitialState: RequestChangePasswordState = {
  success: false,
  loading: false,
  error: false,
};

const requestChangePasswordReducer: Reducer<
  RequestChangePasswordState,
  ActionTypes
> = (state = requestChangePasswordInitialState, action) => {
  switch (action.type) {
    case actions.REQUEST_CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case actions.REQUEST_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case actions.REQUEST_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default requestChangePasswordReducer;
