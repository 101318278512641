import React from "react";
import DesignedButton from "./DesignedButton";
import ROUTES from "utils/routes";

import { useLocation, useHistory } from "react-router-dom";

const LoginButton: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const goToLoginpage = () => {
    history.push({
      pathname: ROUTES.LOGIN,
      search: `?redirectURL=${encodeURIComponent(location.pathname)}`,
    });
  };

  return <DesignedButton onClick={goToLoginpage}>ログイン</DesignedButton>;
};

export default LoginButton;
