import request, { B4SResponse, requestHeadersAuthorized } from "utils/request";
import { OrphanageResponse } from "./OrphanageUserTypes";
import Password from "./Password";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/orphanage_user/`;

export default {
  Password: Password,
  orphanage: async (
    accessToken: string
  ): Promise<B4SResponse<OrphanageResponse>> => {
    return request(API_BASE_URL + "orphanage", {
      ...requestHeadersAuthorized(accessToken),
      method: "GET",
    }) as Promise<B4SResponse<OrphanageResponse>>;
  },
};
