import { call, put, takeLatest, select } from "redux-saga/effects";

import { push } from "connected-react-router";
import { ChangeEmailAction } from "./ChangeEmailTypes";
import actions from "./ChangeEmailConstants";
import changeEmailActionsCreators from "./ChangeEmailActions";
import requestErrorCheck from "utils/requestErrorCheck";
import { accessToken } from "containers/App/GlobalSelectors";
import ROUTES from "utils/routes";

import request, { requestHeadersAuthorized } from "utils/request";

import serialize from "serialize-javascript";

function* changeEmailSaga(params: ChangeEmailAction) {
  try {
    let requestURL = "";

    if (sessionStorage.scope === "orphanage_user") {
      requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user`;
    } else {
      requestURL = `${process.env.REACT_APP_API_URL}/child_user`;
    }
    const responseAccessToken = yield select(accessToken);

    yield call(request, requestURL, {
      method: "PATCH",
      ...requestHeadersAuthorized(responseAccessToken),
      body: serialize(params.payload.emailParams),
    });
    yield put(push(ROUTES.CHANGE_EMAIL_COMPLETE));
    yield put(changeEmailActionsCreators.changeEmailSuccess());
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(changeEmailActionsCreators.changeEmailError(error));
  }
}

export default function* changeEmailRootSaga() {
  yield takeLatest(actions.CHANGE_EMAIL, changeEmailSaga);
}
