enum ERROR_MESSAGES {
  REQUIRED = "必須",
  SPECIAL_CHARS_ERROR = "＠などの特殊文字は使えません",
  ONLY_HIRAGANA_ERROR = "ひらがなを入力してください",
  ONLY_NUMBER_ALLOWED = "数字のみを入力してください",
  ONLY_HANKAKU_NUMBER_ALLOWED = "半角数字で入力してください",
  ONLY_JAPANESE_ERROR = "日本語のみで入力してください",
  VALID_EMAIL = "メールアドレスを入力してください",
  LOGIN_ERROR = "パスワードまたはメールアドレスが間違っています",
  NOT_FOUND_ERROR = "ページが見つかりませんでした",
  NO_CONNECTION_ERROR = "接続エラーが発生しています",
  AUTHORIZATION_ERROR = "権限がありません",
  ERROR_POSTAL_CODE = "郵便番号は半角数字7桁で入力してください",
  INVALID_DATE_ERROR = "日付形式で入力してください(例：2020/01/01)",
  NO_POINT = "保有ポイントが足りません",
  NO_STOCK = "在庫がありません",
  CAN_NOT_APPLY = "現在お申し込みができません",
  CONFIRMATION_PASSWORD_MUST_MATCH = "パスワードが一致しません",
  CONFIRMATION_EMAIL_MUST_MATCH = "メールアドレスが一致しません",
  CHOOSE_RECIEVER_ERROR = "受取人を選択してください",
  TEL_NUMBER_REQUIRED = "電話番号の形式で入力してください（ハイフンなし）",
  INVARID_MAIL = "入力されたメールアドレスは無効です",
  DESTINATION_ERROR = "送付先情報を入力してください",
  FREE_COMMENT_MAX_LENGTH = "コメントは最大255文字で入力してください",
}

export default ERROR_MESSAGES;
