import Annotation from "components/atoms/Annotation";
import DesignedButton from "components/atoms/DesignedButton";
import LinkButton from "components/atoms/LinkButton";
import MainHeader from "components/atoms/MainHeader";
import SelectBox from "components/atoms/SelectBox";
import TextBox from "components/atoms/TextBox";
import { Formik } from "formik";
import { State } from "interfaces/State";
import React, { ComponentProps, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Form, Label } from "semantic-ui-react";
import styled from "styled-components";
import { align } from "styles/Align";
import DefaultGrid from "styles/DefaultGrid";
import { DateFormatter } from "utils/ComponentUtils";
import prefectures from "utils/prefectures";
import ROUTES from "utils/routes";
import * as Yup from "yup";
import childCreators from "../ChildActions";
import { ChildState } from "../ChildTypes";
import { ChildEditorState, initialChildEditorState } from "./ChildEditorType";
import REGEX_STRINGS from "utils/regexStrings";
import ERROR_MESSAGES from "utils/errorMessages";

const childScheme = Yup.object<ChildEditorState>().shape({
  email: Yup.string()
    .email(ERROR_MESSAGES.VALID_EMAIL)
    .required(ERROR_MESSAGES.REQUIRED),
  tel_number: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .min(10, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .max(11, ERROR_MESSAGES.TEL_NUMBER_REQUIRED)
    .nullable(),
  postal_code: Yup.string()
    .matches(REGEX_STRINGS.ONLY_NUMBER, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .length(7, ERROR_MESSAGES.ERROR_POSTAL_CODE)
    .nullable(),
  prefecture: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .nullable(),
  city: Yup.string()
    .matches(
      REGEX_STRINGS.NO_SPECIAL_CHARACTERS_ALLOWED,
      ERROR_MESSAGES.SPECIAL_CHARS_ERROR
    )
    .nullable(),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
});

interface RouterProps {
  id?: string;
}

const ChildEditor: React.FC = () => {
  const { id } = useParams<RouterProps>();
  const { child, loading } = useSelector<State, ChildState>((s) => s.child);
  const dispatch = useDispatch();
  const actions = useMemo(
    () => ({
      ...bindActionCreators(childCreators, dispatch),
    }),
    [dispatch]
  );

  useEffect(() => {
    if (id === undefined) {
      actions.changeChild({ email: generateDummyEmail() });
    } else {
      actions.getChild(id);
    }

    // unmountする時に初期化する
    return () => {
      actions.changeChild(initialChildEditorState());
    };
  }, [id, actions]);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={child}
      validationSchema={childScheme}
      onSubmit={(values) => {
        actions.writeChild(id, values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
      }) => {
        return (
          <Form>
            <DefaultGrid id="ChildEditor">
              <Row id="HeaderRow">
                <Column width={16}>
                  <MainHeader>
                    子どもを{id === undefined ? "登録" : "編集"}
                  </MainHeader>
                </Column>
              </Row>
              <PaddingRow id="InputRow" padding={0}>
                <DefaultGrid>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="last_name">氏名</StyledLabel>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow columns="equal">
                    <PaddingColumn paddingright={0}>
                      {child.last_name}
                    </PaddingColumn>
                    <Column>{child.first_name}</Column>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="last_kana_name">
                        氏名（かな）
                      </StyledLabel>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow columns="equal">
                    <PaddingColumn paddingright={0}>
                      {child.last_kana_name}
                    </PaddingColumn>
                    <Column>{child.first_kana_name}</Column>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="birthdate">生年月日</StyledLabel>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow columns="equal">
                    <PaddingColumn paddingleft={28} textAlign="left">
                      {DateFormatter(child.birthdate, "YYYY/MM/DD")}
                    </PaddingColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="email">メールアドレス</StyledLabel>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow padding={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        type="email"
                        error={
                          touched.email &&
                          errors.email && { content: errors.email }
                        }
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <AnnotationColumn paddingright={0} textAlign="left">
                      <Annotation>
                        ※メールアドレスを持たない場合、ダミーアドレスが自動で入力されます
                      </Annotation>
                    </AnnotationColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="tel_number">電話番号</StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow padding={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        error={
                          touched.tel_number &&
                          errors.tel_number && { content: errors.tel_number }
                        }
                        id="tel_number"
                        name="tel_number"
                        value={values.tel_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <AnnotationColumn paddingright={0} textAlign="left">
                      <Annotation>※ハイフンなしで入力してください</Annotation>
                    </AnnotationColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="postal_code">郵便番号</StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow padding={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        error={
                          touched.postal_code &&
                          errors.postal_code && { content: errors.postal_code }
                        }
                        id="postal_code"
                        name="postal_code"
                        value={values.postal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <AnnotationColumn paddingright={0} textAlign="left">
                      <Annotation>※ハイフンなしで入力してください</Annotation>
                    </AnnotationColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="prefecture">都道府県</StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <SelectBox
                        error={
                          touched.prefecture && errors.prefecture
                            ? errors.prefecture
                            : undefined
                        }
                        id={"prefecture"}
                        name={"prefecture"}
                        loading={loading}
                        disabled={loading}
                        value={values.prefecture}
                        onBlur={handleBlur}
                        options={prefectures.map((item) => ({
                          ...item,
                          id: item.key,
                          onClick: (e) => {
                            handleChange({
                              ...e,
                              target: {
                                ...e.target,
                                id: "prefecture",
                                name: "prefecture",
                                value: item.value,
                              },
                            });
                          },
                        }))}
                        selection
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="city">市区町村</StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        error={
                          touched.city &&
                          errors.city && { content: errors.city }
                        }
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="address1">番地</StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        error={
                          touched.address1 &&
                          errors.address1 && { content: errors.address1 }
                        }
                        id="address1"
                        name="address1"
                        value={values.address1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                  <LabelRow>
                    <LabelColumn width={16}>
                      <StyledLabel htmlFor="address2">
                        建物名／部屋番号
                      </StyledLabel>
                      <Label
                        color="green"
                        size="mini"
                        style={{ marginLeft: "10px" }}
                      >
                        任意
                      </Label>
                    </LabelColumn>
                  </LabelRow>
                  <PaddingRow paddingtop={0} columns="equal">
                    <PaddingColumn paddingright={0}>
                      <LoaderTextBox
                        error={
                          touched.address2 &&
                          errors.address2 && { content: errors.address2 }
                        }
                        id="address2"
                        name="address2"
                        value={values.address2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fluid={true}
                      />
                    </PaddingColumn>
                  </PaddingRow>
                </DefaultGrid>
              </PaddingRow>
              <Row id="ControlsRow">
                <Column width={16}>
                  <DefaultGrid>
                    <PaddingRow padding={0} centered>
                      <FitColumn
                        id="CancelColumn"
                        padding={0}
                        paddingright="0.5rem"
                      >
                        <LinkButton
                          to={ROUTES.CHILDREN_LIST}
                          design="primary"
                          inverted
                        >
                          キャンセル
                        </LinkButton>
                      </FitColumn>
                      <FitColumn
                        id="EntryColumn"
                        padding={0}
                        paddingleft="0.5rem"
                      >
                        <DesignedButton type="submit" onClick={handleSubmit}>
                          保存する
                        </DesignedButton>
                      </FitColumn>
                    </PaddingRow>
                  </DefaultGrid>
                </Column>
              </Row>
            </DefaultGrid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChildEditor;

const LabelRow: React.FC<ComponentProps<typeof PaddingRow>> = (props) => (
  <PaddingRow paddingbottom={4} {...props} />
);

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const FitColumn = styled(PaddingColumn)`
  &&& {
    width: fit-content !important;
  }
`;

const StyledLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
`;

const LabelColumn = styled(Column)`
  text-align: left;
`;

const AnnotationColumn = align(PaddingColumn);

const generateDummyEmail = () => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYXZ.-_0123456789";
  // 日時から適当な文字列を生成する
  return (
    ((
      Math.floor(Math.random() * 9999)
        .toString()
        .padStart(4, "0") + DateFormatter(new Date(), "SSSYYYYMMDDhhmmssSSS")
    )
      .match(/.{2}/g)
      ?.map((str) => chars[Number(str) % chars.length])
      .join("") || "") + "@example.com"
  );
};

const LoaderTextBox: React.FC<ComponentProps<typeof TextBox>> = (props) => {
  const { loading } = useSelector<State, ChildState>((s) => s.child);
  return (
    <TextBox
      {...props}
      loading={loading || props.loading}
      disabled={loading || props.disabled}
    />
  );
};
