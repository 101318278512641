import React from "react";
import { Button, ButtonProps } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "utils/ComponentUtils";
import padding from "styles/Padding";

export interface DesignedButtonProps extends Omit<ButtonProps, "color"> {
  design?: "primary" | "secondary";
}

type StyleCallback = (props: DesignedButtonProps) => string | undefined;

const DesignedButton: React.FC<DesignedButtonProps> = ({
  design = "primary",
  ...props
}) => {
  return <Button {...props} data-design={design} />;
};

const color: StyleCallback = ({ design, inverted, disabled }) => {
  return disabled
    ? COLORS.DISABLED_TEXT
    : design === "secondary"
    ? inverted
      ? "black"
      : "white"
    : inverted
    ? COLORS.PRIMARY
    : "white";
};

const backgroundColor: StyleCallback = ({ design, inverted, disabled }) => {
  return disabled
    ? COLORS.DISABLED
    : design === "secondary"
    ? inverted
      ? "white"
      : COLORS.SECONDARY
    : inverted
    ? "white"
    : COLORS.PRIMARY;
};
const border: StyleCallback = ({ design, inverted, disabled }) =>
  design !== "secondary" && inverted && !disabled
    ? `solid 1px ${COLORS.PRIMARY}`
    : undefined;
export default styled(padding(DesignedButton))`
  &&& {
    background-color: ${backgroundColor};
    color: ${color};
    border: ${border};
    &:focus,
    &:hover {
      opacity: 0.5;
      color: ${color};
    }
  }
`;
