enum ActionTypes {
  CHANGE_CHILD = "Child/CHANGE_CHILD",
  GET_CHILD = "Child/GET_CHILD",
  DELETE_CHILD = "Child/DELETE_CHILD",
  GET_CHILD_SUCCESS = "Child/GET_CHILD_SUCCESS",
  WRITE_CHILD = "Child/WRITE_CHILD",
  CHILD_SUCCESS = "Child/CHILD_SUCCESS",
  CHILD_ERROR = "Child/CHILD_ERROR",
}

export default ActionTypes;
