import React from "react";

import { Dimmer, Loader } from "semantic-ui-react";

const InlineLoader: React.FC = () => {
  return (
    <Dimmer active>
      <Loader />
    </Dimmer>
  );
};

export default InlineLoader;
