import { Reducer } from "redux";
import { ProfileChildState, ActionTypes } from "./profileChildTypes";

import actions from "./profileChildConstants";

const profileInitialState: ProfileChildState = {
  message: false,
  loading: false,
  profileChild: {
    id: "",
    email: "",
    userName: "",
    first_name: "",
    last_name: "",
    first_kana_name: "",
    last_kana_name: "",
    address1: "",
    address2: "",
    available_point: 0,
    city: "",
    postal_code: "",
    prefecture: "",
    tel_number: "",
  },
  error: undefined,
};

const profileChildReducer: Reducer<ProfileChildState, ActionTypes> = (
  state = profileInitialState,
  action
) => {
  switch (action.type) {
    case actions.UPDATE_PROFILE_CHILD:
    case actions.GET_PROFILE_CHILD:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PROFILE_CHILD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        message: false,
        profileChild: action.payload.profileChild,
      };
    case actions.UPDATE_PROFILE_CHILD_SUCCESS:
      return {
        loading: false,
        error: undefined,
        message: action.payload.message as string,
        profileChild: action.payload.profileChild,
      };
    case actions.PROFILE_CHILD_ERROR:
      return {
        ...state,
        loading: false,
        message: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default profileChildReducer;
