import { Reducer } from "redux";
import Actions from "./TransactionsConstants";
import {
  TransactionActionTypes,
  TransactionsState,
  SetPaginationAction,
} from "./TransactionsTypes";

const initialState = (): TransactionsState => ({
  searchCondition: {},
  pagination: {
    limit: 5,
    page: 1,
  },
});

export const transactions: Reducer<
  TransactionsState,
  TransactionActionTypes
> = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.SET_PAGINATION: {
      const pagination = (action as SetPaginationAction).payload.pagination;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    }
    case Actions.SET_SEARCH_CONDITION: {
      const searchCondition = action.payload.searchCondition;
      return {
        ...state,
        searchCondition: searchCondition
          ? {
              ...state.searchCondition,
              ...searchCondition,
            }
          : undefined,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      };
    }
    default:
      return state;
  }
};

export default transactions;
