import React, { useEffect } from "react";

import { Menu } from "semantic-ui-react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { withHeaderAndFooter } from "components/organisms/Header";

import { Link } from "react-router-dom";
import ROUTES from "utils/routes";

import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import MainHeader from "components/atoms/MainHeader";

import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import { BREAKPOINTS } from "utils/ComponentUtils";

const MenuStyled = styled(Menu)`
  &&& {
    width: calc(100% - 20px);
    margin: 10px;
    border: initial;
    @media (min-width: ${BREAKPOINTS.MOBILE}) {
      width: calc(60% - 20px);
      margin: auto;
      margin-top: 110px;
    }
  }
  &&&.ui.menu .item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 600;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }
  &&&.ui.vertical.menu {
    box-shadow: initial;
  }
  &&&.ui.vertical.menu .item:before {
    background: initial;
  }
`;

const MainHeaderStyled = styled(MainHeader)`
  &&& {
    @media (min-width: ${BREAKPOINTS.MOBILE}) {
      text-align: center;
      font-size: 24px;
    }
  }
`;

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const Settings: React.FC<RouteComponentProps> = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <PaddingGrid paddingleft={16} paddingright={16} centered>
      <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
        <PaddingColumn width={16} paddingleft={0}>
          <CustomBreadcrumbs {...props} />
        </PaddingColumn>
      </PaddingRow>
      <DefaultGrid.Row id="MainContents">
        <DefaultGrid.PaddingColumn width={16} padding={0}>
          <DefaultGrid>
            <DefaultGrid.Row id="TitleContainer" columns="equal">
              <DefaultGrid.Column id="TitleColumn" computer={16} mobile={7}>
                <MainHeaderStyled id="Title">設定</MainHeaderStyled>
              </DefaultGrid.Column>
              <DefaultGrid.Column />
            </DefaultGrid.Row>
            <MenuStyled vertical>
              <Menu.Item as={Link} to={ROUTES.PROFILE}>
                プロフィール
              </Menu.Item>
              <Menu.Item as={Link} to={ROUTES.CHANGE_EMAIL}>
                ログインメールアドレス変更
              </Menu.Item>
              <Menu.Item as={Link} to={ROUTES.DELETE_ACCOUNT}>
                退会
              </Menu.Item>
            </MenuStyled>
          </DefaultGrid>
        </DefaultGrid.PaddingColumn>
      </DefaultGrid.Row>
    </PaddingGrid>
  );
};

export default withRouter(withHeaderAndFooter(Settings));
