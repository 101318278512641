import { Reducer } from "redux";
import { ActionTypes, AddressState } from "./addressTypes";
import actions from "./addressConstants";

const addressInitialState: AddressState = {
  prefecture: "",
  city: "",
  area: "",
  loading: false,
  error: undefined,
};

const addressReducer: Reducer<AddressState, ActionTypes> = (
  state = addressInitialState,
  action
) => {
  switch (action.type) {
    case actions.GET_ADDRESS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        prefecture: action.payload.prefecture,
        city: action.payload.city,
        area: action.payload.area,
        loading: false,
      };
    case actions.GET_ADDRESS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default addressReducer;
