import { withHeaderAndFooter } from "components/organisms/Header";
import { State } from "interfaces/State";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useQuery } from "utils/ComponentUtils";
import ROUTES from "utils/routes";
import ChildUserPersonalIdentification from "./ChildUserPersonalIdentification";
import OrphanageUserPersonalIdentification from "./OrphanageUserPersonalIdentification";
import { PersonalIdentificationState } from "./PersonalIdentificationReducer";

// 小画面用共通Propsを提供する
export interface PersonalIdentificationProps {
  resetPasswordToken: string;
  message: string;
  userType: string | null;
}

const PersonalIdentification: React.FC = () => {
  const { message } = useSelector<State, PersonalIdentificationState>(
    (s) => s.personalIdentification
  );
  const query = useQuery();
  const resetPasswordToken = query.get("reset_password_token");
  const userType = query.get("user_type");

  if (resetPasswordToken) {
    if (userType === "orphanage_user") {
      return (
        <OrphanageUserPersonalIdentification
          resetPasswordToken={resetPasswordToken}
          message={message}
          userType={userType}
        />
      );
    } else {
      return (
        <ChildUserPersonalIdentification
          resetPasswordToken={resetPasswordToken}
          message={message}
          userType={userType}
        />
      );
    }
  } else {
    return <Redirect to={ROUTES.TOP} />;
  }
};

export default withHeaderAndFooter(PersonalIdentification);
