import { Reducer } from "redux";

import Actions from "./TransactionsConstants";
import { TransactionsState, TransactionActionTypes } from "./TransactionsTypes";

const initialState = (): TransactionsState => ({
  data: {
    donation_applications: [],
    total_num: 0,
    total_pages: 0,
  },
  loading: false,
  error: false,
  id: undefined,
  activePage: 1,
  receiver_message: "",
});

const transactions: Reducer<TransactionsState, TransactionActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case Actions.GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      };
    case Actions.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        data: {
          donation_applications: action.payload.data.donation_applications,
          total_num: action.payload.data.total_num,
          total_pages: action.payload.data.total_pages,
        },
        error: false,
        loading: false,
      };
    case Actions.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case Actions.COMPLETE_TRANSACTION:
      return {
        ...state,
        id: action.payload.id,
        receiver_message: action.payload.receiver_message,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default transactions;
