export function simulateAsyncCall(request: Record<string, string>) {
  return new Promise((resolve) => {
    setTimeout(() => {
      switch (request.method) {
        case "PATCH":
        case "POST":
          if (request.body) {
            resolve({ status: 200, message: "saved!" });
          }

          break;
        case "DELETE":
          resolve({ status: 200, message: "deleted" });
          break;
        case "GET":
          if (request.body === "asc") {
            resolve({
              status: 200,
              data: [
                {
                  id: 1,
                  image: "http://placekitten.com/400/200",
                  title: "title 1",
                  description: "This is a description.",
                },
                {
                  id: 2,
                  image: "http://placekitten.com/400/200",
                  title: "title 2",
                  description: "This is a description again!",
                },
              ],
            });
          } else if (request.body === "all") {
            resolve({
              status: 200,
              data: [
                {
                  points: 40,
                  image: "http://placekitten.com/400/200",
                  status: "delivered",
                  deliveryStatus: "delivered",
                  donationName: "donation name 1",
                  description: "This is a description again!",
                  donor: "ome henk",
                },
                {
                  points: 40,
                  image: "http://placekitten.com/400/200",
                  status: "delivered",
                  deliveryStatus: "delivered",
                  donationName: "donation name 1",
                  description: "This is a description again!",
                  donor: "ome henk",
                },
              ],
            });
          } else if (request.body === "before-delivery") {
            resolve({
              status: 200,
              data: [
                {
                  points: 50,
                  image: "http://placekitten.com/400/200",
                  status: "being delivered",
                  deliveryStatus: "delivered",
                  donationName: "donation name 2",
                  description: "This is a description somethig!",
                  donor: "ome henk",
                },
              ],
            });
          } else if (request.body === "being-delivered") {
            resolve({
              status: 200,
              data: [
                {
                  points: 60,
                  image: "http://placekitten.com/400/200",
                  status: "in limbo",
                  deliveryStatus: "delivered",
                  donationName: "donation name 3",
                  description: "This is a description hello",
                  donor: "ome sjaak",
                },
              ],
            });
          } else if (request.body === "finished") {
            resolve({
              status: 200,
              data: [
                {
                  points: 70,
                  image: "http://placekitten.com/400/200",
                  status: "finished",
                  deliveryStatus: "delivered",
                  donationName: "donation name 4",
                  description: "This is a description again there!",
                  donor: "tante corrie",
                },
              ],
            });
          } else if (request.body === "children") {
            resolve({
              status: 200,
              data: [
                {
                  name: "太郎",
                  kana_name: "らろ",
                  dob: "2020/12/20",
                  age: "16",
                  grade: "3",
                  email: "k.h@sun-asterisk.com",
                  account: "アカウント",
                  id: "1",
                },
              ],
            });
          } else if (request.body === "child") {
            resolve({
              status: 200,
              data: {
                name: "太郎",
                kana_name: "らろ",
                date_of_birth: "2020/12/20",
                email: "k.h@sun-asterisk.com",
                gender: "男性",
                id: "1",
              },
            });
            resolve({
              status: 200,
              data: [
                {
                  id: "1",
                  name: "太郎",
                  kana_name: "らろ",
                  dob: "2020/12/20",
                  age: "16",
                  grade: "3",
                  email: "k.h@sun-asterisk.com",
                  account: "アカウント",
                },
              ],
            });
          } else if (request.body === "profile") {
            resolve({
              status: 200,
              data: {
                id: "1",
                name: "太郎",
                kana_name: "らろ",
                postal_code: "114-0032",
                prefecture: "東京都",
                city: "品川区",
                address1: "外",
                address2: "１−２８−３４田たつ",
                person_in_charge: "太郎ちゃん",
                kana_person_in_charge: "たろうちゃん",
                tel_number: "080191291",
              },
            });
          } else {
            resolve({
              status: 200,
              data: [
                {
                  image: "http://placekitten.com/400/200",
                  title: "title 2",
                  description: "This is a description again!",
                },
                {
                  image: "http://placekitten.com/400/200",
                  title: "title 1",
                  description: "This is a description.",
                },
              ],
            });
          }
          break;
        default:
          resolve({
            status: 400,
            message: "Bad Request (invalid username or password)",
          });
      }
    }, 1000);
  });
}
