import React from "react";
import { Form, FormCheckboxProps, Radio } from "semantic-ui-react";

type CheckBoxProps = FormCheckboxProps;

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  return (
    <Form.Checkbox control={Radio} style={{ marginBottom: 0 }} {...props} />
  );
};
