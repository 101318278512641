import { Reducer } from "redux";

import Actions from "./ChildrenListConstants";
import {
  ChildrenListPageState,
  SetPaginationAction,
} from "./ChildrenListTypes";

const initialState = (): ChildrenListPageState => ({
  pagination: {
    limit: 5,
    page: 1,
  },
});

export const childrenListPage: Reducer<
  ChildrenListPageState,
  SetPaginationAction
> = (state = initialState(), action) => {
  switch (action.type) {
    case Actions.SET_PAGINATION: {
      const pagination = action.payload.pagination;
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...pagination,
        },
      };
    }
    default:
      return state;
  }
};

export default childrenListPage;
