import { TransactionPatchState } from "api/v1/Transactions";
import { Payload } from "interfaces/Action";
import { Action } from "redux";
import actions from "./TransactionEditConstants";
import { TransactionEditState } from "./TransactionEditReducer";

export interface SetLoadingAction
  extends Action<actions.SET_LOADING>,
    Payload<Pick<TransactionEditState, "loading">> {}
export interface GetTransactionAction
  extends Action<actions.GET_TRANSACTION>,
    Payload<{ id: string }> {}
export interface SetTransactionAction
  extends Action<actions.SET_TRANSACTION>,
    Payload<Pick<TransactionEditState, "transaction">> {}
export type GetOrphanageAction = Action<actions.GET_ORPHANAGE>;
export interface SetOrphanageAction
  extends Action<actions.SET_ORPHANAGE>,
    Payload<Pick<TransactionEditState, "orphanage">> {}
export interface SubmitAction
  extends Action<actions.SUBMIT>,
    Payload<{ id: string; values: TransactionPatchState }> {}

const transactionEditActionCreator = {
  setLoading: (
    loading: SetLoadingAction["payload"]["loading"]
  ): SetLoadingAction => ({ type: actions.SET_LOADING, payload: { loading } }),
  getTransaction: (
    id: GetTransactionAction["payload"]["id"]
  ): GetTransactionAction => ({
    type: actions.GET_TRANSACTION,
    payload: { id },
  }),
  setTransaction: (
    transaction: SetTransactionAction["payload"]["transaction"]
  ): SetTransactionAction => ({
    type: actions.SET_TRANSACTION,
    payload: { transaction },
  }),
  getOrphanage: (): GetOrphanageAction => ({ type: actions.GET_ORPHANAGE }),
  setOrphanage: (
    orphanage: SetOrphanageAction["payload"]["orphanage"]
  ): SetOrphanageAction => ({
    type: actions.SET_ORPHANAGE,
    payload: { orphanage },
  }),
  submit: (payload: SubmitAction["payload"]): SubmitAction => ({
    type: actions.SUBMIT,
    payload,
  }),
};

export type Actions =
  | SetLoadingAction
  | GetTransactionAction
  | SetTransactionAction
  | GetOrphanageAction
  | SetOrphanageAction
  | SubmitAction;

export default transactionEditActionCreator;
