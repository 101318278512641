import { call, put, takeLatest } from "redux-saga/effects";

import { ChangePasswordAction } from "./ChangePasswordTypes";
import actions from "./ChangePasswordConstants";
import changePasswordActionsCreators from "./ChangePasswordActions";

import request from "utils/request";
import { requestHeaders } from "utils/request";

import serialize from "serialize-javascript";

import requestErrorCheck from "utils/requestErrorCheck";
import { SCOPE } from "containers/App/GlobalTypes";
import { push } from "connected-react-router";
import ROUTES from "utils/routes";

function* changePasswordSaga(params: ChangePasswordAction) {
  try {
    let requestURL = "";

    if (params.payload.scope === SCOPE.ORPHANAGE) {
      requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user/password`;
    } else {
      requestURL = `${process.env.REACT_APP_API_URL}/child_user/password`;
    }

    yield call(request, requestURL, {
      method: "PATCH",
      ...requestHeaders,
      body: serialize(params.payload),
    });

    yield put(changePasswordActionsCreators.changePasswordSuccess());
    yield alert("パスワードを保存しました");
    yield put(push(ROUTES.LOGIN));
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(changePasswordActionsCreators.changePasswordError(error));
  }
}

export default function* changePasswordRootSaga() {
  yield takeLatest(actions.CHANGE_PASSWORD, changePasswordSaga);
}
