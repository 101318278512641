import { globalsActionsCreators } from "modules/Globals/GlobalsActions";
import { call, put, takeLatest, select } from "redux-saga/effects";
import request, {
  AsyncReturnType,
  requestHeadersAuthorized,
} from "utils/request";
import actions from "./DonationConstants";
import { GetDonationAction, GetDonationResponse } from "./DonationType";
import { donationActionsCreators } from "./DonationAction";
import { accessToken } from "containers/App/GlobalSelectors";

export const donationSaga = function* () {
  yield takeLatest(actions.GET_DONATION, getDonationSaga);
};

export const getDonationSaga = function* (action: GetDonationAction) {
  try {
    yield put(globalsActionsCreators.setLoading(true));
    const responseAccessToken = yield select(accessToken);
    const donation: AsyncReturnType<typeof getDonation> = yield call(
      getDonation,
      action.payload.id,
      responseAccessToken
    );
    yield put(donationActionsCreators.setDonation(donation.data));
    yield put(globalsActionsCreators.setLoading(false));
  } catch (e) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};
const getDonation = async (id: number, responseAccessToken: string) => {
  return request(process.env.REACT_APP_API_URL + `/donations/${id}`, {
    method: "GET",
    ...requestHeadersAuthorized(responseAccessToken),
  }) as Promise<GetDonationResponse>;
};
