import InfoMessage from "components/atoms/InfoMessage";
import { State } from "interfaces/State";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Tab } from "semantic-ui-react";
import padding from "styles/Padding";
import { LogInState } from "containers/App/GlobalTypes";
import { bindActionCreators } from "redux";
import { withHeaderAndFooter } from "components/organisms/Header";
import CustomBreadcrumbs from "components/organisms/CustomBreadcrumbs";
import MainHeader from "components/atoms/MainHeader";
import DefaultGrid from "styles/DefaultGrid";
import styled from "styled-components";
import DesignedButtonPager from "components/organisms/DesignedButtonPager";
import LikeCards from "components/organisms/LikesCard";
import { LikedChildren } from "../../../modules/db/ChildUser/LikedChildrenType";
import { likesActionsCreators as actionCreators } from "modules/db/Likes/LikesAction";
import { Donation } from "interfaces/db/Donation";

const PaddingGrid = padding(DefaultGrid);
const { PaddingRow, PaddingColumn } = DefaultGrid;

const PaneStyled = styled(Tab.Pane)`
  &&&.ui.segment {
    border: 0;
    box-shadow: initial;
    padding: 0 0 20px 0;
  }
`;

const Likes: React.FC<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);
  const { profileChild } = useSelector((state: State) => state.profileChild);
  const user_id = Number(profileChild.id);
  const { data, total_pages, loading, error } = useSelector(
    (state: State) => state.likes
  );
  const pagination = useSelector((state: State) => state.likes.pagination);
  let childrenNames = [""];

  const actions = useMemo(() => {
    return {
      likes: bindActionCreators(actionCreators, dispatch),
    };
  }, [dispatch]);

  useMemo(() => {
    if (data.length === 0) {
      if (pagination.page) {
        pagination.page !== 1 && pagination.page--;
      }
    }
    if (user_id) {
      if (scope === "child_user") {
        dispatch(actions.likes.getLikesChild(scope, pagination, user_id));
      }
    }
    if (scope === "orphanage_user") {
      dispatch(actions.likes.getLikesOrphanage(scope, pagination));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, pagination]);

  return (
    <>
      <PaddingGrid i="likes" paddingleft={16} paddingright={16} centered>
        <PaddingRow id="BreadcrumbsRow" paddingtop={8}>
          <PaddingColumn width={16} paddingleft={0}>
            <CustomBreadcrumbs {...props} />
          </PaddingColumn>
        </PaddingRow>

        <DefaultGrid.Row id="MainContents" centered>
          <DefaultGrid.PaddingColumn
            mobile={16}
            tablet={12}
            computer={8}
            padding={0}
          >
            <DefaultGrid>
              <DefaultGrid.Row id="TitleContainer" columns="equal">
                <DefaultGrid.Column id="TitleColumn" compute={16} mobile={16}>
                  <MainHeader id="Title">お気に入りリスト</MainHeader>
                </DefaultGrid.Column>
                <DefaultGrid.Column />
              </DefaultGrid.Row>
              <DefaultGrid.PaddingRow id="ControllsContainer" padding={0}>
                <DefaultGrid.PaddingColumn
                  id="ControllsColumn"
                  width={16}
                  padding={0}
                >
                  {error && (
                    <InfoMessage severity="error" message={error.toString()} />
                  )}

                  <PaneStyled attached={false} loading={loading}>
                    {data === undefined || data.length === 0
                      ? "お気に入りが登録されていません。"
                      : data.map((donation: Donation) => (
                          <>
                            {(childrenNames = [""])}
                            {() => {
                              if (scope === "orphanage_user") {
                                donation.liked_children.map(
                                  (child: LikedChildren) =>
                                    childrenNames.push(child.full_name)
                                );
                              }
                            }}

                            {/* 画像の有無で条件分岐 */}
                            {donation.images[0] &&
                            donation.images[0].thumbnail_url ? (
                              <LikeCards
                                user_id={user_id}
                                scope={scope}
                                donation={donation}
                                image={donation.images[0].thumbnail_url}
                              />
                            ) : (
                              <LikeCards
                                user_id={user_id}
                                scope={scope}
                                donation={donation}
                                image={""}
                              />
                            )}
                          </>
                        ))}
                  </PaneStyled>
                </DefaultGrid.PaddingColumn>
              </DefaultGrid.PaddingRow>
            </DefaultGrid>
          </DefaultGrid.PaddingColumn>
        </DefaultGrid.Row>

        {data !== undefined && data.length !== 0 && (
          <DesignedButtonPager
            activePage={pagination.page}
            totalPages={total_pages}
            onPageChange={(e, { activePage }) => {
              if (activePage) {
                dispatch(
                  actionCreators.setPagination({
                    ...pagination,
                    page: +activePage,
                  })
                );
                window.scroll(0, 0); //表示位置をページ冒頭に戻す
              }
            }}
          />
        )}
      </PaddingGrid>
    </>
  );
};

export default withHeaderAndFooter(Likes);
