import { CheckBox } from "components/atoms/CheckBox";
import DesignedButton from "components/atoms/DesignedButton";
import { withHeaderAndFooter } from "components/organisms/Header";
import { Formik, FormikProps } from "formik";
import { State } from "interfaces/State";
import { childrenListActionsCreators } from "modules/db/ChildrenList/ChildrenListActions";
import { ChildUser } from "modules/db/ChildUser/ChildUserType";
import { donationActionsCreators } from "modules/db/Donation/DonationAction";
import { DonationState } from "modules/db/Donation/DonationType";
import { GlobalsState } from "modules/Globals/GlobalsTypes";
import React, { ComponentProps, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { Form, Header, Loader } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import padding from "styles/Padding";
import * as Yup from "yup";
import DonationInfo from "../parts/DonationInfo/DonationInfo";
import { donationApplicationActionsCreators } from "./DonationApplicationActions";
import {
  INITIAL_FORM_VALUES,
  DONATION_APPLICATION_CHILD_LABEL_MAP,
} from "./DonationApplicationConstants";
import { DonationApplicationInputState } from "./DonationApplicationTypes";

import { Breadcrumbs } from "components/organisms/Breadcrumbs";
import {
  ApplicationChildRows,
  ApplicationRows,
} from "./DonationApplicationChildRows";
import MainHeader from "components/atoms/MainHeader";
import { size } from "styles/Size";
import Validate from "utils/Validate";
import { LogInState } from "containers/App/GlobalTypes";
import ERROR_MESSAGES from "utils/errorMessages";
import TextArea from "components/atoms/TextArea";

const PaddingGrid = padding(DefaultGrid);
const FluidForm = styled(Form)`
  &&& {
    width: 100%;
  }
`;
const ColumnStyled = styled(DefaultGrid.Column)`
  &&& {
    margin-bottom: 10px !important;
  }
`;
const ImageRow = styled(DefaultGrid.Row)`
  max-width: 370px;
`;

export interface DonationApplicationParams {
  [k: string]: string;
  id: string;
}

const initialApplicationSchema = Yup.object().shape({
  name: Validate.receiver_user_name,
  kana: Validate.receiver_user_kana,
});
const initialChildSchema = Yup.object().shape({});
const pendingYupSchema = Yup.object().shape({
  name: Validate.receiver_user_name,
  kana: Validate.receiver_user_kana,
});
const childadditionalYupSchema = Yup.object().shape({
  postal_code: Validate.postal_code,
  prefecture: Validate.prefecture,
  city: Validate.city,
  address1: Validate.address1,
  tel_number: Validate.tel_number,
  free_comment: Validate.free_comment,
});
const additionalYupSchema = Yup.object().shape({
  name: Validate.receiver_user_name,
  kana: Validate.receiver_user_kana,
  postal_code: Validate.postal_code,
  prefecture: Validate.prefecture,
  city: Validate.city,
  address1: Validate.address1,
  tel_number: Validate.tel_number,
  free_comment: Validate.free_comment,
});

const DonationApplication: React.FC<
  RouteComponentProps<DonationApplicationParams>
> = (props) => {
  const dispatch = useDispatch();
  const { data: donation, loading } = useSelector<State, DonationState>(
    (state) => state.donation
  );
  const globals = useSelector<State, GlobalsState>((state) => state.globals);

  const [profileData, setProfileData] = useState<ChildUser>();
  const [inputtedValues] = useState<DonationApplicationInputState>();
  const [initialValues, setInitialValues] =
    useState<DonationApplicationInputState>(INITIAL_FORM_VALUES);
  const [showError, setShowError] = useState(false);

  const [childValue, setChildValue] = useState<ChildUser>();
  const [checkFlag, setCheckFlag] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [free_comment, setFreeComment] = useState<string | null>(null);
  const { scope } = useSelector<State, LogInState>((state) => state.logIn);

  const [applicationSchema, setApplicationSchema] = useState<Yup.ObjectSchema>(
    initialApplicationSchema
  );
  const [childSchema, setChildSchema] =
    useState<Yup.ObjectSchema>(initialChildSchema);

  const [deliveryMethod, setDeliveryMethod] = useState<String>("");
  const [backToEditScreenFlag, setBackToEditScreenFlag] =
    useState<boolean>(false);

  const [formValues, setFormValues] = useState<ChildUser | undefined>();

  useEffect(() => {
    const sessionFormValues = sessionStorage.getItem("form_values");
    const sessionProfileData = sessionStorage.getItem("profileData");

    if (sessionProfileData !== null && sessionFormValues !== null) {
      const sessionFormValuesParse = JSON.parse(sessionFormValues);
      setBackToEditScreenFlag(false);
      setProfileData(JSON.parse(sessionProfileData));
      setFormValues(sessionFormValuesParse);
      setFreeComment(sessionFormValuesParse.free_comment);
      sessionStorage.removeItem("form_values");
      sessionStorage.removeItem("profileData");
    } else if (childValue) {
      setProfileData(childValue);
    }
  }, [childValue]);

  // MEMO: 申し込み確認画面から戻ってきたら、申し込み確認画面から戻ったことを判断するflagをsessionを基に生成する
  useEffect(() => {
    const flag = sessionStorage.getItem("backToEditScreenFlag");
    const sessionDeliveryMethod = sessionStorage.getItem("deliveryMethod");

    if (flag === "true") {
      setBackToEditScreenFlag(true);
      sessionStorage.removeItem("backToEditScreenFlag");

      if (sessionDeliveryMethod !== null)
        setDeliveryMethod(JSON.parse(sessionDeliveryMethod));
    }
  }, []);

  useEffect(() => {
    // MEMO: 申し込み確認画面から戻ってきたら、送付先情報選択のトグルを選択可能状態にする。
    if (backToEditScreenFlag) setCheckFlag(false);

    if (
      profileData !== undefined &&
      deliveryMethod === "" &&
      donation?.register_later !== true
    )
      setShowError(true);
    else setShowError(false);

    // MEMO:
    // 1つのsetInitialValues()のなかでdeliveryMethodを三項演算子で条件分岐すると可読性が悪い。
    // 重複するコードができてしまうが、あえてifで分岐している。
    if (profileData && deliveryMethod === "sameApplicant") {
      setApplicationSchema(additionalYupSchema);
      setChildSchema(childadditionalYupSchema);
      setInitialValues({
        child_user_id: profileData.id,
        name: profileData.last_name + profileData.first_name,
        kana: profileData.last_kana_name + profileData.first_kana_name,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        first_kana_name: profileData.first_kana_name,
        last_kana_name: profileData.last_kana_name,
        postal_code: profileData.orphanage.postal_code || "",
        prefecture: profileData.orphanage.prefecture || "",
        city: profileData.orphanage.city || "",
        address1: profileData.orphanage.address1 || "",
        address2: profileData.orphanage.address2 || "",
        receiver_place_name: profileData.orphanage.name || "",
        tel_number: profileData.orphanage.tel_number || "",
        free_comment: free_comment || "",
        sameApplicant: true,
        sameAddress: false,
        pending: false,
      });
    } else if (profileData && deliveryMethod === "sameAddress") {
      setApplicationSchema(additionalYupSchema);
      setChildSchema(childadditionalYupSchema);
      setInitialValues({
        child_user_id: profileData.id,
        name: profileData.last_name + profileData.first_name,
        kana: profileData.last_kana_name + profileData.first_kana_name,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        first_kana_name: profileData.first_kana_name,
        last_kana_name: profileData.last_kana_name,
        postal_code:
          formValues && formValues.postal_code !== null
            ? formValues.postal_code
            : inputtedValues?.postal_code || profileData.postal_code || "",
        prefecture:
          formValues && formValues.prefecture !== null
            ? formValues.prefecture
            : inputtedValues?.prefecture || profileData.prefecture || "",
        city:
          formValues && formValues.city !== null
            ? formValues.city
            : inputtedValues?.city || profileData.city || "",
        address1:
          formValues && formValues.address1 !== null
            ? formValues.address1
            : inputtedValues?.address1 || profileData.address1 || "",
        address2:
          formValues && formValues.address2 !== null
            ? formValues.address2
            : inputtedValues?.address2 || profileData.address2 || "",
        receiver_place_name:
          formValues && formValues.receiver_place_name !== (null || undefined)
            ? formValues.receiver_place_name
            : inputtedValues?.receiver_place_name ||
              profileData.receiver_place_name ||
              "",
        tel_number:
          formValues && formValues.tel_number !== null
            ? formValues.tel_number
            : inputtedValues?.tel_number || profileData.tel_number || "",
        free_comment: free_comment || "",
        sameApplicant: false,
        sameAddress: true,
        pending: false,
      });
      setFormValues(undefined);
    } else {
      // MEMO: pending時、受取人だけ選択時の条件分岐
      if (profileData) {
        setInitialValues({
          child_user_id: profileData.id,
          name: profileData.last_name + profileData.first_name,
          kana: profileData.last_kana_name + profileData.first_kana_name,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          first_kana_name: profileData.first_kana_name,
          last_kana_name: profileData.last_kana_name,
          postal_code: "",
          prefecture: "",
          city: "",
          address1: "",
          address2: "",
          receiver_place_name: "",
          tel_number: "",
          free_comment: free_comment || "",
          sameApplicant: false,
          sameAddress: false,
          pending: deliveryMethod === "pending" ? true : false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, scope, deliveryMethod]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { id } = props.match.params;

  const actions = useMemo(() => {
    return {
      childrenList: bindActionCreators(childrenListActionsCreators, dispatch),
      donation: bindActionCreators(donationActionsCreators, dispatch),
      ...bindActionCreators(donationApplicationActionsCreators, dispatch),
    };
  }, [dispatch]);

  // donationを取得していない時は取得する
  useEffect(() => {
    if (!donation) {
      actions.donation.getDonation(+id);
    }
  }, [donation, actions.donation, id]);

  useEffect(() => {
    let pagination = {
      page: undefined,
      limit: undefined,
    };
    actions.childrenList.getChildrenList(pagination);

    let total_pages = 0;
    return () => {
      actions.childrenList.getChildrenListSuccess(undefined, total_pages);
    };
  }, [actions]);

  const handleSubmit = async (formValues: DonationApplicationInputState) => {
    setApplicationSchema(additionalYupSchema);
    setChildSchema(childadditionalYupSchema);
    if (
      !formValues.sameApplicant &&
      !formValues.sameAddress &&
      !formValues.pending
    ) {
      setShowError(true);
    } else {
      actions.confirmation(props.match.params, formValues);
      sessionStorage.setItem("form_values", JSON.stringify(formValues));
      sessionStorage.setItem("profileData", JSON.stringify(profileData));
      sessionStorage.setItem("deliveryMethod", JSON.stringify(deliveryMethod));
    }
  };

  return (
    <DefaultGrid id="DonationApplication" centered>
      {!loading && donation ? (
        <>
          <Row>
            <DefaultGrid.Column width={16}>
              <Breadcrumbs {...props} />
            </DefaultGrid.Column>
          </Row>

          <Row id="MainContentRow">
            <ResizeableColumn mobile={16} tablet={10} computer={6}>
              <FluidGrid id="MainContentGrid">
                <Row id="TitleRow" centered>
                  <MainHeader>申込み内容を入力</MainHeader>
                </Row>
                <PaddingRow
                  id="BodyRow"
                  paddingtop={0}
                  paddingbottom={0}
                  centered
                >
                  <PaddingGrid paddingtop={16}>
                    <ImageRow id="Images" centered>
                      <DonationInfo donation={donation} />
                    </ImageRow>
                  </PaddingGrid>
                </PaddingRow>
                <DefaultGrid.Row style={{ paddingBottom: 0 }}>
                  <Header as="h2">{donation?.name}</Header>
                </DefaultGrid.Row>
                <PaddingRow paddingleft={5}>
                  <Header as="h3">{donation?.point + "pt"}</Header>
                </PaddingRow>
                <PaddingRow id="DestinationInfoRow" paddingtop={0}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={
                      scope === "orphanage_user"
                        ? applicationSchema
                        : childSchema
                    }
                  >
                    {(props) => {
                      const isLoading = globals.isLoading || props.isSubmitting;

                      const handleChangeSameApplicant: FormikProps<DonationApplicationInputState>["handleChange"] =
                        (e: React.FormEvent<HTMLInputElement>) => {
                          setApplicationSchema(additionalYupSchema);
                          setChildSchema(childadditionalYupSchema);
                          setDeliveryMethod("sameApplicant");
                          setFormValues(undefined);
                          if (profileData && !backToEditScreenFlag) {
                            setInitialValues({
                              ...props.values,
                              child_user_id: profileData.id,
                              first_name: profileData.first_name,
                              last_name: profileData.last_name,
                              first_kana_name: profileData.first_kana_name,
                              last_kana_name: profileData.last_kana_name,
                              postal_code: profileData.orphanage.postal_code,
                              prefecture: profileData.orphanage.prefecture,
                              city: profileData.orphanage.city,
                              address1: profileData.orphanage.address1,
                              address2: profileData.orphanage.address2,
                              receiver_place_name: profileData.orphanage.name,
                              tel_number: profileData.orphanage.tel_number,
                              sameApplicant: true,
                              sameAddress: false,
                              pending: false,
                            });
                            setShowError(false);
                          }
                          props.handleChange(e);
                        };

                      const handleChangeSameAddress: FormikProps<DonationApplicationInputState>["handleChange"] =
                        (e: React.FormEvent<HTMLInputElement>) => {
                          setApplicationSchema(additionalYupSchema);
                          setChildSchema(childadditionalYupSchema);
                          setDeliveryMethod("sameAddress");
                          setFormValues(undefined);
                          if (profileData && !backToEditScreenFlag) {
                            setInitialValues({
                              ...props.values,
                              child_user_id: profileData.id,
                              first_name: profileData.first_name,
                              last_name: profileData.last_name,
                              first_kana_name: profileData.first_kana_name,
                              last_kana_name: profileData.last_kana_name,
                              postal_code:
                                profileData.postal_code !== null
                                  ? profileData.postal_code
                                  : inputtedValues?.postal_code || "",
                              prefecture:
                                profileData.prefecture !== null
                                  ? profileData.prefecture
                                  : inputtedValues?.prefecture || "",
                              city:
                                profileData.city !== null
                                  ? profileData.city
                                  : inputtedValues?.city || "",
                              address1:
                                profileData.address1 !== null
                                  ? profileData.address1
                                  : inputtedValues?.address1 || "",
                              address2:
                                profileData.address2 !== null
                                  ? profileData.address2
                                  : inputtedValues?.address2 || "",
                              receiver_place_name:
                                profileData.receiver_place_name !==
                                (null || undefined)
                                  ? profileData.receiver_place_name
                                  : inputtedValues?.receiver_place_name || "",
                              tel_number:
                                profileData.tel_number !== null
                                  ? profileData.tel_number
                                  : inputtedValues?.tel_number || "",
                              sameApplicant: false,
                              sameAddress: true,
                              pending: false,
                            });
                            setShowError(false);
                          }
                          props.handleChange(e);
                        };

                      const handleChangePending: FormikProps<DonationApplicationInputState>["handleChange"] =
                        (e: React.FormEvent<HTMLInputElement>) => {
                          setApplicationSchema(pendingYupSchema);
                          setChildSchema(Yup.object().shape({}));
                          setDeliveryMethod("pending");
                          if (profileData) {
                            setInitialValues({
                              ...props.values,
                              child_user_id: profileData.id,
                              first_name: profileData.first_name,
                              last_name: profileData.last_name,
                              first_kana_name: profileData.first_kana_name,
                              last_kana_name: profileData.last_kana_name,
                              postal_code: "",
                              prefecture: "",
                              city: "",
                              address1: "",
                              address2: "",
                              receiver_place_name: "",
                              tel_number: "",
                              sameApplicant: false,
                              sameAddress: false,
                              pending: true,
                            });
                            setShowError(false);
                          }
                          props.handleChange(e);
                        };

                      return (
                        <FluidForm onSubmit={props.handleSubmit}>
                          <ApplicationChildRows
                            {...props}
                            isLoading={isLoading}
                            setChildValue={setChildValue}
                            setCheckFlag={setCheckFlag}
                            donationPoint={donation?.point}
                            donationFledgedYear={donation?.fledged_year}
                            donationTags={donation?.tags}
                            isDisabled={isDisabled}
                            setIsDisabled={setIsDisabled}
                            profileData={profileData}
                          />
                          <Row style={{ paddingTop: 25 }}>
                            <DefaultColumn>
                              <SubHeader
                                id="DestinationInfoSubHeader"
                                style={{ paddingLeft: "1rem" }}
                              >
                                送付先情報
                              </SubHeader>
                            </DefaultColumn>
                          </Row>
                          <DefaultGrid>
                            <DefaultGrid.Row
                              id="form-input-control-error-email"
                              name="acceptTerms"
                              error={{
                                content: "Please enter a valid email address",
                                pointing: "below",
                              }}
                            >
                              {!donation.register_later && (
                                <ColumnStyled mobile={16} computer={5}>
                                  <CheckBox
                                    id="sameApplicant"
                                    name="sameApplicant"
                                    checked={
                                      props.values.sameApplicant === true
                                    }
                                    onChange={handleChangeSameApplicant}
                                    label={
                                      DONATION_APPLICATION_CHILD_LABEL_MAP[
                                        "sameApplicant"
                                      ]
                                    }
                                    disabled={checkFlag || isLoading}
                                  />
                                </ColumnStyled>
                              )}
                              {!donation.register_later && (
                                <ColumnStyled mobile={16} computer={6}>
                                  <CheckBox
                                    id="sameAddress"
                                    name="sameAddress"
                                    checked={props.values.sameAddress === true}
                                    onChange={handleChangeSameAddress}
                                    label={
                                      DONATION_APPLICATION_CHILD_LABEL_MAP[
                                        "sameAddress"
                                      ]
                                    }
                                    disabled={checkFlag || isLoading}
                                  />
                                </ColumnStyled>
                              )}

                              {donation.register_later && (
                                <>
                                  <CheckBox
                                    id="pending"
                                    name="pending"
                                    /*
                                    checked={
                                      props.values.pending === true
                                    }
                                    */
                                    onChange={handleChangePending}
                                    style={{ display: "none" }}
                                  />
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "16px",
                                      paddingLeft: "1rem",
                                    }}
                                  >
                                    あとから「申込履歴」より入力してください
                                  </p>
                                </>
                              )}

                              {showError && (
                                <p style={{ color: "red", fontSize: "14px" }}>
                                  {ERROR_MESSAGES.DESTINATION_ERROR}
                                </p>
                              )}
                            </DefaultGrid.Row>
                            {props.values.sameAddress && (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "16px",
                                  paddingLeft: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                ※確実に受け取れる住所の記載をお願いします
                              </p>
                            )}
                          </DefaultGrid>

                          {props.values.sameAddress && (
                            <ApplicationRows
                              {...props}
                              isLoading={isLoading}
                              setChildValue={setChildValue}
                              setCheckFlag={setCheckFlag}
                            />
                          )}
                          <Row style={{ paddingTop: 25 }}>
                            <DefaultColumn>
                              <SubHeader style={{ paddingLeft: "1rem" }}>
                                備考
                              </SubHeader>
                            </DefaultColumn>
                          </Row>
                          <DefaultGrid>
                            <DefaultGrid.Row>
                              {donation?.comment == null ? (
                                <>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      paddingLeft: "1rem",
                                    }}
                                  >
                                    特になし
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    paddingLeft: "1rem",
                                  }}
                                >
                                  {donation?.comment}
                                </p>
                              )}
                            </DefaultGrid.Row>
                          </DefaultGrid>
                          <Row style={{ paddingTop: 25 }}>
                            <DefaultColumn>
                              <SubHeader style={{ paddingLeft: "1rem" }}>
                                コメント
                              </SubHeader>
                            </DefaultColumn>
                          </Row>
                          <DefaultGrid>
                            <DefaultGrid.Row centered>
                              <DefaultGrid.Column width={16}>
                                <Form.Group widths="equal">
                                  <TextArea
                                    id="free_comment"
                                    name="free_comment"
                                    error={
                                      props.touched.free_comment &&
                                      props.errors.free_comment
                                        ? props.errors.free_comment
                                        : undefined
                                    }
                                    value={
                                      free_comment !== null
                                        ? free_comment
                                        : donation?.free_comment || ""
                                    }
                                    onChange={(e) => {
                                      setFreeComment(e.currentTarget.value);
                                      props.handleChange(e);
                                    }}
                                    onBlur={props.handleBlur}
                                    style={{ height: 130 }}
                                  />
                                </Form.Group>
                              </DefaultGrid.Column>
                            </DefaultGrid.Row>
                          </DefaultGrid>

                          <PaddingRow
                            id="DestinationInfoRow"
                            paddingtop={30}
                            style={{ width: 255, margin: "auto" }}
                          >
                            <Row>
                              <Column width={16} textAlign="center">
                                {donation.register_later && (
                                  <ResizeableButton
                                    paddingtop={20}
                                    paddingbottom={20}
                                    type="submit"
                                    onClick={handleChangePending}
                                    disabled={isLoading || isDisabled}
                                    loading={isLoading}
                                    fluid
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    確認する
                                  </ResizeableButton>
                                )}
                                {!donation.register_later && (
                                  <ResizeableButton
                                    paddingtop={20}
                                    paddingbottom={20}
                                    type="submit"
                                    disabled={isLoading || isDisabled}
                                    loading={isLoading}
                                    fluid
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    確認する
                                  </ResizeableButton>
                                )}
                              </Column>
                            </Row>
                          </PaddingRow>
                        </FluidForm>
                      );
                    }}
                  </Formik>
                </PaddingRow>
              </FluidGrid>
            </ResizeableColumn>
          </Row>
        </>
      ) : (
        <Loader active />
      )}
    </DefaultGrid>
  );
};

export default withHeaderAndFooter(DonationApplication);

const ResizeableButton = size(DesignedButton);

const { Row, Column, PaddingRow, PaddingColumn } = DefaultGrid;

const ResizeableGrid = size(padding(DefaultGrid));
const ResizeableColumn = size(Column);
const FluidGrid: React.FC<ComponentProps<typeof ResizeableGrid>> = (props) => (
  <ResizeableGrid {...props} size={{ ...props.size, width: "100%" }} />
);
const DefaultColumn: typeof PaddingColumn = (props) => (
  <PaddingColumn padding={0} {...props} />
);
const SubHeader = styled.h2`
  font-size: 1.2rem;
`;
