import actions from "./registerConstants";

import {
  Registration,
  RegisterAction,
  RegisterSuccessAction,
  RegisterErrorAction,
} from "./registerTypes";

const register = (registration: Registration): RegisterAction => {
  return {
    type: actions.REGISTER,
    payload: {
      registration,
    },
  };
};

const registerSuccess = (): RegisterSuccessAction => {
  return {
    type: actions.REGISTER_SUCCESS,
  };
};

const registerError = (error: Error): RegisterErrorAction => {
  return {
    type: actions.REGISTER_ERROR,
    payload: {
      error,
    },
  };
};

const RegisterCreators = {
  register,
  registerSuccess,
  registerError,
};

export default RegisterCreators;
