import { Action } from "redux";
import actions from "./TransactionDetailConstants";
import { Payload } from "interfaces/Action";
import { TransactionDetailState } from "./TransactionDetailReducer";

export interface SetLoadingAction
  extends Action<actions.SET_LOADING>,
    Payload<Pick<TransactionDetailState, "loading">> {}
export interface GetTransactionAction
  extends Action<actions.GET_TRANSACTION>,
    Payload<{ id: string }> {}
export interface SetTransactionAction
  extends Action<actions.SET_TRANSACTION>,
    Payload<Pick<TransactionDetailState, "transaction">> {}

const transactionDetailActionCreator = {
  setLoading: (
    loading: SetLoadingAction["payload"]["loading"]
  ): SetLoadingAction => ({ type: actions.SET_LOADING, payload: { loading } }),
  getTransaction: (
    id: GetTransactionAction["payload"]["id"]
  ): GetTransactionAction => ({
    type: actions.GET_TRANSACTION,
    payload: { id },
  }),
  setTransaction: (
    transaction: SetTransactionAction["payload"]["transaction"]
  ): SetTransactionAction => ({
    type: actions.SET_TRANSACTION,
    payload: { transaction },
  }),
};

export type Actions =
  | SetLoadingAction
  | GetTransactionAction
  | SetTransactionAction;

export default transactionDetailActionCreator;
