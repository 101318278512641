import { call, put, select, takeLatest } from "redux-saga/effects";

import { LikeAction } from "./LikeTypes";
import actions from "./LikeConstants";
import LikeCreators from "./LikeActions";

import requestErrorCheck from "utils/requestErrorCheck";
import request, { requestHeadersAuthorized } from "utils/request";
import { accessToken } from "containers/App/GlobalSelectors";

function* LikeSaga(params: LikeAction) {
  try {
    // use query string to pass the child_user_id and donation_id
    const requestURL =
      `${process.env.REACT_APP_API_URL}/likes` +
      `?child_user_id=${params.payload.child_user_id}&donation_id=${params.payload.donation_id}`;

    const responseAccessToken = yield select(accessToken);

    yield call(request, requestURL, {
      method: params.payload.method,
      ...requestHeadersAuthorized(responseAccessToken),
    });

    yield put(LikeCreators.LikeSuccess());
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(LikeCreators.LikeError(error));
  }
}

export default function* LikeRootSaga() {
  yield takeLatest(actions.REQUEST_LIKE, LikeSaga);
}
