import React, { ComponentProps } from "react";
import { Icon, Input, StrictInputProps } from "semantic-ui-react";
import { COLORS } from "utils/ComponentUtils";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
  &&& {
    color: ${COLORS.PRIMARY};
    pointer-events: cursor;
  }
`;

const Styledinput = styled(Input)`
  &&& {
    border-radius: 4px;
  }
`;

interface SearchBarProps
  extends StrictInputProps,
    Omit<ComponentProps<"input">, keyof StrictInputProps | "as"> {
  iconProps?: ComponentProps<"i">;
}

const SearchBar: React.FC<SearchBarProps> = ({ iconProps, ...props }) => {
  return (
    <Styledinput
      icon={<StyledIcon name="search" link {...iconProps} />}
      placeholder="検索"
      {...props}
    />
  );
};

export default SearchBar;
