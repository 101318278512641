import { call, put, takeLatest } from "redux-saga/effects";

import { GetAddressAction } from "./addressTypes";

import actions from "./addressConstants";
import ZipcodeActionsCreators from "./addressActions";

import request from "utils/request";

function* getAddressSaga(params: GetAddressAction) {
  try {
    const requestURL = `/address/search?zipcode=${params.payload.zipcode}`;
    const address = yield call(request, requestURL, {
      method: "GET",
    });

    if (address.message) {
      const error = new Error(address.message);
      throw error;
    }

    yield put(
      ZipcodeActionsCreators.getAddressSuccess(
        address.results[0].address1,
        address.results[0].address2,
        address.results[0].address3
      )
    );
  } catch (error) {
    yield put(ZipcodeActionsCreators.getAddressError(error));
  }
}

export default function* getAddressSagaRootSaga() {
  yield takeLatest(actions.GET_ADDRESS, getAddressSaga);
}
