import { APIResponse } from "interfaces/db/Rails";
import { GetTransactionsResponse } from "interfaces/db/Transaction";
import request, { B4SResponse, requestHeadersAuthorized } from "utils/request";

export type TransactionPatchKeys = keyof TransactionPatchState;
export interface TransactionPatchState {
  postal_code: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  receiver_place_name: string;
  receiver_tel_number: string;
  delivery_date: string;
}

export default {
  get: async (
    accessToken: string,
    id: string
  ): Promise<B4SResponse<GetTransactionsResponse>> => {
    return (
      request(process.env.REACT_APP_API_URL + "/transactions/" + id, {
        method: "GET",
        ...requestHeadersAuthorized(accessToken),
      }) as Promise<B4SResponse<APIResponse<GetTransactionsResponse>>>
    ).then(GetTransactionsResponseConverter);
  },
  patch: async (
    accessToken: string,
    id: string,
    values: TransactionPatchState
  ): Promise<B4SResponse<GetTransactionsResponse>> => {
    return (
      request(
        process.env.REACT_APP_API_URL +
          "/transactions/" +
          id +
          "/?" +
          (
            [
              "postal_code",
              "prefecture",
              "city",
              "address1",
              "address2",
              "receiver_place_name",
              "receiver_tel_number",
              "delivery_date",
            ] as TransactionPatchKeys[]
          )
            .map(
              (key) =>
                `donation_application[${key}]=${encodeURIComponent(
                  values[key]
                )}`
            )
            .join("&"),
        {
          method: "PATCH",
          ...requestHeadersAuthorized(accessToken),
        }
      ) as Promise<B4SResponse<APIResponse<GetTransactionsResponse>>>
    ).then(GetTransactionsResponseConverter);
  },
};

const GetTransactionsResponseConverter = (
  res: B4SResponse<APIResponse<GetTransactionsResponse>>
): B4SResponse<GetTransactionsResponse> => ({
  ...res,
  data: {
    ...res.data,
    created_at: new Date(res.data.created_at),
    updated_at: new Date(res.data.updated_at),
    donation: {
      ...res.data.donation,
      created_at: new Date(res.data.donation.created_at),
      updated_at: new Date(res.data.donation.updated_at),
    },
    child_user: {
      ...res.data.child_user,
      birthdate:
        res.data.child_user.birthdate === null
          ? null
          : new Date(res.data.child_user.birthdate),
      account_notified_at:
        res.data.child_user.account_notified_at === null
          ? null
          : new Date(res.data.child_user.account_notified_at),
      created_at: new Date(res.data.child_user.created_at),
      updated_at: new Date(res.data.child_user.updated_at),
    },
  },
});
