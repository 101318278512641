import {
  GetTransactionsAction,
  GetTransactionsSuccessAction,
  GetTransactionsErrorAction,
  GetTransactionsOptions,
} from "./TransactionsTypes";
import Actions from "./TransactionsConstants";
import { Transaction } from "interfaces/db/Transaction";
import { TransactionPagination } from "pages/MyPage/Transactions/TransactionsTypes";

const getTransactions = (
  pagination: TransactionPagination,
  searchCondition?: GetTransactionsOptions
): GetTransactionsAction => {
  return {
    type: Actions.GET_TRANSACTIONS,
    payload: {
      pagination,
      searchCondition,
    },
  };
};

const getTransactionsSuccess = (data: {
  donation_applications: Transaction[];
  total_num: number;
  total_pages: number;
}): GetTransactionsSuccessAction => {
  return {
    type: Actions.GET_TRANSACTIONS_SUCCESS,
    payload: {
      data,
    },
  };
};

const getTransactionsError = (error: Error): GetTransactionsErrorAction => {
  return {
    type: Actions.GET_TRANSACTIONS_ERROR,
    payload: {
      error,
    },
  };
};

const completeTransaction = (id: string, receiver_message: string) => {
  return {
    type: Actions.COMPLETE_TRANSACTION,
    payload: {
      id,
      receiver_message,
    },
  };
};

export const transactionsActionsCreators = {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsError,
  completeTransaction,
};
