import { Reducer } from "redux";
import actions from "./DonationConstants";
import { DonationActionTypes, DonationState } from "./DonationType";

const initialState = (): DonationState => ({
  loading: true,
});

export const donation: Reducer<DonationState, DonationActionTypes> = (
  state = initialState(),
  action
) => {
  switch (action.type) {
    case actions.SET_DONATION:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case actions.RESET_DONATION:
      return {
        data: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

export default donation;
