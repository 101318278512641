import React from "react";
import { Pagination, StrictPaginationProps } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "utils/ComponentUtils";
import { BREAKPOINTS } from "utils/ComponentUtils";

export interface MainButtonPagerProps
  extends Omit<StrictPaginationProps, "totalPages"> {
  totalPages: number;
}

const StyledPagination = styled(Pagination)`
  &&& {
    > *.item.active {
      background-color: ${COLORS.PRIMARY};
      color: white;
    }
    @media (max-width: ${BREAKPOINTS.MOBILE}) {
      > *.item {
        min-width: 0;
      }
      > [type="ellipsisItem"],
      [type="lastItem"],
      [type="firstItem"] {
        display: none;
      }
    }
  }
`;

const DesignedButtonPager: React.FC<MainButtonPagerProps> = ({
  totalPages,
  ...props
}) =>
  totalPages === undefined ? (
    <></>
  ) : (
    <StyledPagination totalPages={totalPages} {...props} />
  );

export default DesignedButtonPager;
