import { connectRouter } from "connected-react-router";
import { logIn } from "containers/App/GlobalReducer";
import * as H from "history";
import { State } from "interfaces/State";
import address from "modules/Address/addressReducer";
import categories from "modules/db/Categories/CategoriesReducer";
import donations from "modules/db/Donations/DonationsReducer";
import donation from "modules/db/Donation/DonationReducer";
import transactions from "modules/db/Transactions/TransactionsReducer";
import globals from "modules/Globals/GlobalsReducer";
import changePassword from "pages/ChangePassword/ChangePasswordReducer";
import donationApplication from "pages/Donations/Application/DonationApplicationReducer";
import donationConfirmation from "pages/Donations/Confirmation/DonationConfirmationReducer";
import { donations as donationsPage } from "pages/Donations/List/DonationsReducer";
import changeEmail from "pages/MyPage/ChangeEmail/ChangeEmailReducer";
import child from "pages/MyPage/Child/ChildReducer";
import childrenList from "modules/db/ChildrenList/ChildrenListReducer";
import childrenListPage from "pages/MyPage/ChildrenList/ChildrenListReducer";
import deleteAccount from "pages/MyPage/DeleteAccount/DeleteAccountReducer";
import profileChild from "pages/MyPage/Profile/child_user/profileChildReducer";
import orphanage from "pages/MyPage/Profile/orphanage_user/orphanage/orphanageReducer";
import profile from "pages/MyPage/Profile/orphanage_user/orphanage_user/profileReducer";
import transactionDetail from "pages/MyPage/Transactions/TransactionDetail/TransactionDetailReducer";
import transactionEdit from "pages/MyPage/Transactions/TransactionEdit/TransactionEditReducer";
import transactionsPage from "pages/MyPage/Transactions/TransactionsReducer";
import personalIdentification from "pages/PersonalIdentification/PersonalIdentificationReducer";
import requestChangePassword from "pages/RequestChangePassword/RequestChangePasswordReducer";
import likes from "modules/db/Likes/LikesReducer";
import { top } from "pages/Top/TopReducer";
import { combineReducers } from "redux";

const createRootReducer = (history: H.History) =>
  combineReducers<State>({
    router: connectRouter(history),
    globals,
    logIn,
    top,
    donationsPage,
    donationApplication,
    donationConfirmation,
    address,
    transactions,
    transactionsPage,
    transactionDetail,
    transactionEdit,
    childrenList,
    childrenListPage,
    child,
    requestChangePassword,
    changePassword,
    changeEmail,
    profile,
    profileChild,
    orphanage,
    deleteAccount,
    donations,
    donation,
    categories,
    personalIdentification,
    likes,
  });

export default createRootReducer;
