import React from "react";
import { GetProps } from "react-redux";
import { Grid } from "semantic-ui-react";
import padding from "./Padding";
// 毎回paddedを入れてるのでデフォルトで入れたい
const DefaultGrid = (props: GetProps<typeof Grid>) => {
  return <Grid padded {...props} />;
};

// Same behavior as Grid
DefaultGrid.Column = Grid.Column;
DefaultGrid.Row = Grid.Row;

DefaultGrid.PaddingColumn = padding(Grid.Column);
DefaultGrid.PaddingRow = padding(Grid.Row);

export default DefaultGrid;
