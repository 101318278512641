import { call, put, takeLatest, select } from "redux-saga/effects";

import { UpdateProfileAction } from "./profileTypes";
import actions from "./profileConstants";
import profileActionsCreators from "./profileActions";

import requestErrorCheck from "utils/requestErrorCheck";

import { accessToken } from "containers/App/GlobalSelectors";

import request from "utils/request";
import { requestHeadersAuthorized } from "utils/request";

import serialize from "serialize-javascript";
import ROUTES from "utils/routes";
import { push } from "connected-react-router";
import { getUserName } from "containers/App/GlobalSaga";
import { SCOPE } from "containers/App/GlobalTypes";
import { globalActionsCreators } from "containers/App/GlobalActions";

function* getProfileSaga() {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user`;
    let responseAccessToken = yield select(accessToken);
    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }
    const profile = yield call(request, requestURL, {
      method: "GET",
      ...requestHeadersAuthorized(responseAccessToken),
    });
    const userName = (yield getUserName(
      sessionStorage.getItem("scope") as SCOPE,
      responseAccessToken
    )) as string;
    profile.data.userName = userName;

    yield put(profileActionsCreators.getProfileSuccess(profile.data));
  } catch (error) {
    yield put(globalActionsCreators.logOut());
    yield put(profileActionsCreators.profileError(error));
  }
}

function* updateProfileSaga(params: UpdateProfileAction) {
  try {
    const requestURL = `${process.env.REACT_APP_API_URL}/orphanage_user`;
    let responseAccessToken = yield select(accessToken);
    if (!responseAccessToken) {
      responseAccessToken = sessionStorage.getItem("access_token");
    }

    const response = yield call(request, requestURL, {
      method: "PATCH",
      ...requestHeadersAuthorized(responseAccessToken),
      body: serialize(params.payload.profile),
    });
    yield put(push(ROUTES.SETTINGS));
    yield put(
      profileActionsCreators.updateProfileSuccess(
        response.data,
        "保存しました。"
      )
    );
  } catch (error) {
    yield requestErrorCheck(error);
    yield put(profileActionsCreators.profileError(error));
  }
}

export default function* registerRootSaga() {
  yield takeLatest(actions.GET_PROFILE, getProfileSaga);
  yield takeLatest(actions.UPDATE_PROFILE, updateProfileSaga);
}
