import MainHeader from "components/atoms/MainHeader";
import { withHeaderAndFooter } from "components/organisms/Header";
import Todokun from "images/todokun_charactor.png";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, Image } from "semantic-ui-react";
import styled from "styled-components";
import DefaultGrid from "styles/DefaultGrid";
import { size } from "styles/Size";
import ROUTES from "utils/routes";

const AccountNotificationCompleted: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <DefaultGrid id="AccountNotificationCompleted">
      <DefaultGrid.Row id="BreadcrumbsRow">
        <Breadcrumb icon="right angle" sections={dummySections} />
      </DefaultGrid.Row>
      <DefaultGrid.Row id="MainRow" centered>
        <SizeableGrid
          id="MainGrid"
          centered
          size={{
            maxWidth: 315,
          }}
        >
          <DefaultGrid.Row id="TitleRow">
            <MainHeader>アカウント通知が完了しました</MainHeader>
          </DefaultGrid.Row>
          <DefaultGrid.Row id="ContentsRow">
            <DefaultGrid centered>
              <DefaultGrid.Row id="ContentsHeaderRow">
                <TitleTextSpan>
                  該当子どもユーザー登録されている
                  <br />
                  アドレスにメールを送信しました。
                </TitleTextSpan>
              </DefaultGrid.Row>
              <DefaultGrid.Row id="ContentsBodyRow">
                <CenteredImg alt="トドクン" src={Todokun} />
              </DefaultGrid.Row>
              <DefaultGrid.Row id="ContentsBodyRow">
                <BodyTextSpan>
                  メールに添付されているリンクをクリックし、
                  <br />
                  パスワードの再設定を行いログインしてください
                </BodyTextSpan>
              </DefaultGrid.Row>
            </DefaultGrid>
          </DefaultGrid.Row>
        </SizeableGrid>
      </DefaultGrid.Row>
    </DefaultGrid>
  );
};

export default withHeaderAndFooter(AccountNotificationCompleted);

const dummySections = [
  {
    key: "dummy",
  },
  {
    key: "BackToChildrenList",
    content: "子どもユーザー一覧へ戻る",
    as: NavLink,
    to: ROUTES.CHILDREN_LIST,
  },
];

const SizeableGrid = size(DefaultGrid);

const TitleTextSpan = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

const BodyTextSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const CenteredImg = styled(Image)`
  margin: 0px auto;
`;
