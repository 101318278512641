import React from "react";
import { Header, HeaderProps } from "semantic-ui-react";
import styled from "styled-components";
import { COLORS } from "utils/ComponentUtils";

const StyledHeader = styled(Header)`
  &&& {
    color: ${COLORS.PRIMARY};
    font-size: 1.5rem;
  }
`;

const MainHeader: React.FC<HeaderProps> = (props) => (
  <StyledHeader {...props} />
);

export default MainHeader;
